import React from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/inputs/Button';

import { ReactComponent as ArrowLeft } from 'static/icons/arrows/arrow-left.svg';

import styles from './BackLink.module.scss';

interface BackLinkProps {
  className?: string;
  to?: string;
}

const BackLink: React.FC<BackLinkProps> = ({ className, to }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const linkProps = {
    className: cx(styles.BackLink, className),
    iconStart: ArrowLeft,
    size: 'small' as 'small',
    variant: 'text' as 'text',
  };

  return to ? (
    <Button.RouterLink {...linkProps} to={to ?? ''}>
      {t('shared.controls.goBack')}
    </Button.RouterLink>
  ) : (
    <Button {...linkProps} onClick={() => navigate(-1)}>
      {t('shared.controls.goBack')}
    </Button>
  );
};

export default BackLink;
