import { AdminReviewsFilterType } from 'types/adminReviews';
import { AdminFilterDescriptionType, SelectOption } from 'types/common';

export const reviewStatusOptions = [
  { value: 0, label: 'Новый' },
  { value: 1, label: 'Опубликованный' },
  { value: 2, label: 'Отклоненный' },
];

export const isPublishedOnMainOptions = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' },
];

const adminReviewsFilters: AdminFilterDescriptionType<AdminReviewsFilterType>[] = [
  {
    key: 'reviewStatus',
    label: 'Статус',
    input: { type: 'select', props: { options: reviewStatusOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'isPublishedOnMain',
    label: 'Опубликован на главной',
    input: { type: 'select', props: { options: isPublishedOnMainOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
];

export default adminReviewsFilters;
