import React, { useEffect } from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';
import { MagicBoxLotsFilterType } from 'types/magicBox';

import { Card } from 'components/surfaces';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, Checkbox, TextField } from 'components/inputs';

import { ReactComponent as CloseIcon } from 'static/icons/close-small.svg';

import styles from './MagicBoxLotsFilterModal.module.scss';

const MagicBoxLotsFilterModal: React.FC = () => {
  const { t } = useTranslation();
  const { magicBoxStore } = useStore();

  const magicBoxLotsFilterForm = useForm<MagicBoxLotsFilterType>({
    defaultValues: magicBoxStore.lots.filter,
  });

  const handleSubmit = magicBoxLotsFilterForm.handleSubmit((values: MagicBoxLotsFilterType) => {
    magicBoxStore.closeLotsFilterModal();
    magicBoxStore.updateLotsFilter(values);
  });

  const handleReset = () => {
    magicBoxStore.closeLotsFilterModal();
    magicBoxStore.updateLotsFilter({
      active: true,
      completed: true,
      myLots: false,
      participating: false,
      prizeAmountFrom: '',
      prizeAmountTo: '',
      ticketPriceFrom: '',
      ticketPriceTo: '',
    });
  };

  useEffect(() => {
    if (magicBoxStore.isLotsFilterModalOpen) {
      magicBoxLotsFilterForm.reset(magicBoxStore.lots.filter);
    }
  }, [magicBoxLotsFilterForm, magicBoxStore.isLotsFilterModalOpen, magicBoxStore.lots.filter]);

  return (
    <Modal className={styles.MagicBoxLotsFilterModal} isOpen={magicBoxStore.isLotsFilterModalOpen}>
      <div className={styles.heading}>
        <Typography className={styles.title} element="h2" variant="subtitle1">
          {t('shared.filter')}
        </Typography>

        <Button className={styles.close} variant="wrapper" onClick={magicBoxStore.closeLotsFilterModal}>
          <CloseIcon className={styles.icon} />
        </Button>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <Card className={styles.card}>
          <Typography className={styles.label} element="span" variant="subtitle1" color="black">
            {t('modals.magicBoxFilter.participationPrice')}
          </Typography>

          <div className={styles.fields}>
            <Controller
              control={magicBoxLotsFilterForm.control}
              name="ticketPriceFrom"
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <div className={styles.fieldWrapper}>
                  <NumericFormat
                    className={styles.input}
                    {...fieldProps}
                    placeholder="0"
                    allowLeadingZeros={false}
                    allowNegative={false}
                    customInput={TextField}
                  />
                  <Typography className={styles.prefix} element="span" variant="paragraph1" color="blackTransparent">
                    {t('shared.labels.from')}
                  </Typography>
                </div>
              )}
            />

            <Controller
              control={magicBoxLotsFilterForm.control}
              name="ticketPriceTo"
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <div className={styles.fieldWrapper}>
                  <NumericFormat
                    className={styles.input}
                    {...fieldProps}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    customInput={TextField}
                  />
                  <Typography className={styles.prefix} element="span" variant="paragraph1" color="blackTransparent">
                    {t('shared.labels.to')}
                  </Typography>
                </div>
              )}
            />
          </div>
        </Card>

        <Card className={styles.card}>
          <Typography className={styles.label} element="span" variant="subtitle1" color="black">
            {t('modals.magicBoxFilter.prizeAmount')}
          </Typography>

          <div className={styles.fields}>
            <Controller
              control={magicBoxLotsFilterForm.control}
              name="prizeAmountFrom"
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <div className={styles.fieldWrapper}>
                  <NumericFormat
                    className={styles.input}
                    {...fieldProps}
                    placeholder="0"
                    allowLeadingZeros={false}
                    allowNegative={false}
                    customInput={TextField}
                  />
                  <Typography className={styles.prefix} element="span" variant="paragraph1" color="blackTransparent">
                    {t('shared.labels.from')}
                  </Typography>
                </div>
              )}
            />

            <Controller
              control={magicBoxLotsFilterForm.control}
              name="prizeAmountTo"
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <div className={styles.fieldWrapper}>
                  <NumericFormat
                    className={styles.input}
                    {...fieldProps}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    customInput={TextField}
                  />
                  <Typography className={styles.prefix} element="span" variant="paragraph1" color="blackTransparent">
                    {t('shared.labels.to')}
                  </Typography>
                </div>
              )}
            />
          </div>
        </Card>

        <Card className={styles.card}>
          <Typography className={styles.label} element="span" variant="subtitle1" color="black">
            {t('modals.magicBoxFilter.status', { number: 1 })}
          </Typography>

          <div className={styles.checkboxes}>
            <Controller
              control={magicBoxLotsFilterForm.control}
              name="completed"
              render={({ field }) => (
                <Checkbox className={styles.checkbox} {...field} checked={field.value}>
                  <Typography className={styles.checkboxLabel} element="span" variant="paragraph1" color="black">
                    {t('modals.magicBoxFilter.completed')}
                  </Typography>
                </Checkbox>
              )}
            />
            <Controller
              control={magicBoxLotsFilterForm.control}
              name="active"
              render={({ field }) => (
                <Checkbox className={styles.checkbox} {...field} checked={field.value}>
                  <Typography className={styles.checkboxLabel} element="span" variant="paragraph1" color="black">
                    {t('modals.magicBoxFilter.active')}
                  </Typography>
                </Checkbox>
              )}
            />
          </div>
        </Card>

        <Card className={styles.card}>
          <Typography className={styles.label} element="span" variant="subtitle1" color="black">
            {t('modals.magicBoxFilter.status', { number: 2 })}
          </Typography>

          <div className={styles.checkboxes}>
            <Controller
              control={magicBoxLotsFilterForm.control}
              name="participating"
              render={({ field }) => (
                <Checkbox className={styles.checkbox} {...field} checked={field.value}>
                  <Typography className={styles.checkboxLabel} element="span" variant="paragraph1" color="black">
                    {t('modals.magicBoxFilter.withMe')}
                  </Typography>
                </Checkbox>
              )}
            />
            <Controller
              control={magicBoxLotsFilterForm.control}
              name="myLots"
              render={({ field }) => (
                <Checkbox className={styles.checkbox} {...field} checked={field.value}>
                  <Typography className={styles.checkboxLabel} element="span" variant="paragraph1" color="black">
                    {t('modals.magicBoxFilter.myLots')}
                  </Typography>
                </Checkbox>
              )}
            />
          </div>
        </Card>

        <Button className={styles.button} size="large" type="submit">
          {t('shared.controls.apply')}
        </Button>

        <Button
          className={cx(styles.button, styles.close)}
          size="medium"
          variant="outlined"
          color="black"
          onClick={handleReset}
        >
          {t('modals.magicBoxFilter.clear')}
        </Button>
      </form>
    </Modal>
  );
};

export default observer(MagicBoxLotsFilterModal);
