import React, { forwardRef } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import Spinner from 'components/feedback/Spinner';
import { SVGIconType } from 'types/common';

import styles from './Button.module.scss';

type ButtonBasicProps = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  color?: 'green' | 'black' | 'gray' | 'lightGray' | 'white' | 'boneWhite' | 'red' | 'crimsonRed' | 'blue';
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'contained' | 'outlined' | 'wrapper';
  iconStart?: SVGIconType;
  iconEnd?: SVGIconType;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
};

interface ButtonProps extends ButtonBasicProps {
  onTouch?: React.TouchEventHandler;
  type?: 'button' | 'submit';
}

interface ButtonLinkProps extends ButtonBasicProps {
  href: string;
  target?: string;
  rel?: string;
  download?: boolean | string;
  newTab?: boolean;
}

interface ButtonRouterLinkProps extends ButtonBasicProps {
  to: string;
  newTab?: boolean;
}

const Button = ({
  children,
  className,
  onClick,
  onTouch,
  disabled,
  isLoading,
  iconStart: IconStart,
  iconEnd: IconEnd,
  color = 'green',
  size = 'large',
  variant = 'contained',
  type = 'button',
}: ButtonProps) => {
  return (
    <button
      className={cx(
        styles.Button,
        styles[color],
        styles[size],
        styles[variant],
        { [styles.disabled]: disabled || isLoading },
        className
      )}
      disabled={disabled || isLoading}
      onClick={onClick}
      onTouchStart={onTouch}
      type={type}
    >
      {isLoading ? (
        <Spinner className={styles.spinner} />
      ) : (
        <>
          {IconStart && <IconStart className={styles.iconStart} />}
          {children}
          {IconEnd && <IconEnd className={styles.iconEnd} />}
        </>
      )}
    </button>
  );
};

Button.Link = ({
  children,
  className,
  href,
  rel,
  target,
  download,
  disabled,
  onClick,
  iconStart: IconStart,
  iconEnd: IconEnd,
  color = 'green',
  size = 'large',
  variant = 'contained',
  newTab,
}: ButtonLinkProps) => {
  return (
    <a
      className={cx(
        styles.Button,
        styles[color],
        styles[size],
        styles[variant],
        { [styles.disabled]: disabled },
        className
      )}
      onClick={onClick}
      href={href}
      download={download}
      {...(newTab ? { rel: 'noreferrer', target: '_blank' } : { rel, target })}
    >
      {IconStart && <IconStart className={styles.iconStart} />}
      {children}
      {IconEnd && <IconEnd className={styles.iconEnd} />}
    </a>
  );
};

Button.RouterLink = forwardRef<HTMLAnchorElement | null, ButtonRouterLinkProps>(
  (
    {
      children,
      className,
      to,
      newTab,
      disabled,
      iconStart: IconStart,
      iconEnd: IconEnd,
      onClick,
      color = 'green',
      size = 'large',
      variant = 'contained',
    }: ButtonRouterLinkProps,
    ref
  ) => {
    return (
      <Link
        className={cx(
          styles.Button,
          styles[color],
          styles[size],
          styles[variant],
          { [styles.disabled]: disabled },
          className
        )}
        onClick={onClick}
        /* onMouseUp={(event) => {
          event.preventDefault();
        }}
        onMouseUpCapture={(event) => {
          event.preventDefault();
        }}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onMouseDownCapture={(event) => {
          event.preventDefault();
        }} */
        ref={ref}
        to={to}
        {...(newTab ? { target: '_blank', rel: 'noreferrer' } : {})}
      >
        {IconStart && <IconStart className={styles.iconStart} />}
        {children}
        {IconEnd && <IconEnd className={styles.iconEnd} />}
      </Link>
    );
  }
);

export default Button;
