import React from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { TOPBOX_LEVELS_MAP } from 'const/topboxMarketing';

import { Modal, Spinner } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './TopboxReferralInfoModal.module.scss';
import { Button } from 'components/inputs';
import numbro from 'numbro';

const TopboxReferralInfoModal: React.FC = () => {
  const { t } = useTranslation();

  const { topboxMarketingStore } = useStore();
  const level = topboxMarketingStore.referralInfoModalConfig?.level;

  return (
    <Modal
      className={cx(styles.TopboxReferralInfoModal, level && styles[level])}
      isOpen={!!topboxMarketingStore.referralInfoModalConfig}
      onClose={topboxMarketingStore.closeReferralInfoModal}
    >
      {level && (
        <div className={styles.heading}>
          <img className={styles.icon} src={TOPBOX_LEVELS_MAP[level].image} alt={level} />

          <Typography element="span" variant="subtitle1" color="white">
            {t(`shared.topboxMarketing.levels.${level}`)}
          </Typography>
        </div>
      )}

      <Typography className={styles.title} element="h2" variant="heading6" color="white">
        {t('modals.topboxReferralInfo.title')}
      </Typography>

      {!!level && !!topboxMarketingStore.referralsStats ? (
        <ul className={styles.info}>
          {(['1', '2', '3', '4', '5'] as const).map((line) => (
            <li key={line}>
              <Typography className={styles.label} element="span" variant="paragraph1" color="white">
                {t('shared.labels.line')} {line}
              </Typography>

              <Typography element="span" variant="subtitle1" color="white">
                {numbro(topboxMarketingStore.referralsStats?.[level][line]).format()}
              </Typography>
            </li>
          ))}
        </ul>
      ) : (
        <Spinner className={styles.spinner} />
      )}

      <Button
        className={styles.close}
        variant="outlined"
        color="lightGray"
        onClick={topboxMarketingStore.closeReferralInfoModal}
      >
        {t('shared.controls.close')}
      </Button>
    </Modal>
  );
};

export default observer(TopboxReferralInfoModal);
