import { makeAutoObservable } from 'mobx';

import RootStore from 'store';
import { UsersService } from 'api/client';
import { UserRoleType } from 'types/common';
import { getAccessToken, invalidateToken, logTodaysLoginCount, setAccessToken } from 'utils/auth';

export default class AuthStore {
  rootStore: RootStore;

  userRole: UserRoleType | null;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'authStore' });
    this.rootStore = rootStore;
    const { token } = getAccessToken();
    if (token) {
      this.check();
      this.userRole = null;
      this.getUserRole();
    } else {
      this.userRole = 'guest';
    }
  }

  *getUserRole() {
    try {
      yield this.rootStore.userStore.getUser();
      if (this.rootStore.userStore.user?.isAdmin) {
        this.userRole = 'admin';
      } else if (this.rootStore.userStore.user?.isSupport) {
        this.userRole = 'support';
      } else if (this.rootStore.userStore.user?.isSmm) {
        this.userRole = 'smm';
      } else {
        this.userRole = 'user';
      }
    } catch (error) {
      console.log('[AuthStore] getUserRole error:', error);
      this.userRole = 'guest';
    }
  }

  *login(token: string) {
    try {
      setAccessToken(token);
      yield this.getUserRole();
      const todaysLoginCount = logTodaysLoginCount();
      if (todaysLoginCount <= 1) {
        this.rootStore.mainMarketingStore.openMainMarketingPromoModal();
      }
    } catch (error) {
      console.log('[AuthStore] login error:', error);
    }
  }

  *logout() {
    try {
      yield invalidateToken();
      this.userRole = 'guest';
    } catch (error) {
      console.log('[AuthStore] logout error:', error);
    }
  }

  *check() {
    try {
      yield UsersService.checkAliveToken();
    } catch (error) {
      console.log('[AuthStore] check error:', error);
    }
  }
}
