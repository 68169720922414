import React, { useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';
import { mapApiErrors } from 'utils/errorHandling';
import { ApiError, UsersService } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, TextField } from 'components/inputs';

import { ReactComponent as PaperPlane } from 'static/images/paper-plane.svg';

import styles from './CreateReviewModal.module.scss';

interface ReviewValues {
  text: string;
}

const CreateReviewModal: React.FC = () => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const reviewSchema: yup.SchemaOf<ReviewValues> = useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required(t('modals.createReview.textRequired') as string),
      }),
    [t]
  );

  const reviewForm = useForm<ReviewValues>({
    mode: 'onTouched',
    resolver: yupResolver(reviewSchema),
    defaultValues: {
      text: '',
    },
  });

  const handleNotificationSubmit = reviewForm.handleSubmit(async (values: ReviewValues) => {
    try {
      setIsLoading(true);
      await UsersService.createReview(values);
      setIsSuccess(true);
      userStore.getReviews();
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, reviewForm);
      } else {
        reviewForm.setError('text', { message: apiError?.body?.message || t('shared.errors.unknownError') });
      }
    } finally {
      setIsLoading(false);
    }
  });

  const handleClose = () => {
    setIsSuccess(false);
    userStore.closeCreateReviewModal();
  };

  useEffect(() => {
    reviewForm.reset({ text: '' });
  }, [reviewForm, userStore.createReviewModalConfig]);

  return (
    <Modal className={styles.CreateReviewModal} isOpen={!!userStore.createReviewModalConfig} onClose={handleClose}>
      {!isSuccess && (
        <>
          <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
            {userStore.createReviewModalConfig?.label}
          </Typography>

          <form className={styles.form} onSubmit={handleNotificationSubmit}>
            <Controller
              control={reviewForm.control}
              name="text"
              render={({ field, fieldState }) => (
                <TextField
                  className={styles.field}
                  {...field}
                  error={fieldState.error?.message}
                  placeholder={t('modals.createReview.placeholder')}
                  maxLength={1000}
                  multiline
                />
              )}
            />

            <div className={styles.controls}>
              <Button
                className={styles.button}
                variant="outlined"
                color="red"
                disabled={isLoading}
                onClick={handleClose}
              >
                {t('shared.controls.cancel')}
              </Button>
              <Button className={styles.button} type="submit" isLoading={isLoading}>
                {t('modals.createReview.submit')}
              </Button>
            </div>
          </form>
        </>
      )}

      {isSuccess && (
        <>
          <PaperPlane className={styles.successImg} />

          <Typography className={styles.title} element="h2" variant="heading5" color="black">
            {t('modals.createReview.thanks')}
          </Typography>

          <Typography className={styles.text} element="p" variant="paragraph1" color="darkGray">
            {t('modals.createReview.success')}
          </Typography>

          <Button className={styles.button} variant="outlined" color="gray" onClick={handleClose}>
            {t('shared.controls.close')}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default observer(CreateReviewModal);
