import React, { useRef, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { ru as ruDateFnsLocale } from 'date-fns/locale';
import { Calendar } from 'react-date-range';
import { AnimatePresence, motion } from 'framer-motion';

import { useOnClickOutside } from 'utils/hooks';

import Card from 'components/surfaces/Card';
import TextField from 'components/inputs/TextField';

import styles from './DatePicker.module.scss';

const MotionCard = motion(Card);

interface DatePickerProps {
  className?: string;
  error?: string;
  label?: string;
  placeholder?: string;
  value?: Date;
  onChange?: (newValue: Date) => void;
  maxDate?: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  className,
  label,
  placeholder,
  error,
  value,
  onChange,
  maxDate = new Date(),
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [calendarPlacement, setCalendarPlacement] = useState({ top: false, left: false });

  const openCalendar = () => {
    setIsCalendarOpen(true);

    const rightEdge = (wrapperRef.current?.offsetLeft || 0) + 300;
    const bottomEdge = (wrapperRef.current?.offsetTop || 0) + (wrapperRef.current?.clientHeight || 0) + 320;

    setCalendarPlacement({
      left: rightEdge > document.body.scrollWidth,
      top: bottomEdge > document.body.scrollHeight,
    });
  };

  const closeCalendar = () => {
    setIsCalendarOpen(false);
    setCalendarPlacement({ top: false, left: false });
  };

  useOnClickOutside(wrapperRef, closeCalendar);

  const handleChange = (newDate: Date) => {
    onChange && onChange(newDate);
    closeCalendar();
  };

  return (
    <div className={cx(styles.DatePicker, className)} ref={wrapperRef}>
      <TextField
        className={styles.input}
        label={label}
        placeholder={placeholder}
        error={error}
        onFocus={openCalendar}
        value={value ? moment(value).format('DD.MM.YYYY') : ''}
      />

      <AnimatePresence>
        {isCalendarOpen && (
          <MotionCard
            className={cx(styles.pickerCard, {
              [styles.placementTop]: calendarPlacement.top,
              [styles.placementLeft]: calendarPlacement.left,
            })}
            animate="show"
            exit="hide"
            initial="hide"
            variants={{
              hide: { opacity: 0, transform: 'translateY(20px)' },
              show: { opacity: 1, transform: 'translateY(0px)' },
            }}
          >
            <Calendar
              onChange={handleChange}
              date={value}
              maxDate={maxDate}
              showDateDisplay={false}
              rangeColors={['#00b548']}
              color="#00b548"
              locale={ruDateFnsLocale}
            />
          </MotionCard>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DatePicker;
