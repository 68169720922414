import React, { forwardRef } from 'react';

import cx from 'classnames';

import styles from './Card.module.scss';

interface CardProps {
  children?: React.ReactNode;
  className?: string;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

const Card = forwardRef<HTMLDivElement | null, CardProps>(({ children, className, onScroll }, ref) => {
  return (
    <div className={cx(styles.Card, className)} ref={ref} onScroll={onScroll}>
      {children}
    </div>
  );
});

export default Card;
