import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { useStore } from 'store';
import { SelectOption } from 'types/common';

import { Modal } from 'components/feedback';
import { Button, Select } from 'components/inputs';
import { Typography } from 'components/dataDisplay';

import styles from './TransactionsFilterModal.module.scss';

interface TransactionsFilterValues {
  status: string[];
  type: string[];
}

const TransactionsFilterModal: React.FC = () => {
  const { t } = useTranslation();
  const { accountingStore } = useStore();

  const handleClose = () => {
    accountingStore.closeTransactionsFilterModal();
  };

  const transactionsFilterForm = useForm<TransactionsFilterValues>({
    defaultValues: {
      status: [],
      type: [],
    },
  });

  const handleFilterSubmit = transactionsFilterForm.handleSubmit((values: TransactionsFilterValues) => {
    accountingStore.transactionsFilterModalConfig?.onSubmit({
      status: values.status,
      type: values.type,
    });
    handleClose();
  });

  useEffect(() => {
    transactionsFilterForm.reset(
      accountingStore.transactionsFilterModalConfig?.filter || {
        status: [],
        type: [],
      }
    );
  }, [accountingStore.transactionsFilterModalConfig, transactionsFilterForm]);

  return (
    <Modal
      className={styles.TransactionsFilterModal}
      isOpen={!!accountingStore.transactionsFilterModalConfig}
      onClose={handleClose}
    >
      <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
        {t('shared.filter')}
      </Typography>

      <form className={styles.form} onSubmit={handleFilterSubmit}>
        <Controller
          control={transactionsFilterForm.control}
          name="status"
          render={({ field, fieldState }) => (
            <Select
              label={t('shared.transactions.props.status')}
              isClearable
              isMulti
              options={accountingStore.transactionsStatuses}
              value={accountingStore.transactionsStatuses.filter((option) =>
                field.value.includes(option.value as string)
              )}
              onChange={(newValue) => field.onChange((newValue as SelectOption[]).map((option) => option.value))}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={transactionsFilterForm.control}
          name="type"
          render={({ field, fieldState }) => (
            <Select
              label={t('shared.transactions.props.type')}
              isClearable
              isMulti
              options={accountingStore.transactionsTypes}
              value={accountingStore.transactionsTypes.filter((option) => field.value.includes(option.value as string))}
              onChange={(newValue) => field.onChange((newValue as SelectOption[]).map((option) => option.value))}
              error={fieldState.error?.message}
            />
          )}
        />

        <div className={styles.controls}>
          <Button className={styles.button} type="submit">
            {t('shared.controls.apply')}
          </Button>
          <Button className={styles.button} variant="outlined" onClick={handleClose}>
            {t('shared.controls.cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(TransactionsFilterModal);
