import React, { useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { useWindowSize } from 'utils/hooks';

import Card from 'components/surfaces/Card';

import { ReactComponent as CopyIcon } from 'static/icons/copy.svg';

import styles from './ClipboardCopy.module.scss';

const MotionCard = motion(Card);

interface ClipboardCopyProps {
  children?: React.ReactNode;
  className?: string;
  value?: string | null;
  disabled?: string;
}

const ClipboardCopy: React.FC<ClipboardCopyProps> = ({ children, className, value, disabled }) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);
  const [hovered, setHovered] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { width: windowWidth } = useWindowSize();

  const handleClick = () => {
    if (disabled || !value) return;

    navigator.clipboard.writeText(value);
    setHovered(false);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const isAlignedRight = useMemo(
    () =>
      wrapperRef.current?.getBoundingClientRect().right &&
      windowWidth &&
      windowWidth - wrapperRef.current?.getBoundingClientRect().right < 75,
    [wrapperRef, windowWidth]
  );

  return (
    <div
      className={cx(
        styles.ClipboardCopy,
        {
          [styles.empty]: !children,
        },
        className
      )}
      ref={wrapperRef}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AnimatePresence>
        {copied && (
          <MotionCard
            className={cx(styles.hint, styles.copied, { [styles.alignRight]: isAlignedRight })}
            variants={{
              hide: {
                opacity: 0,
                marginBottom: '-8px',
              },
              show: {
                opacity: 1,
                marginBottom: '12px',
              },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            key="copied"
            transition={{ ease: 'easeInOut' }}
          >
            {t('shared.labels.copied')}
          </MotionCard>
        )}

        {hovered && (
          <MotionCard
            className={cx(styles.hint, { [styles.alignRight]: isAlignedRight })}
            variants={{
              hide: {
                opacity: 0,
                marginBottom: '-8px',
              },
              show: {
                opacity: 1,
                marginBottom: '12px',
              },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            key="hint"
            transition={{ ease: 'easeInOut' }}
          >
            {t('shared.labels.copy')}
          </MotionCard>
        )}
      </AnimatePresence>

      {children || <CopyIcon className={styles.copyIcon} />}
    </div>
  );
};

export default ClipboardCopy;
