import React from 'react';

import cx from 'classnames';

import { TypographyColorType } from 'types/common';

import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as BoxIcon } from 'static/icons/box.svg';

import styles from './Logo.module.scss';

interface LogoProps {
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  variant?: 'horizontal' | 'vertical' | 'verticalCentered';
  color?: TypographyColorType;
}

const Logo: React.FC<LogoProps> = ({
  className,
  iconClassName,
  textClassName,
  variant = 'horizontal',
  color = 'nightGreen',
}) => {
  return (
    <div className={cx(styles.Logo, styles[variant], className)}>
      <div className={styles.boxIconWrapper}>
        <BoxIcon className={cx(styles.boxIcon, iconClassName)} />
      </div>
      <Typography className={cx(styles.text, textClassName)} element="span" variant="heading5" color={color}>
        Profit<span>Box</span>
      </Typography>
    </div>
  );
};

export default Logo;
