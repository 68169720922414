import React from 'react';

import moment from 'moment';

import { ReviewResponse } from 'api/client';

import Card from 'components/surfaces/Card';
import Avatar from 'components/dataDisplay/Avatar';
import Typography from 'components/dataDisplay/Typography';

import styles from './ReviewCard.module.scss';

interface ReviewCardProps {
  review: ReviewResponse;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review }) => {
  return (
    <Card className={styles.ReviewCard}>
      <Typography className={styles.date} element="p" variant="caption1" color="darkGray">
        {moment(review.createdAt).format('DD MMMM YYYY')}
      </Typography>

      <Typography className={styles.text} element="p" variant="paragraph1" color="black">
        {review.text}
      </Typography>

      <div className={styles.user}>
        <Avatar className={styles.avatar} size="sm" avatar={review.user.avatar} name={review.user.fullName} />

        <Typography className={styles.username} element="p" variant="paragraph2" color="black">
          {review.user.fullName}
        </Typography>
      </div>
    </Card>
  );
};

export default ReviewCard;
