import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NumericFormat, PatternFormat } from 'react-number-format';

import { useStore } from 'store';
import { AdminUserItem } from 'api/client';
import { mapApiErrors } from 'utils/errorHandling';
import { EditUserFormValues, EditUserModalConfigType, SelectOption, UserType } from 'types/common';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, Dropzone, PasswordField, Select, TextField } from 'components/inputs';

import styles from './EditUserModal.module.scss';

const getFormDefaultValues = (config: EditUserModalConfigType | null, user: (UserType & AdminUserItem) | null) =>
  config?.fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: field.initialValue?.(user) ?? '',
    }),
    {}
  );

interface EditUserModalProps {
  config: EditUserModalConfigType | null;
  user: (UserType & AdminUserItem) | null;
  onSubmit: (user: UserType & AdminUserItem) => void;
  onClose: () => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ config, user, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { layoutStore } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const editForm = useForm<EditUserFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(
      yup.object().shape({
        ...config?.fields.reduce(
          (acc, field) => ({
            ...acc,
            [field.key]: field.validation,
          }),
          {}
        ),
      })
    ),
    defaultValues: getFormDefaultValues(config, user),
  });

  useEffect(() => {
    if (config) {
      editForm.reset(getFormDefaultValues(config, user));
    }
  }, [config, editForm, user]);

  const handleClose = () => {
    setIsLoading(false);
    setSuccess(false);
    onClose();
  };

  const handleSubmit = editForm.handleSubmit((values: EditUserFormValues) => {
    setIsLoading(true);
    config
      ?.submitHandler?.(values)
      .then(() => {
        setSuccess(true);
        onSubmit(config.getUpdatedUser(user!, values));
      })
      .catch((error) => {
        if (error?.body?.errors) {
          mapApiErrors(error.body.errors, editForm);
        } else {
          layoutStore.openFeedbackModal(error?.body?.message || t('shared.errors.unknownError'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <Modal className={styles.EditUserModal} isOpen={!!config} onClose={handleClose}>
      <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
        {t('modals.editUser.title')}
      </Typography>

      {!success && (
        <form className={styles.form} onSubmit={handleSubmit}>
          {config?.fields.map((fieldConfig) => (
            <Controller
              control={editForm.control}
              key={fieldConfig.key}
              name={fieldConfig.key}
              render={({ field: { ref, ...fieldProps }, fieldState }) => (
                <>
                  {fieldConfig.type === 'text' && (
                    <TextField
                      {...fieldProps}
                      ref={ref}
                      error={
                        fieldState.error?.message && config.translatable
                          ? t(fieldState.error.message)!
                          : fieldState.error?.message
                      }
                      value={(fieldProps.value as string) ?? ''}
                      label={config.translatable ? t(fieldConfig.label) : fieldConfig.label}
                    />
                  )}

                  {fieldConfig.type === 'number' && (
                    <NumericFormat
                      {...fieldProps}
                      value={(fieldProps.value as string) ?? ''}
                      error={
                        fieldState.error?.message && config.translatable
                          ? t(fieldState.error.message)!
                          : fieldState.error?.message
                      }
                      label={config.translatable ? t(fieldConfig.label) : fieldConfig.label}
                      prefix={fieldConfig.prefix}
                      allowLeadingZeros={false}
                      customInput={TextField}
                    />
                  )}

                  {fieldConfig.type === 'password' && (
                    <PasswordField
                      {...fieldProps}
                      ref={ref}
                      error={
                        fieldState.error?.message && config.translatable
                          ? t(fieldState.error.message)!
                          : fieldState.error?.message
                      }
                      value={(fieldProps.value as string) ?? ''}
                      label={config.translatable ? t(fieldConfig.label) : fieldConfig.label}
                    />
                  )}

                  {fieldConfig.type === 'format' && (
                    <PatternFormat
                      {...fieldProps}
                      value={(fieldProps.value as string) ?? ''}
                      error={
                        fieldState.error?.message && config.translatable
                          ? t(fieldState.error.message)!
                          : fieldState.error?.message
                      }
                      label={config.translatable ? t(fieldConfig.label) : fieldConfig.label}
                      format={fieldConfig.format || ''}
                      prefix={fieldConfig.prefix}
                      customInput={TextField}
                    />
                  )}

                  {fieldConfig.type === 'file' && (
                    <Dropzone
                      onChange={(file) => fieldProps.onChange(file)}
                      error={
                        fieldState.error?.message && config.translatable
                          ? t(fieldState.error.message)!
                          : fieldState.error?.message
                      }
                      label={config.translatable ? t(fieldConfig.label) : fieldConfig.label}
                    />
                  )}

                  {fieldConfig.type === 'select' && (
                    <Select
                      onChange={(newValue) => fieldProps.onChange((newValue as SelectOption).value)}
                      options={
                        fieldConfig.options?.map((option) => ({
                          ...option,
                          label: config.translatable ? t(option.label as string) : option.label,
                        }))!
                      }
                      value={fieldConfig.options!.find((option) => option.value === fieldProps.value)}
                      error={
                        fieldState.error?.message && config.translatable
                          ? t(fieldState.error.message)!
                          : fieldState.error?.message
                      }
                      label={config.translatable ? t(fieldConfig.label) : fieldConfig.label}
                    />
                  )}
                </>
              )}
            />
          ))}

          <div className={styles.controls}>
            <Button className={styles.button} isLoading={isLoading} type="submit">
              {t('shared.controls.save')}
            </Button>
            <Button className={styles.button} disabled={isLoading} variant="outlined" onClick={handleClose}>
              {t('shared.controls.cancel')}
            </Button>
          </div>
        </form>
      )}

      {success && (
        <>
          <Typography className={styles.message} element="p" variant="paragraph1">
            {t('modals.editUser.success')}
          </Typography>

          <Button className={styles.button} onClick={handleClose}>
            OK
          </Button>
        </>
      )}
    </Modal>
  );
};

export default observer(EditUserModal);
