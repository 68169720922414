import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { MainMarketingService, PartnerForInstallResponse } from 'api/client';

import { Card } from 'components/surfaces';
import { Typography } from 'components/dataDisplay';
import { Modal, Spinner } from 'components/feedback';
import { Button, TextField } from 'components/inputs';

import { ReactComponent as CloningIcon } from 'static/icons/cloning.svg';
import { ReactComponent as CheckIcon } from 'static/icons/check-round.svg';
import { ReactComponent as PersonIcon } from 'static/icons/partner-install.svg';

import styles from './InstallMainMarketingPlaceModal.module.scss';

type PlaceOptionType = 'partner' | 'clone';

const InstallMainMarketingPlaceModal: React.FC = () => {
  const { t } = useTranslation();
  const { placeId } = useParams();
  const { mainMarketingStore, layoutStore } = useStore();
  const { desk, installPlaceModalConfig, closeInstallPlaceModal } = mainMarketingStore;

  const [isPartnerSelect, setIsPartnerSelect] = useState(false);
  const [isInstallLoading, setIsInstallLoading] = useState(false);
  const [placeOption, setPlaceOption] = useState<PlaceOptionType>('partner');

  const [partnerUsername, setPartnerUsername] = useState('');
  const [isPartnersLoading, setIsPartnersLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerForInstallResponse | null>(null);
  const [availablePartners, setAvailablePartners] = useState<PartnerForInstallResponse[] | null>(null);
  const [filteredPartners, setFilteredPartners] = useState<PartnerForInstallResponse[] | null>(null);

  const handleClose = () => {
    setPlaceOption('partner');
    setIsPartnerSelect(false);
    setSelectedPartner(null);
    closeInstallPlaceModal();
  };

  const getDesk = () => {
    if (placeId) {
      mainMarketingStore.getDeskByPlaceId(placeId);
    } else {
      mainMarketingStore.getDesk(desk?.current.id.toString()!);
    }
  };

  const handleInstall = () => {
    if (placeOption === 'partner' && !selectedPartner) {
      setIsPartnerSelect(true);
    } else if (placeOption === 'partner' && selectedPartner) {
      setIsInstallLoading(true);
      MainMarketingService.mainMarketingInstallPartner({
        partnerPlaceId: selectedPartner.id,
        rootPlaceId: desk?.items[0]!.id!,
        place: parseInt(installPlaceModalConfig?.place!),
      })
        .then(() => {
          getDesk();
          handleClose();
        })
        .catch((error) => {
          layoutStore.openFeedbackModal(error?.body?.message || t('shared.errors.unknownError'));
        })
        .finally(() => {
          setIsInstallLoading(false);
        });
    } else {
      setIsInstallLoading(true);
      MainMarketingService.mainMarketingInstallClone({
        rootPlaceId: desk?.items[0]?.id!,
        place: parseInt(installPlaceModalConfig?.place!),
      })
        .then(() => {
          getDesk();
          handleClose();
        })
        .catch((error) => {
          layoutStore.openFeedbackModal(error?.body?.message || t('shared.errors.unknownError'));
        })
        .finally(() => {
          setIsInstallLoading(false);
        });
    }
  };

  const filterPartners = debounce((query: string) => {
    setFilteredPartners(
      availablePartners?.filter((partner) => partner.user.username.toLowerCase().includes(query.toLowerCase())) || null
    );
  }, 300);

  useEffect(() => {
    if (isPartnerSelect && desk) {
      setIsPartnersLoading(true);
      MainMarketingService.mainMarketingGetPartnersForInstall(desk.current.id.toString())
        .then(({ data }) => {
          setAvailablePartners(data.items);
        })
        .catch((error) => {
          console.log('[InstallPlaceModal] getAvailablePartners error:', error);
        })
        .finally(() => {
          setIsPartnersLoading(false);
        });
    }
  }, [isPartnerSelect, desk]);

  useEffect(() => {
    filterPartners(partnerUsername);
  }, [availablePartners, partnerUsername, filterPartners]);

  return (
    <Modal
      className={styles.InstallMainMarketingPlaceModal}
      variant="unwrapped"
      isOpen={!!installPlaceModalConfig}
      onClose={handleClose}
    >
      {!isPartnerSelect && (
        <>
          <Typography className={styles.title} variant="heading6" element="h2" color="white">
            {t('modals.installMainMarketingPlace.title')}
          </Typography>

          <div className={styles.switcher}>
            <Button
              className={cx(styles.button, { [styles.active]: placeOption === 'partner' })}
              variant="wrapper"
              onClick={() => setPlaceOption('partner')}
            >
              <PersonIcon className={styles.icon} />

              <Typography className={styles.label} variant="paragraph1" element="span" color="darkGray">
                {t('modals.installMainMarketingPlace.partner')}
              </Typography>

              <CheckIcon className={styles.check} />
            </Button>
            <Button
              className={cx(styles.button, { [styles.active]: placeOption === 'clone' })}
              variant="wrapper"
              onClick={() => setPlaceOption('clone')}
            >
              <CloningIcon className={styles.icon} />

              <Typography className={styles.label} variant="paragraph1" element="span" color="darkGray">
                {t('modals.installMainMarketingPlace.clone')}
              </Typography>

              <CheckIcon className={styles.check} />
            </Button>
          </div>

          <div className={styles.controls}>
            <Button
              className={styles.button}
              variant="outlined"
              color="lightGray"
              disabled={isInstallLoading}
              onClick={handleClose}
            >
              {t('shared.controls.goBack')}
            </Button>
            <Button className={styles.button} isLoading={isInstallLoading} onClick={handleInstall}>
              {t('modals.installMainMarketingPlace.install')}
            </Button>
          </div>
        </>
      )}

      {isPartnerSelect && (
        <>
          <Typography className={styles.inputLabel} variant="heading6" element="h2" color="white">
            {t('modals.installMainMarketingPlace.partnerSelect.label')}:
          </Typography>

          <TextField
            className={styles.input}
            placeholder={t('modals.installMainMarketingPlace.partnerSelect.placeholder')}
            value={partnerUsername}
            onChange={(event) => setPartnerUsername(event.target.value)}
          />

          <Card className={styles.partnersList}>
            {isPartnersLoading && !filteredPartners && <Spinner className={styles.spinner} />}
            {filteredPartners?.length === 0 && (
              <Typography className={styles.noPartners} variant="paragraph2" element="span" color="darkGray">
                {t('modals.installMainMarketingPlace.partnerSelect.notFound')}
              </Typography>
            )}
            {!!filteredPartners?.length &&
              filteredPartners.map((partner) => (
                <Button
                  className={cx(styles.partner, { [styles.selected]: selectedPartner?.id === partner.id })}
                  key={partner.id}
                  variant="wrapper"
                  onClick={() => setSelectedPartner(partner)}
                >
                  <div className={styles.text}>
                    <Typography className={styles.username} variant="paragraph1" element="span" color="darkGray">
                      {partner.user.username} ({partner.line} {t('modals.installMainMarketingPlace.partnerSelect.line')}
                      )
                    </Typography>

                    <Typography className={styles.count} variant="caption1" element="span">
                      {partner.subCount}
                    </Typography>
                  </div>

                  <CheckIcon className={styles.check} />
                </Button>
              ))}
          </Card>

          <div className={styles.controls}>
            <Button
              className={styles.button}
              variant="outlined"
              color="lightGray"
              disabled={isInstallLoading}
              onClick={() => {
                setIsPartnerSelect(false);
                setSelectedPartner(null);
                setAvailablePartners(null);
              }}
            >
              {t('shared.controls.goBack')}
            </Button>
            <Button
              className={styles.button}
              isLoading={isInstallLoading}
              onClick={handleInstall}
              disabled={!selectedPartner}
            >
              {t('modals.installMainMarketingPlace.install')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default observer(InstallMainMarketingPlaceModal);
