import { UseFormReturn, FieldValues, Path } from 'react-hook-form';

export const mapApiErrors = <FormValues extends FieldValues>(
  errors: { property: Path<FormValues>; message: string }[],
  formInstance: UseFormReturn<FormValues>
) => {
  errors.forEach((error) => {
    formInstance.setError(error.property, { message: error.message });
  });
};
