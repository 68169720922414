import { DeskItem2 } from 'api/client';

export enum TopboxLevels {
  Emerald = 'emerald',
  Ruby = 'ruby',
  Diamond = 'diamond',
}

export type GroupedTopmboxMarketingDesksType = { [key in TopboxLevels]: DeskItem2[] };

export type TopboxMarketingStatsFilterType = {
  deskIds: number[];
  lines: number[];
  clones: boolean;
  placesLeft: number[];
  username: string;
};

export type TopboxMarketingStatsFilterModalConfig = {
  filter: TopboxMarketingStatsFilterType;
  onSubmit: (newFilterValue: TopboxMarketingStatsFilterType) => void;
};

export type TopboxReferralLevelStats = {
  '1': number;
  '2': number;
  '3': number;
  '4': number;
  '5': number;
};

export type TopboxReferralStats = {
  [key in TopboxLevels]: TopboxReferralLevelStats;
};
