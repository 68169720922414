import React, { useState } from 'react';

import numbro from 'numbro';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { TopBoxMarketingService } from 'api/client';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './PurchaseTopboxDeskModal.module.scss';

const PurchaseTopboxDeskModal: React.FC = () => {
  const {
    layoutStore,
    topboxMarketingStore: { purchaseDeskModalConfig, closePurchaseDeskModal, getDesks },
    accountingStore,
  } = useStore();
  const { t } = useTranslation();

  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  const handleClose = () => {
    closePurchaseDeskModal();
  };

  const handlePurchase = () => {
    if (!purchaseDeskModalConfig) return;

    setIsPurchaseLoading(true);
    TopBoxMarketingService.topBoxMarketingBuyDesk(purchaseDeskModalConfig.desk.id)
      .then(() => {
        getDesks();
        accountingStore.getBalance();
      })
      .catch((error) => {
        layoutStore.openFeedbackModal(error?.body?.message || t('shared.errors.unknownError'));
      })
      .finally(() => {
        setIsPurchaseLoading(false);
        handleClose();
      });
  };

  return (
    <Modal
      className={styles.PurchaseTopboxDeskModal}
      variant="unwrapped"
      isOpen={!!purchaseDeskModalConfig}
      onClose={handleClose}
    >
      <Typography className={styles.title} variant="heading6" element="h2" color="white">
        {t('modals.purchaseTopboxDesk.title')}
      </Typography>

      <Typography className={styles.text} variant="paragraph1" element="p" color="boneWhite">
        <Trans i18nKey="modals.purchaseTopboxDesk.confirm" components={{ br: <br /> }} />
      </Typography>

      <Typography className={styles.hint} variant="paragraph1" element="p" color="boneWhite">
        {t('modals.purchaseTopboxDesk.price')}:
      </Typography>

      <Typography className={styles.price} variant="heading6" element="p" color="white">
        {numbro(purchaseDeskModalConfig?.desk.price || 0).format({ trimMantissa: false })} <span>ВВ</span>
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          variant="outlined"
          color="lightGray"
          disabled={isPurchaseLoading}
          onClick={handleClose}
        >
          {t('shared.controls.cancel')}
        </Button>
        <Button className={styles.button} isLoading={isPurchaseLoading} onClick={handlePurchase}>
          {t('modals.purchaseTopboxDesk.purchase')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(PurchaseTopboxDeskModal);
