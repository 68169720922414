import React from 'react';

import cx from 'classnames';
import numbro from 'numbro';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useStore } from 'store';
import routes from 'config/routes';
import { RaffleViewModel } from 'api/client';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Avatar from 'components/dataDisplay/Avatar';
import ClipboardCopy from 'components/inputs/ClipboardCopy';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as BoxIcon } from 'static/icons/box.svg';
import { ReactComponent as LockIcon } from 'static/icons/lock.svg';
import { ReactComponent as CopyIcon } from 'static/icons/copy.svg';
import { ReactComponent as TrashIcon } from 'static/icons/trash.svg';
import { ReactComponent as ChevronIcon } from 'static/icons/chevrons/chevron-right.svg';

import styles from './MagicBoxLotCard.module.scss';

interface MagicBoxLotCardProps {
  lot: RaffleViewModel;
}

const MagicBoxLotCard: React.FC<MagicBoxLotCardProps> = ({ lot }) => {
  const { t } = useTranslation();
  const { magicBoxStore } = useStore();

  return (
    <Card className={styles.MagicBoxLotCard}>
      <div className={styles.heading}>
        <div className={cx(styles.status, { [styles.active]: lot.status === 'active' })}>
          <BoxIcon className={styles.icon} />

          <Typography element="span" variant="caption1" color="blackTransparent">
            {lot.status === 'active' && t('shared.magicBoxLot.statuses.active')}
            {lot.status === 'completed' && t('shared.magicBoxLot.statuses.completed')}
            {lot.status === 'canceled' && t('shared.magicBoxLot.statuses.canceled')}
          </Typography>
        </div>

        <div className={styles.controls}>
          {lot.type === 'private' && <LockIcon className={styles.icon} />}
          {lot.owner && lot.status === 'active' && (
            <Button
              className={cx(styles.button, styles.delete)}
              variant="wrapper"
              onClick={() => magicBoxStore.openDeleteLotModal({ lot })}
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      </div>

      <div className={styles.delimeter} />

      <div className={cx(styles.info, styles.main)}>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="blackTransparent">
            {t('shared.magicBoxLot.fields.prize')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="black">
            {lot.prize}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="blackTransparent">
            {t('shared.magicBoxLot.fields.prizePerWinner')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="black">
            x{lot.prizePerWinner}
          </Typography>
        </div>
      </div>

      <div className={styles.delimeter} />

      <div className={styles.info}>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="blackTransparent">
            {t('shared.magicBoxLot.fields.prizeAmount')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="black">
            {numbro(lot.prizeAmount).format()}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="blackTransparent">
            {t('shared.magicBoxLot.fields.ticketPrice')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="black">
            {numbro(lot.ticketPrice).format()}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="blackTransparent">
            {t('shared.magicBoxLot.fields.winnerCount')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="black">
            {lot.winnerCount}
          </Typography>
        </div>
        <Button.RouterLink
          className={cx(styles.item, styles.button)}
          variant="wrapper"
          to={generatePath(routes.magicBoxLot, { lotId: lot.id })}
        >
          <Typography className={styles.label} element="span" variant="caption1" color="blackTransparent">
            {t('shared.magicBoxLot.fields.participants')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="black">
            {lot.ticketsBought}/{lot.totalTickets}
          </Typography>

          <ChevronIcon className={styles.icon} />
        </Button.RouterLink>
      </div>

      <div className={styles.delimeter} />

      <div className={styles.user}>
        <Avatar
          className={styles.avatar}
          size="sm"
          avatar={lot.creator.avatarUrl}
          name={`${lot.creator.firstName} ${lot.creator.lastName}`}
        />

        <div className={styles.userInfo}>
          <Typography className={styles.name} element="span" variant="subtitle1" color="black">
            {`${lot.creator.firstName} ${lot.creator.lastName}`}
          </Typography>
          <Typography className={styles.username} element="span" variant="caption1" color="blackTransparent">
            {lot.creator.username}
          </Typography>
        </div>
      </div>

      <div className={styles.delimeter} />

      <div className={styles.controls}>
        {lot.status === 'active' && (
          <Button size="medium" onClick={() => magicBoxStore.openJoinLotModal({ lot })}>
            {lot.myTicketsBought ? t('shared.magicBoxLot.buyMore') : t('shared.magicBoxLot.join')}
          </Button>
        )}
        {lot.status === 'completed' && (
          <Button.RouterLink size="medium" to={generatePath(routes.magicBoxLot, { lotId: lot.id })}>
            {t('shared.magicBoxLot.checkoutResults')}
          </Button.RouterLink>
        )}
        <ClipboardCopy
          className={styles.copy}
          value={`${window.location.origin}${generatePath(routes.magicBoxLot, { lotId: lot.id })}`}
        >
          <CopyIcon className={styles.icon} />
        </ClipboardCopy>
      </div>
    </Card>
  );
};

export default MagicBoxLotCard;
