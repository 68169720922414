import React from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import routes from 'config/routes';

import { ReactComponent as NotFoundPageSvg } from 'static/images/not-found-page.svg';
import { ReactComponent as NotFoundLogoSvg } from 'static/images/not-found-logo.svg';

import styles from './NotFoundPage.module.scss';

interface NotFoundPageProps {
  redirect?: string;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ redirect }) => {
  return (
    <div className={styles.NotFoundPage}>
      <div className={styles.pageContainer}>
        <NotFoundPageSvg className={styles.icon} />
        <h1 className={styles.notFoundPageHeader}>страница не найдена</h1>
        <span className={styles.notFoundPageDescription}>Извините, мы не можем найти страницу, которую вы ищете.</span>
        <Link to={redirect ?? routes.root} className={styles.notFoundPageLinkWrapper}>
          Перейти на главную
        </Link>
        <NotFoundLogoSvg className={styles.logoIcon} />
      </div>
    </div>
  );
};

export default observer(NotFoundPage);
