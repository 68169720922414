import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { ApiError, LineMegaBoxStatisticsItem, MegaBoxMarketingService, PreOrderDeskListItem } from 'api/client';
import RootStore from 'store';
import {
  GroupedMegaBoxMarketingDesksType,
  MegaBoxMarketingStatsFilterType,
  PurchaseMegaBoxPlacesModalConfigType,
} from 'types/megaBoxMarketing';

export default class MegaBoxMarketingStore {
  rootStore: RootStore;

  purchasePlacesModalConfig: PurchaseMegaBoxPlacesModalConfigType | null;

  desks: PreOrderDeskListItem[] | null;

  stats: {
    items: LineMegaBoxStatisticsItem[];
    total: number;
    perPage: number;
    page: number;
    filter: MegaBoxMarketingStatsFilterType;
  };

  loading: {
    getDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'megaBoxMarketingStore' });
    this.rootStore = rootStore;
    this.purchasePlacesModalConfig = null;

    this.desks = null;
    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        line: 1,
        isBuy: true,
        username: '',
      },
    };

    this.loading = {
      getDesks: false,
      getStats: false,
    };
    this.errors = {
      getDesks: null,
    };
  }

  get desksGroupedByBlocks() {
    console.log(groupBy(this.desks, (desk) => desk.blockName.split(' ')[0]) as GroupedMegaBoxMarketingDesksType);
    return this.desks
      ? (groupBy(this.desks, (desk) => desk.blockName.split(' ')[0]) as GroupedMegaBoxMarketingDesksType)
      : null;
  }

  *getDesks() {
    try {
      this.loading.getDesks = true;
      const { data } = yield MegaBoxMarketingService.getMegaBoxPreOrderDeskList();
      this.desks = data.items;
    } catch (error) {
      this.errors.getDesks = error as ApiError;
      console.log('[MegaBoxMarketingStore] getDesks error:', error);
    } finally {
      this.loading.getDesks = false;
    }
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield MegaBoxMarketingService.getMegaBoxPreOrderStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.line,
        this.stats.filter.username,
        this.stats.filter.isBuy
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[MegaBoxMarketingStore] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: MegaBoxMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openPurchasePlacesModal(config: PurchaseMegaBoxPlacesModalConfigType) {
    this.purchasePlacesModalConfig = config;
  }

  closePurchasePlacesModal() {
    this.purchasePlacesModalConfig = null;
  }
}
