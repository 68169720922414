import React, { useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import numbro from 'numbro';
import { observer } from 'mobx-react';
import { NumericFormat } from 'react-number-format';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { useStore } from 'store';
import { SelectOption } from 'types/common';
import { mapApiErrors } from 'utils/errorHandling';
import { AccountingService, ApiError } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, PasswordField, TextField, Select } from 'components/inputs';

import styles from './WithdrawModal.module.scss';

interface WithdrawValues {
  amount: number | string;
  wallet: string;
  financialPassword: string;
  bankId?: string;
}

const comissions: { [key: string]: string } = {
  USDT: '4 USDT',
  PAYEER: '3%',
  QIWI: '5%',
  SBP: '5%',
  YOOMONEY: '5%',
  PERFECT_MONEY: '17%',
  FREEKASSA: '7%',
};

const WithdrawModal: React.FC = () => {
  const { t } = useTranslation();
  const { accountingStore, userStore, layoutStore } = useStore();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [USDTPrice, setUSDTPrice] = useState(0);
  const [sbpBanksList, setSbpBanksList] = useState<SelectOption[]>([]);

  const getSbpBanksList = async () => {
    try {
      const { data } = await AccountingService.freekassaSbpBanks();
      setSbpBanksList(data.items.map((bank) => ({ label: bank.name, value: bank.id })));
    } catch (error) {
      console.log('[getSbpBanksList] errors', error);
    }
  };

  const withdrawSchema: yup.SchemaOf<WithdrawValues> = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()
          .nullable()
          .required(t('modals.withdraw.errors.amountRequired') as string)
          .positive(t('modals.withdraw.errors.amountPositive') as string)
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
        wallet: yup
          .string()
          .required(
            (accountingStore.withdrawModalConfig?.platform === 'QIWI'
              ? t('modals.withdraw.errors.phoneRequired')
              : t('modals.withdraw.errors.walletRequired')) as string
          ),
        financialPassword: yup.string().required(t('modals.withdraw.errors.financialPasswordRequired') as string),
        bankId: yup.string().when('$platform', {
          is: (platform: string) => platform === 'SBP',
          then: (schema) => schema.required(t('modals.withdraw.errors.bankRequired') as string),
        }),
      }),
    [accountingStore.withdrawModalConfig, t]
  );

  const withdrawForm = useForm<WithdrawValues>({
    resolver: yupResolver(withdrawSchema),
    mode: 'onTouched',
    context: { platform: accountingStore.withdrawModalConfig?.platform },
    defaultValues: {
      amount: '',
      wallet: '',
      financialPassword: '',
      bankId: undefined,
    },
  });
  const amountValue = withdrawForm.watch('amount');

  const handleClose = () => {
    setIsLoading(false);
    setSuccess(false);
    withdrawForm.reset({
      amount: '',
      wallet: '',
      financialPassword: '',
    });
    accountingStore.closeWithdrawModal();
  };

  const handleWithdrawSubmit = withdrawForm.handleSubmit(async (values: WithdrawValues) => {
    try {
      setIsLoading(true);
      await accountingStore.withdrawModalConfig?.submitHandler({ ...values, amount: values.amount as number });
      accountingStore.getBalance();
      setSuccess(true);
      userStore.openCreateReviewModal({
        label: <Trans i18nKey="modals.withdraw.reviewMessage" components={{ br: <br /> }} />,
      });
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, withdrawForm);
      } else {
        layoutStore.openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
      }
    } finally {
      setIsLoading(false);
    }
  });

  const getUSDTPrice = async () => {
    try {
      setIsLoading(true);
      const { data } = await AccountingService.getUsdtPrice();
      setUSDTPrice(data.price);
    } catch (error) {
      console.log('[WithdrawModal] getUSDTPrice error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accountingStore.withdrawModalConfig?.platform === 'USDT') {
      getUSDTPrice();
    }
    if (accountingStore.withdrawModalConfig?.platform === 'SBP') {
      getSbpBanksList();
    }
  }, [accountingStore.withdrawModalConfig]);

  return (
    <Modal className={styles.WithdrawModal} isOpen={!!accountingStore.withdrawModalConfig} onClose={handleClose}>
      <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
        {t('modals.withdraw.title', { platform: accountingStore.withdrawModalConfig?.platform })}
      </Typography>

      <Typography className={styles.title} element="p" variant="paragraph2" color="blackTransparent">
        {t('modals.withdraw.comission', { value: comissions[accountingStore.withdrawModalConfig?.platform!] })}
      </Typography>

      {!success && (
        <form className={styles.form} onSubmit={handleWithdrawSubmit}>
          <Controller
            control={withdrawForm.control}
            name="amount"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="number"
                label={t('modals.withdraw.labels.amount')}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={withdrawForm.control}
            name="wallet"
            render={({ field: { ref, ...fieldProps }, fieldState }) => (
              <>
                {!['QIWI', 'SBP'].includes(accountingStore.withdrawModalConfig?.platform ?? '') && (
                  <TextField
                    {...fieldProps}
                    ref={ref}
                    label={
                      accountingStore.withdrawModalConfig?.platform === 'FREEKASSA'
                        ? 'FKwallet'
                        : t('modals.withdraw.labels.account')
                    }
                    error={fieldState.error?.message}
                  />
                )}
                {['QIWI', 'SBP'].includes(accountingStore.withdrawModalConfig?.platform ?? '') && (
                  <NumericFormat
                    {...fieldProps}
                    error={fieldState.error?.message}
                    label={t('modals.withdraw.labels.phone')}
                    customInput={TextField}
                    prefix={accountingStore.withdrawModalConfig?.platform === 'SBP' ? '+7' : '+'}
                    placeholder={accountingStore.withdrawModalConfig?.platform === 'SBP' ? '+7' : undefined}
                  />
                )}
              </>
            )}
          />

          {accountingStore.withdrawModalConfig?.platform === 'SBP' && (
            <Controller
              control={withdrawForm.control}
              name="bankId"
              render={({ field, fieldState }) => (
                <Select
                  options={sbpBanksList}
                  label={t('modals.withdraw.labels.bank')}
                  value={sbpBanksList.find((option) => option.value === field.value)}
                  error={fieldState.error?.message}
                  onChange={(newValue) => field.onChange((newValue as SelectOption).value)}
                />
              )}
            />
          )}

          <Controller
            control={withdrawForm.control}
            name="financialPassword"
            render={({ field, fieldState }) => (
              <PasswordField
                {...field}
                label={t('modals.withdraw.labels.financialPassword')}
                error={fieldState.error?.message}
              />
            )}
          />

          <div className={styles.controls}>
            <Button className={styles.button} isLoading={isLoading} type="submit">
              {t('modals.withdraw.submit')}
              {accountingStore.withdrawModalConfig?.platform === 'USDT' &&
                amountValue &&
                USDTPrice &&
                ` ${numbro(parseInt(amountValue as string) / USDTPrice).format()} USDT`}
            </Button>
            <Button className={styles.button} disabled={isLoading} variant="outlined" onClick={handleClose}>
              {t('shared.controls.cancel')}
            </Button>
          </div>
        </form>
      )}

      {!!success && (
        <>
          <Typography className={styles.message} element="p" variant="paragraph1">
            {t('modals.withdraw.sucess')}
          </Typography>

          <Button className={styles.button} onClick={handleClose}>
            OK
          </Button>
        </>
      )}
    </Modal>
  );
};

export default observer(WithdrawModal);
