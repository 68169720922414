import React from 'react';

import cx from 'classnames';
import { NumericFormat } from 'react-number-format';

import Button from 'components/inputs/Button';
import TextField from 'components/inputs/TextField';
import Typography from 'components/dataDisplay/Typography';

import styles from './NumberFieldWithControls.module.scss';

interface NumberFieldWithControlsProps {
  name?: string;
  value: string | number;
  onChange: (newValue: number | null) => void;
  onBlur?: React.FocusEventHandler;
  onFocus?: React.FocusEventHandler;
  disabled?: boolean;
  label?: string | null;
  placeholder?: string | null;
  error?: string;
  className?: string;
  max?: number;
  min?: number;
  allowEmpty?: boolean;
  allowNegative?: boolean;
  allowLeadingZeros?: boolean;
  decimalScale?: number;
}

const NumberFieldWithControls: React.FC<NumberFieldWithControlsProps> = ({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  disabled,
  label,
  placeholder,
  error,
  className,
  max,
  min,
  /* allowEmpty = true, */
  allowNegative = false,
  allowLeadingZeros = false,
  decimalScale = 0,
}) => {
  const increment = () => {
    const currentValue = parseFloat(value as string);

    if (isNaN(currentValue)) {
      onChange(min ?? 1);
    } else {
      onChange(Math.min(currentValue + 1, max ?? currentValue + 1));
    }
  };

  const decrement = () => {
    const currentValue = parseFloat(value as string);
    const minimalDecrement = allowNegative ? Math.min(min ?? -1, -1) : Math.max(0, min ?? 0);

    if (isNaN(currentValue)) {
      onChange(minimalDecrement);
    } else {
      onChange(Math.max(currentValue - 1, minimalDecrement ?? currentValue - 1));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);

    if (isNaN(newValue)) {
      onChange(null);
    } else {
      /* const maxNewValue = Math.min(newValue, max ?? newValue);
      onChange(Math.max(maxNewValue, min ?? maxNewValue)); */
      onChange(newValue);
    }
  };

  return (
    <div
      className={cx(
        styles.NumberFieldWithControls,
        {
          [styles.hasError]: !!error,
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      {(label || error) && (
        <Typography className={styles.label} variant="paragraph2" element="span" color="darkGray">
          {error || label}
        </Typography>
      )}

      <div className={styles.fieldWrapper}>
        <Button className={styles.button} variant="outlined" color="black" onClick={decrement}>
          -
        </Button>
        <NumericFormat
          className={styles.input}
          name={name}
          value={value}
          onChange={handleChange}
          /* isAllowed={({ floatValue, value: newValue }) => {
            if (!allowEmpty && !newValue) return false;
            return !floatValue || ((!max || floatValue <= max) && (!min || floatValue >= min));
          }} */
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder}
          allowNegative={allowNegative}
          allowLeadingZeros={allowLeadingZeros}
          decimalScale={decimalScale}
          customInput={TextField}
        />
        <Button className={styles.button} variant="outlined" color="black" onClick={increment}>
          +
        </Button>
      </div>
    </div>
  );
};

export default NumberFieldWithControls;
