import React from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { useScrollLock } from 'utils/hooks';

import Button from 'components/inputs/Button';

import { ReactComponent as CloseIcon } from 'static/icons/close-small.svg';

import styles from './Modal.module.scss';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  variant?: 'default' | 'unwrapped';
  className?: string;
  overlayClassName?: string;
  contentClassName?: string;
  headingClassName?: string;
  onClose?: () => void;
  lockScroll?: boolean;
  heading?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  children,
  variant = 'default',
  isOpen,
  className,
  overlayClassName,
  contentClassName,
  headingClassName,
  onClose,
  lockScroll = true,
  heading,
}) => {
  useScrollLock(lockScroll && isOpen);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modalOverlay"
          className={cx(styles.overlay, styles[variant], { [styles.noClose]: !onClose }, overlayClassName)}
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <motion.div
            key="modalContent"
            className={cx(styles.card, styles[variant], { [styles.headed]: !!heading }, className)}
            variants={{
              hide: { transform: 'translateY(20px)' },
              show: { transform: 'translateY(0px)' },
            }}
          >
            {heading ? (
              <>
                <div className={cx(styles.header, headingClassName)}>
                  {heading}
                  {onClose && (
                    <Button className={styles.close} variant="wrapper" onClick={onClose}>
                      <CloseIcon className={styles.icon} />
                    </Button>
                  )}
                </div>
                <div className={cx(styles.content, contentClassName)}>{children}</div>
              </>
            ) : (
              children
            )}
          </motion.div>
          {onClose && !heading && (
            <Button className={styles.close} variant="wrapper" onClick={onClose}>
              <CloseIcon className={styles.icon} />
            </Button>
          )}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
