import React, { ReactNode } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as EditIcon } from 'static/icons/edit.svg';
import { ReactComponent as CloseIcon } from 'static/icons/close.svg';

import styles from './UserFieldCard.module.scss';

interface UserFieldCardProps {
  className?: string;
  prefix?: ReactNode;
  value?: ReactNode;
  label?: ReactNode;
  postfix?: ReactNode;
  isEditDisabled?: boolean;
  isClearDisabled?: boolean;
  isEditLoading?: boolean;
  isClearLoading?: boolean;
  onEdit?: () => void;
  onClear?: () => void;
}

const UserFieldCard: React.FC<UserFieldCardProps> = ({
  className,
  prefix,
  value,
  label,
  postfix,
  isEditDisabled,
  isClearDisabled,
  isEditLoading,
  isClearLoading,
  onEdit,
  onClear,
}) => {
  const { t } = useTranslation();

  return (
    <Card className={cx(styles.UserFieldCard, className)}>
      {prefix}

      <div className={styles.text}>
        {value && (
          <Typography className={styles.value} element="p" variant="subtitle1" color="black">
            {value}
          </Typography>
        )}

        {label && (
          <Typography className={styles.label} element="p" variant="caption1" color="blackTransparent">
            {label}
          </Typography>
        )}
      </div>

      {onEdit && (
        <Button
          className={styles.button}
          variant="outlined"
          color="black"
          size="small"
          iconStart={EditIcon}
          disabled={isEditDisabled}
          isLoading={isEditLoading}
          onClick={onEdit}
        >
          <span>{t('shared.controls.edit')}</span>
        </Button>
      )}

      {onClear && (
        <Button
          className={cx(styles.button, styles.clear)}
          variant="outlined"
          color="red"
          size="small"
          disabled={isClearDisabled}
          isLoading={isClearLoading}
          onClick={onClear}
        >
          <CloseIcon className={styles.icon} />
        </Button>
      )}

      {postfix}
    </Card>
  );
};

export default UserFieldCard;
