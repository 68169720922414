import React, { useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';
import { mapApiErrors } from 'utils/errorHandling';
import { ApiError, UsersService } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, TextField } from 'components/inputs';

import styles from './CreateNotificationModal.module.scss';

interface NotificationValues {
  message: string;
}

const CreateNotificationModal: React.FC = () => {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const modalConfig = userStore.createNotificationModalConfig;

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const notificationSchema: yup.SchemaOf<NotificationValues> = useMemo(
    () =>
      yup.object().shape({
        message: yup.string().required(t('modals.createNotification.messageRequired') as string),
      }),
    [t]
  );

  const notificationForm = useForm<NotificationValues>({
    mode: 'onTouched',
    resolver: yupResolver(notificationSchema),
    defaultValues: {
      message: '',
    },
  });

  const handleNotificationSubmit = notificationForm.handleSubmit(async (values: NotificationValues) => {
    try {
      setIsLoading(true);
      switch (modalConfig?.type) {
        case 'curator':
          await UsersService.writeMessageToCurator(values);
          break;
        case 'team':
          await UsersService.writeMessageToTeam(values);
          break;
        default:
      }
      setIsSuccess(true);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, notificationForm);
      } else {
        notificationForm.setError('message', { message: apiError?.body?.message || t('shared.errors.unknownError') });
      }
    } finally {
      setIsLoading(false);
    }
  });

  const handleClose = () => {
    setIsSuccess(false);
    userStore.closeCreateNotificationModal();
  };

  useEffect(() => {
    notificationForm.reset({ message: '' });
  }, [notificationForm, userStore.createNotificationModalConfig]);

  return (
    <Modal className={styles.CreateNotificationModal} isOpen={!!modalConfig} onClose={handleClose}>
      {!isSuccess && (
        <>
          <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
            {t('modals.createNotification.title', {
              reciever: t(`modals.createNotification.recievers.${modalConfig?.type}`),
            })}
          </Typography>

          <form className={styles.form} onSubmit={handleNotificationSubmit}>
            <Controller
              control={notificationForm.control}
              name="message"
              render={({ field, fieldState }) => (
                <TextField
                  className={styles.field}
                  {...field}
                  error={fieldState.error?.message}
                  placeholder={t('modals.createNotification.placeholder')}
                  maxLength={400}
                  multiline
                />
              )}
            />

            <div className={styles.controls}>
              <Button
                className={styles.button}
                variant="outlined"
                color="red"
                disabled={isLoading}
                onClick={handleClose}
              >
                {t('shared.controls.cancel')}
              </Button>
              <Button className={styles.button} type="submit" isLoading={isLoading}>
                {t('modals.createNotification.submit')}
              </Button>
            </div>
          </form>
        </>
      )}

      {isSuccess && (
        <>
          <Typography className={styles.title} element="h2" variant="paragraph1" color="black">
            {t('modals.createNotification.success')}
          </Typography>

          <Button className={styles.button} onClick={handleClose}>
            OK
          </Button>
        </>
      )}
    </Modal>
  );
};

export default observer(CreateNotificationModal);
