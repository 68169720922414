/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyCloneRequest } from '../models/BuyCloneRequest';
import type { DeskItem } from '../models/DeskItem';
import type { DeskItemPlaces } from '../models/DeskItemPlaces';
import type { FoundDeskUser } from '../models/FoundDeskUser';
import type { InstallCloneRequest } from '../models/InstallCloneRequest';
import type { InstallPartnerRequest } from '../models/InstallPartnerRequest';
import type { MainStatistics } from '../models/MainStatistics';
import type { MyInstalledClone } from '../models/MyInstalledClone';
import type { PartnerForInstallResponse } from '../models/PartnerForInstallResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MainMarketingService {
  /**
   * Gets the list of desks in the main marketing
   * @returns any Returns the list of desks in the main marketing
   * @throws ApiError
   */
  public static mainMarketingGetDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DeskItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/desk/list',
    });
  }

  /**
   * Gets the structure of the desk.
   * @param deskId
   * @returns any Returns the structure of the desk.
   * @throws ApiError
   */
  public static mainMarketingShowDesk(deskId: string): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/desk/{deskId}/show',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting from the given place.
   * @param placeId
   * @returns any Returns the desk structure starting from the given place.
   * @throws ApiError
   */
  public static mainMarketingShowDeskFromPlace(placeId: string): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/place/{placeId}/show',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of partners to install
   * @param deskId
   * @returns any Returns a list of partners to install
   * @throws ApiError
   */
  public static mainMarketingGetPartnersForInstall(deskId: string): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<PartnerForInstallResponse>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/desk/{deskId}/partners',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Buys the desk in the main marketing
   * @param deskId Desk ID
   * @returns void
   * @throws ApiError
   */
  public static mainMarketingBuyDesk(deskId: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/main/desk/{deskId}/buy',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Buys a clone in the main marketing
   * @param deskId Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static mainMarketingBuyClone(deskId: number, requestBody: BuyCloneRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/main/clone/{deskId}/buy',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Searches for a user in the desk by username
   * @param deskId Desk id
   * @param q Username
   * @param limit
   * @returns any Returns found users on the desk
   * @throws ApiError
   */
  public static mainMarketingSearchUser(
    deskId: number,
    q: string,
    limit: number = 10
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FoundDeskUser>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/desk/{deskId}/search/user',
      path: {
        deskId: deskId,
      },
      query: {
        q: q,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of my installed clones.
   * @param deskId Desk ID
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of my installed clones.
   * @throws ApiError
   */
  public static mainMarketingMyInstalledClones(
    deskId: number,
    offset?: number,
    limit: number = 50
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<MyInstalledClone>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/clone/{deskId}/installed',
      path: {
        deskId: deskId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets desk statistics in main marketing
   * @param offset Offset
   * @param limit Limit
   * @param filterDesk Desk ids
   * @param filterLine Lines
   * @param filterClones Show only clones
   * @param filterPlacesLeft Places before closing
   * @param filterUsername Username query
   * @returns any Returns desk statistics in main marketing
   * @throws ApiError
   */
  public static mainMarketingGetDeskStatistics(
    offset?: number,
    limit: number = 12,
    filterDesk?: Array<number>,
    filterLine?: Array<1 | 2 | 3 | 4 | 5>,
    filterClones?: boolean,
    filterPlacesLeft?: Array<1 | 2 | 3 | 4 | 5 | 6>,
    filterUsername?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<MainStatistics>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/main/desk/statistics',
      query: {
        offset: offset,
        limit: limit,
        'filter[desk][]': filterDesk,
        'filter[line][]': filterLine,
        'filter[clones]': filterClones,
        'filter[placesLeft][]': filterPlacesLeft,
        'filter[username]': filterUsername,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Installs the partner at the specified place on the desk.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static mainMarketingInstallPartner(requestBody: InstallPartnerRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/main/install/partner',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Installs a clone at the specified place on the desk.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static mainMarketingInstallClone(requestBody: InstallCloneRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/main/install/clone',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }
}
