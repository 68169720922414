import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from 'store';
import { SelectOption } from 'types/common';
import { mapApiErrors } from 'utils/errorHandling';
import { AdminService, ApiError } from 'api/client';
import { reviewStatusOptions } from 'const/adminReviewsFilters';

import { Button, Checkbox, Select, TextField } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './AdminEditReviewModal.module.scss';

interface EditReviewValues {
  text: string;
  status: number;
  publishedOnMain: boolean;
}

const editReviewFormSchema: yup.SchemaOf<EditReviewValues> = yup.object().shape({
  text: yup.string().required('Укажите текст отзыва'),
  status: yup.number().required(),
  publishedOnMain: yup.boolean().required(),
});

const AdminEditReviewModal: React.FC = () => {
  const { adminStore, layoutStore } = useStore();
  const review = adminStore.adminEditReviewModalConfig?.review;

  const [isLoading, setIsLoading] = useState(false);

  const editReviewForm = useForm<EditReviewValues>({
    mode: 'onTouched',
    resolver: yupResolver(editReviewFormSchema),
    defaultValues: {
      text: review?.text ?? '',
      status: review?.status ?? 0,
      publishedOnMain: review?.isPublishedOnMain ?? false,
    },
  });

  const handleSubmit = editReviewForm.handleSubmit(async (values: EditReviewValues) => {
    try {
      setIsLoading(true);
      await AdminService.updateReview(review?.id!, values);
      adminStore.closeAdminEditReviewModal();
      adminStore.getReviews();
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, editReviewForm);
      } else {
        layoutStore.openFeedbackModal(apiError?.body?.message || 'Неизвестная ошибка');
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    editReviewForm.reset({
      text: review?.text ?? '',
      status: review?.status ?? 0,
      publishedOnMain: review?.isPublishedOnMain ?? false,
    });
  }, [editReviewForm, review]);

  return (
    <Modal
      className={styles.AdminEditReviewModal}
      isOpen={!!adminStore.adminEditReviewModalConfig}
      onClose={adminStore.closeAdminEditReviewModal}
    >
      <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
        Редактирование
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit}>
        <Controller
          control={editReviewForm.control}
          name="text"
          render={({ field, fieldState }) => (
            <TextField {...field} error={fieldState.error?.message} label="Текст отзыва" maxLength={400} multiline />
          )}
        />

        <Controller
          control={editReviewForm.control}
          name="status"
          render={({ field, fieldState }) => (
            <Select
              label="Статус"
              options={reviewStatusOptions}
              error={fieldState.error?.message}
              onChange={(newValue) => field.onChange((newValue as SelectOption).value)}
              value={reviewStatusOptions.find((option) => option.value === field.value)}
            />
          )}
        />

        <Controller
          control={editReviewForm.control}
          name="publishedOnMain"
          render={({ field, fieldState }) => (
            <Checkbox className={styles.checkbox} {...field} checked={field.value} error={fieldState.error?.message}>
              Опубликован на главной
            </Checkbox>
          )}
        />

        <div className={styles.controls}>
          <Button className={styles.button} isLoading={isLoading} type="submit">
            Сохранить
          </Button>
          <Button
            className={styles.button}
            disabled={isLoading}
            variant="outlined"
            onClick={adminStore.closeAdminEditReviewModal}
          >
            Отмена
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(AdminEditReviewModal);
