import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from 'store';

import styles from './Snowfall.module.scss';

const Snowfall: React.FC = () => {
  const { layoutStore } = useStore();

  return (
    <div className={styles.snowfall}>
      {layoutStore.isSnowEnabled &&
        Array(60)
          .fill(null)
          .map((_, index) => <div className={styles.snowflake} key={index} />)}
    </div>
  );
};

export default observer(Snowfall);
