import React from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { useStore } from 'store';
import routes from 'config/routes';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';

import boxImg from './assets/box.png';
import tictacGreenImg from './assets/tictac-green.png';
import tictacOrangeImg from './assets/tictac-orange.png';
import tictacYellowImg from './assets/tictac-yellow.png';
import styles from './TictacPromoModal.module.scss';

const TictacPromoModal: React.FC = () => {
  const { t } = useTranslation();
  const { mainMarketingStore } = useStore();

  return (
    <Modal className={styles.TictacPromoModal} isOpen={mainMarketingStore.isMainMarketingPromoModalOpen}>
      <img className={cx(styles.image, styles.box)} src={boxImg} alt="" />
      <img className={cx(styles.image, styles.tictacOrange)} src={tictacOrangeImg} alt="" />
      <img className={cx(styles.image, styles.tictacGreen)} src={tictacGreenImg} alt="" />
      <img className={cx(styles.image, styles.tictacYellow)} src={tictacYellowImg} alt="" />

      <Typography className={styles.title} element="h2" color="green" variant="heading6" fontFamily="montserrat">
        {<Trans i18nKey="modals.promo.tictac.title" components={{ br: <br /> }} />}
      </Typography>

      <Typography className={styles.text} element="h2" color="white" variant="subtitle1">
        {<Trans i18nKey="modals.promo.tictac.text" components={{ br: <br /> }} />}
      </Typography>

      <Typography className={styles.subtitle} element="h2" color="white" variant="heading6">
        {<Trans i18nKey="modals.promo.tictac.subtitle" components={{ br: <br /> }} />}
      </Typography>

      <Button.RouterLink
        className={styles.link}
        onClick={mainMarketingStore.closeMainMarketingPromoModal}
        to={routes.tictacMarketing}
        size="small"
      >
        {t('modals.promo.link')}
      </Button.RouterLink>

      <Button className={styles.close} variant="wrapper" onClick={mainMarketingStore.closeMainMarketingPromoModal}>
        <CloseIcon className={styles.icon} />
      </Button>
    </Modal>
  );
};

export default observer(TictacPromoModal);
