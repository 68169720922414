/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RafflePrize } from './RafflePrize';

export type CreateRaffleRequest = {
  type: CreateRaffleRequest.type;
  prize: RafflePrize;
  totalTickets: number;
  winnerCount: number;
  prizesPerWinner: number;
  password?: string | null;
};

export namespace CreateRaffleRequest {
  export enum type {
    PUBLIC = 'public',
    PRIVATE = 'private',
  }
}
