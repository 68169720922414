import React from 'react';

import { Logo } from 'components/dataDisplay';

import styles from './PreloaderPage.module.scss';

const PreloaderPage: React.FC = () => {
  return (
    <div className={styles.PreloaderPage}>
      <Logo
        className={styles.logo}
        iconClassName={styles.icon}
        textClassName={styles.text}
        color="white"
        variant="verticalCentered"
      />
    </div>
  );
};

export default PreloaderPage;
