import React, { Fragment } from 'react';

import cx from 'classnames';

import Button from 'components/inputs/Button';

import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
  className?: string;
  links: {
    label: React.ReactNode;
    to: string;
  }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className, links }) => {
  return (
    <div className={cx(styles.Breadcrumbs, className)}>
      {links.map((link, index) => (
        <Fragment key={link.to}>
          <Button.RouterLink
            className={styles.link}
            to={link.to}
            variant="text"
            color={index === links.length - 1 ? 'black' : 'lightGray'}
          >
            {link.label}
          </Button.RouterLink>

          {index !== links.length - 1 && <span className={styles.delimeter} />}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
