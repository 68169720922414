/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserData } from './UserData';

export type FullReviewResponse = {
  id: string;
  user: UserData;
  text: string;
  isPublishedOnMain: boolean;
  /**
   * New: 0<br/>Approved: 1<br/>Declined: 2
   */
  status: FullReviewResponse.status;
  createdAt: string;
  updatedAt: string;
};

export namespace FullReviewResponse {
  /**
   * New: 0<br/>Approved: 1<br/>Declined: 2
   */
  export enum status {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}
