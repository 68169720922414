import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

import { useStore } from 'store';
import routes from 'config/routes';
import { FullReviewResponse } from 'api/client';
import { reviewStatusOptions } from 'const/adminReviewsFilters';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as EditIcon } from 'static/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'static/icons/trash.svg';

import styles from './AdminReviewCard.module.scss';

interface AdminReviewCardProps {
  review: FullReviewResponse;
}

const AdminReviewCard: React.FC<AdminReviewCardProps> = ({ review }) => {
  const { adminStore } = useStore();

  return (
    <Card className={styles.AdminReviewCard}>
      <div className={styles.data}>
        <Typography className={styles.info} element="span" variant="caption1" color="blackTransparent">
          Автор:{' '}
          {
            <Button.RouterLink
              className={styles.link}
              variant="text"
              to={generatePath(routes.adminUser, { userId: review.user.id })}
            >
              {review.user.username}
            </Button.RouterLink>
          }
        </Typography>

        <Typography className={styles.info} element="span" variant="caption1" color="blackTransparent">
          Дата создания: {moment(review.createdAt).format('DD.MM.YYYY')}
        </Typography>

        <Typography className={styles.info} element="span" variant="caption1" color="blackTransparent">
          Дата обновления: {moment(review.updatedAt).format('DD.MM.YYYY')}
        </Typography>

        <Typography className={styles.info} element="span" variant="caption1" color="blackTransparent">
          Статус: {reviewStatusOptions.find((statusOption) => statusOption.value === review.status)?.label || '—'}
        </Typography>

        <Typography className={styles.info} element="span" variant="caption1" color="blackTransparent">
          Опубликован на главной: {review.isPublishedOnMain ? 'Да' : 'Нет'}
        </Typography>

        <Typography className={styles.message} variant="paragraph2" color="black">
          {review.text}
        </Typography>
      </div>

      <div className={styles.controls}>
        <Button
          className={cx(styles.button, styles.edit)}
          variant="wrapper"
          onClick={() => adminStore.openAdminEditReviewModal({ review })}
        >
          <EditIcon className={styles.icon} />
        </Button>

        <Button
          className={cx(styles.button, styles.delete)}
          variant="wrapper"
          onClick={() => adminStore.openAdminDeleteReviewModal({ id: review.id })}
        >
          <TrashIcon className={styles.icon} />
        </Button>
      </div>
    </Card>
  );
};

export default AdminReviewCard;
