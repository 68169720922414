import React, { useEffect } from 'react';

import * as yup from 'yup';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, TextField } from 'components/inputs';

import styles from './AdminNotificationFormModal.module.scss';

interface AdminNotificationValues {
  message: string;
}

const adminNotificationSchema: yup.SchemaOf<AdminNotificationValues> = yup.object().shape({
  message: yup.string().required('Укажите текст сообщения'),
});

const AdminNotificationFormModal: React.FC = () => {
  const { adminStore } = useStore();
  const modalConfig = adminStore.adminNotificationFormModalConfig;

  const adminNotificationForm = useForm<AdminNotificationValues>({
    mode: 'onTouched',
    resolver: yupResolver(adminNotificationSchema),
    defaultValues: {
      message: modalConfig?.message?.message || '',
    },
  });

  const handleNotificationSubmit = adminNotificationForm.handleSubmit((values: AdminNotificationValues) => {
    if (modalConfig?.message) {
      adminStore.updateSystemNotification(modalConfig.message.id.toString(), values);
    } else {
      adminStore.createSystemNotification(values);
    }
  });

  const handleClose = () => {
    adminStore.closeAdminNotificationFormModal();
  };

  useEffect(() => {
    adminNotificationForm.reset({
      message: modalConfig?.message?.message || '',
    });
  }, [adminNotificationForm, modalConfig]);

  return (
    <Modal className={styles.AdminNotificationFormModal} isOpen={!!modalConfig} onClose={handleClose}>
      <Typography className={styles.title} element="h2" variant="heading6" color="black">
        {modalConfig?.message ? 'Редактировать сообщение' : 'Новое сообщение'}
      </Typography>

      <form className={styles.form} onSubmit={handleNotificationSubmit}>
        <Controller
          control={adminNotificationForm.control}
          name="message"
          render={({ field, fieldState }) => (
            <TextField {...field} error={fieldState.error?.message} label="Текст сообщения" multiline />
          )}
        />

        <div className={styles.controls}>
          <Button
            className={styles.button}
            type="submit"
            isLoading={adminStore.loading.createOrUpdateSystemNotification}
          >
            Сохранить
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            disabled={adminStore.loading.createOrUpdateSystemNotification}
            onClick={handleClose}
          >
            Отмена
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(AdminNotificationFormModal);
