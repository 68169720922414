import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useStore } from 'store';
import routes from 'config/routes';
import { TicTacMarketingService, MyInstalledClone } from 'api/client';

import { Button } from 'components/inputs';
import { Card } from 'components/surfaces';
import { Pagination } from 'components/navigation';
import { Typography } from 'components/dataDisplay';
import { Modal, Spinner } from 'components/feedback';

import styles from './UserTictacClonesModal.module.scss';

const CLONES_PER_PAGE = 5;

const UserTictacClonesModal: React.FC = () => {
  const { t } = useTranslation();
  const { tictacMarketingStore } = useStore();

  const [clonesPage, setClonesPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clones, setClones] = useState<{ items: MyInstalledClone[]; total: number }>({ items: [], total: 0 });

  const handleClose = () => {
    tictacMarketingStore.closeUserClonesModal();
    setClones({ items: [], total: 0 });
    setClonesPage(0);
  };

  const getClones = async (id: number, page: number) => {
    try {
      setIsLoading(true);
      const { data } = await TicTacMarketingService.myInstalledClonesInTicTac(
        id,
        CLONES_PER_PAGE * page,
        CLONES_PER_PAGE
      );
      setClones({
        items: data.items,
        total: data.total,
      });
    } catch (error) {
      console.log('[UserClonesModal] getClones error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tictacMarketingStore.userClonesModalConfig) {
      getClones(tictacMarketingStore.userClonesModalConfig?.deskId!, clonesPage);
    }
  }, [tictacMarketingStore.userClonesModalConfig, clonesPage]);

  return (
    <Modal
      className={styles.UserTictacClonesModal}
      variant="unwrapped"
      isOpen={!!tictacMarketingStore.userClonesModalConfig}
      onClose={handleClose}
    >
      <Typography className={styles.inputLabel} variant="heading6" element="h2" color="white">
        {t('modals.userTictacClones.title')}
      </Typography>

      <Card className={styles.card}>
        {isLoading && !clones.total && <Spinner className={styles.spinner} />}
        {!isLoading && !clones.total && (
          <Typography className={styles.noClones} variant="paragraph2" element="span" color="darkGray">
            {t('modals.userTictacClones.noData')}
          </Typography>
        )}
        {!!clones.total && (
          <div className={styles.list}>
            {clones.items.map((clone) => (
              <Button.RouterLink
                className={styles.item}
                key={clone.placeId}
                variant="wrapper"
                onClick={handleClose}
                to={generatePath(routes.tictacMarketingDeskPlace, {
                  deskId: tictacMarketingStore.desk?.current.id || '',
                  placeId: clone.placeId,
                })}
              >
                <div className={styles.info}>
                  <Typography variant="paragraph1" element="span" color="darkGray">
                    #{clone.placeId}
                  </Typography>
                  <Typography variant="caption1" element="span" color="blackTransparent">
                    {t('modals.userTictacClones.closed')}: {clone.closedPlaces}
                  </Typography>
                </div>

                <Typography className={styles.createdAt} variant="caption1" element="span" color="blackTransparent">
                  {moment(clone.createdAt).format('DD.MM.YYYY')}
                  <br />
                  {moment(clone.createdAt).format('HH:mm:ss')}
                </Typography>
              </Button.RouterLink>
            ))}
          </div>
        )}
        {!!clones.total && isLoading && <Spinner className={styles.spinner} variant="bluredOverlay" />}

        <Pagination
          className={styles.pagination}
          page={clonesPage}
          total={clones.total}
          perPage={CLONES_PER_PAGE}
          onChange={(newPage) => setClonesPage(newPage)}
        />
      </Card>

      <Button className={styles.button} variant="outlined" color="lightGray" onClick={handleClose}>
        {t('shared.controls.close')}
      </Button>
    </Modal>
  );
};

export default observer(UserTictacClonesModal);
