import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import RootStore from 'store';
import {
  PurchaseDeskModalConfigType,
  TopboxReferralInfoModalConfig,
  PurchaseClonesModalConfigType,
  InstallPlaceModalConfigType,
  UserClonesModalConfigType,
  NodeInfoModalConfig,
} from 'types/modalConfigs';
import {
  TopboxReferralStats,
  TopboxMarketingStatsFilterType,
  GroupedTopmboxMarketingDesksType,
} from 'types/topboxMarketing';
import { ApiError, TopBoxMarketingService, DeskItem2, DeskItemPlaces3, TopBoxStatistics } from 'api/client';

export default class TopboxMarketingStore {
  rootStore: RootStore;

  purchaseDeskModalConfig: PurchaseDeskModalConfigType | null;

  referralInfoModalConfig: TopboxReferralInfoModalConfig | null;

  purchaseClonesModalConfig: PurchaseClonesModalConfigType | null;

  installPlaceModalConfig: InstallPlaceModalConfigType | null;

  userClonesModalConfig: UserClonesModalConfigType | null;

  nodeInfoModalConfig: NodeInfoModalConfig | null;

  isStatsFilterModalOpen: boolean;

  desks: DeskItem2[] | null;

  referralsStats: TopboxReferralStats | null;

  desk: DeskItemPlaces3 | null;

  deskClonesTotal: number;

  stats: {
    items: TopBoxStatistics[];
    total: number;
    perPage: number;
    page: number;
    filter: TopboxMarketingStatsFilterType;
  };

  loading: {
    getDesk: boolean;
    getDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getDesk: ApiError | null;
    getDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'topMarketingStore' });
    this.rootStore = rootStore;

    this.purchaseDeskModalConfig = null;
    this.referralInfoModalConfig = null;
    this.purchaseClonesModalConfig = null;
    this.installPlaceModalConfig = null;
    this.userClonesModalConfig = null;
    this.nodeInfoModalConfig = null;
    this.isStatsFilterModalOpen = false;

    this.desks = null;
    this.desk = null;
    this.deskClonesTotal = 0;

    this.referralsStats = null;

    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        deskIds: [],
        lines: [],
        clones: false,
        placesLeft: [],
        username: '',
      },
    };

    this.loading = {
      getDesk: false,
      getDesks: false,
      getStats: false,
    };

    this.errors = {
      getDesk: null,
      getDesks: null,
    };
  }

  get isDeskNotFound() {
    return this.errors.getDesk?.status === 404;
  }

  get desksGroupedByBlocks() {
    return this.desks ? (groupBy(this.desks, (desk) => desk.blockName) as GroupedTopmboxMarketingDesksType) : null;
  }

  *getDesks() {
    try {
      this.loading.getDesks = true;
      const {
        data: { items },
      }: { data: { items: DeskItem2[] } } = yield TopBoxMarketingService.topBoxMarketingGetDeskList();
      this.desks = items.map((desk) => ({ ...desk, blockName: desk.blockName?.toLowerCase() }));
      const { data: referralsStats } = yield TopBoxMarketingService.topBoxMarketingGetReferralStats();
      this.referralsStats = Object.fromEntries(
        Object.entries(referralsStats).map(([key, value]) => [key.toLowerCase(), value])
      ) as TopboxReferralStats;
    } catch (error) {
      this.errors.getDesks = error as ApiError;
      console.log('[TopboxMarketingStore] getDesks error:', error);
    } finally {
      this.loading.getDesks = false;
    }
  }

  *getDesk(id: string) {
    try {
      this.loading.getDesk = true;
      const { data: deskData } = yield TopBoxMarketingService.topBoxMarketingShowDesk(id);
      const { data: deskClonesData } = yield TopBoxMarketingService.topBoxMarketingMyInstalledClones(parseInt(id));
      this.desk = {
        ...deskData,
        current: { ...deskData.current, blockName: deskData.current.blockName.toLowerCase() },
      };
      this.deskClonesTotal = deskClonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[TopboxMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  *getDeskByPlaceId(placeId: string) {
    this.loading.getDesk = true;
    try {
      const { data: deskData } = yield TopBoxMarketingService.topBoxMarketingShowDeskFromPlace(placeId);
      const { data: deskClonesData } = yield TopBoxMarketingService.topBoxMarketingMyInstalledClones(
        parseInt(deskData.current.id)
      );
      this.desk = {
        ...deskData,
        current: { ...deskData.current, blockName: deskData.current.blockName.toLowerCase() },
      };
      this.deskClonesTotal = deskClonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[TopboxMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  resetDesk() {
    this.errors.getDesk = null;
    this.desk = null;
    this.deskClonesTotal = 0;
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield TopBoxMarketingService.topBoxMarketingGetDeskStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.deskIds,
        this.stats.filter.lines as (4 | 1 | 2 | 3 | 5)[],
        this.stats.filter.clones,
        this.stats.filter.placesLeft as (1 | 2 | 3)[],
        this.stats.filter.username
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[TopboxMarketingStore] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: TopboxMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openStatsFilterModal() {
    this.isStatsFilterModalOpen = true;
  }

  closeStatsFilterModal() {
    this.isStatsFilterModalOpen = false;
  }

  openPurchaseDeskModal(config: PurchaseDeskModalConfigType) {
    this.purchaseDeskModalConfig = config;
  }

  closePurchaseDeskModal() {
    this.purchaseDeskModalConfig = null;
  }

  openReferralInfoModal(config: TopboxReferralInfoModalConfig) {
    this.referralInfoModalConfig = config;
  }

  closeReferralInfoModal() {
    this.referralInfoModalConfig = null;
  }

  openPurchaseClonesModal(config: PurchaseClonesModalConfigType) {
    this.purchaseClonesModalConfig = config;
  }

  closePurchaseClonesModal() {
    this.purchaseClonesModalConfig = null;
  }

  openInstallPlaceModal(config: InstallPlaceModalConfigType) {
    this.installPlaceModalConfig = config;
  }

  closeInstallPlaceModal() {
    this.installPlaceModalConfig = null;
  }

  openUserClonesModal(config: UserClonesModalConfigType) {
    this.userClonesModalConfig = config;
  }

  closeUserClonesModal() {
    this.userClonesModalConfig = null;
  }

  openNodeInfoModal(config: NodeInfoModalConfig) {
    this.nodeInfoModalConfig = config;
  }

  closeNodeInfoModal() {
    this.nodeInfoModalConfig = null;
  }
}
