import React, { useRef } from 'react';

import { sample } from 'lodash';
import cx from 'classnames';
import ReactAvatar from 'react-avatar';

import { SVGIconType } from 'types/common';
import { AvatarResponse } from 'api/client';

import { ReactComponent as SantaHat1 } from 'static/icons/hats/santa-hat-1.svg';
import { ReactComponent as SantaHat2 } from 'static/icons/hats/santa-hat-2.svg';
import { ReactComponent as SantaHat3 } from 'static/icons/hats/santa-hat-3.svg';
import { ReactComponent as SantaHat4 } from 'static/icons/hats/santa-hat-4.svg';
import { ReactComponent as SantaHat5 } from 'static/icons/hats/santa-hat-5.svg';
import { ReactComponent as SantaHat6 } from 'static/icons/hats/santa-hat-6.svg';
import { ReactComponent as SantaHat7 } from 'static/icons/hats/santa-hat-7.svg';

import styles from './Avatar.module.scss';

const hats = [SantaHat1, SantaHat2, SantaHat3, SantaHat4, SantaHat5, SantaHat6, SantaHat7];

interface AvatarProps {
  className?: string;
  avatar?: AvatarResponse | string | null;
  name?: string;
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm';
  shape?: 'circle' | 'square';
  withHat?: boolean;
}

const avatarSizes = {
  xxl: '256px',
  xl: '128px',
  lg: '80px',
  md: '64px',
  sm: '32px',
};

const Avatar: React.FC<AvatarProps> = ({ className, avatar, name, size = 'md', shape = 'circle', withHat = false }) => {
  const Hat = useRef(sample(hats) as SVGIconType);
  return (
    <div className={cx(styles.Avatar, className, styles[size], styles[shape])}>
      <ReactAvatar
        className={styles.avatar}
        name={name}
        size={avatarSizes[size]}
        src={avatar !== null && typeof avatar === 'object' ? (avatar as AvatarResponse)[size!]! : avatar || undefined}
      />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      {withHat && <Hat.current className={styles.hat} />}
    </div>
  );
};

export default Avatar;
