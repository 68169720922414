import React, { useEffect } from 'react';

import * as yup from 'yup';
import { useStore } from 'store';
import { observer } from 'mobx-react';

import { Modal } from 'components/feedback';

import styles from './AdminTransactionUpdateModal.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, TextField } from 'components/inputs';
import { SelectOption } from 'types/common';

interface UpdateTransactionValues {
  newAmount: number | string;
  newStatus: string;
  newWithdrawalAccount: string;
}

const updateTransactionSchema: yup.SchemaOf<UpdateTransactionValues> = yup.object().shape({
  newAmount: yup
    .number()
    .nullable()
    .required('Укажите сумму')
    .positive('Сумма должна быть положительной')
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  newStatus: yup.string().required(),
  newWithdrawalAccount: yup.string().required('Укажите кошелек'),
});

const AdminTransactionUpdateModal: React.FC = () => {
  const { adminStore, accountingStore } = useStore();

  const updateTransactionForm = useForm<UpdateTransactionValues>({
    resolver: yupResolver(updateTransactionSchema),
    defaultValues: {
      newAmount: adminStore.transactions.item?.transactionAmount || '',
      newStatus: adminStore.transactions.item?.status || '',
      newWithdrawalAccount: adminStore.transactions.item?.withdrawRequest?.accountDetails || '',
    },
    mode: 'onTouched',
  });

  const handleSubmit = updateTransactionForm.handleSubmit((values: UpdateTransactionValues) => {
    adminStore.updateTransaction(adminStore.transactions.item?.transactionId!, {
      ...values,
      newAmount: parseInt(values.newAmount as string),
    });
  });

  useEffect(() => {
    if (adminStore.transactions.item) {
      updateTransactionForm.reset({
        newAmount: adminStore.transactions.item?.transactionAmount || '',
        newStatus: adminStore.transactions.item?.status || '',
        newWithdrawalAccount: adminStore.transactions.item?.withdrawRequest?.accountDetails || '',
      });
    }
  }, [adminStore.transactions.item, updateTransactionForm]);

  return (
    <Modal
      className={styles.AdminTransactionUpdateModal}
      isOpen={adminStore.isAdminTransactionUpdateModalOpen}
      onClose={adminStore.closeAdminTransactionUpdateModal}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <Controller
          control={updateTransactionForm.control}
          name="newAmount"
          render={({ field, fieldState }) => (
            <TextField {...field} type="number" label="Сумма" error={fieldState.error?.message} />
          )}
        />

        <Controller
          control={updateTransactionForm.control}
          name="newStatus"
          render={({ field, fieldState }) => (
            <Select
              label="Статус"
              options={accountingStore.transactionsStatuses}
              value={accountingStore.transactionsStatuses.find((option) => field.value === option.value)}
              onChange={(newValue) => field.onChange((newValue as SelectOption).value)}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={updateTransactionForm.control}
          name="newWithdrawalAccount"
          render={({ field, fieldState }) => (
            <TextField {...field} type="number" label="Кошелек" error={fieldState.error?.message} />
          )}
        />

        <div className={styles.controls}>
          <Button className={styles.button} type="submit" isLoading={adminStore.loading.updateTransaction}>
            Сохранить
          </Button>

          <Button
            className={styles.button}
            disabled={adminStore.loading.updateTransaction}
            onClick={adminStore.closeAdminTransactionUpdateModal}
            variant="outlined"
          >
            Отменить
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(AdminTransactionUpdateModal);
