/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SiteNotificationResponse } from '../models/SiteNotificationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {
  /**
   * Gets event notifications.
   * @param type Notification Type
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns event notifications.
   * @throws ApiError
   */
  public static getEventNotifications(
    type: 'magicbox',
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<SiteNotificationResponse>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/notification/events/{type}',
      path: {
        type: type,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Marks event notifications as read.
   * @param eventId Event ID
   * @returns void
   * @throws ApiError
   */
  public static readEventNotifications(eventId: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/notification/event/{eventId}',
      path: {
        eventId: eventId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete the event notification.
   * @param eventId Event ID
   * @returns void
   * @throws ApiError
   */
  public static deleteEventNotification(eventId: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/notification/event/{eventId}',
      path: {
        eventId: eventId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
