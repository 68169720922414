/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JwtTokenResponse } from '../models/JwtTokenResponse';
import type { PasswordResetBeginRequest } from '../models/PasswordResetBeginRequest';
import type { PasswordResetFinishRequest } from '../models/PasswordResetFinishRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * Get an authorization token by login and password
   * After receiving the token, in order to make authenticated requests, you need to pass the HTTP header `Authentication: Bearer {token}`. A `refresh_token` cookie is added to the httponly cookies, with which you can refresh a short-lived JWT token by making a request to `GET /api/token/refresh`.
   * @param requestBody
   * @returns any Returns a token for making authenticated requests
   * @throws ApiError
   */
  public static signIn(requestBody: {
    /**
     * Username
     */
    username: string;
    /**
     * User password
     */
    password: string;
    /**
     * 2Fa code
     */
    code2fa?: string;
  }): CancelablePromise<{
    status: boolean;
    data: JwtTokenResponse;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/signin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Authorisation Error`,
      },
    });
  }

  /**
   * Gets a new JWT token (authorization by cookie refresh_token)
   * Gets a new JWT token and updates the `refresh_token` cookie with the new one. The old `refresh_token` becomes invalid.
   * @returns any Returns a token for making authenticated requests
   * @throws ApiError
   */
  public static tokenRefresh(): CancelablePromise<{
    status: boolean;
    data: JwtTokenResponse;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/token/refresh',
      errors: {
        401: `Authorisation Error`,
      },
    });
  }

  /**
   * Deletes the 'refresh_token' cookie. Execute at logout.
   * @returns any Return result
   * @throws ApiError
   */
  public static tokenInvalidate(): CancelablePromise<{
    code: number;
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/token/invalidate',
      errors: {
        400: `Validation Error`,
      },
    });
  }

  /**
   * Requests a password reset and sends an email to the user
   * After entering the email, the user receives an email with a link to the frontend page, where he can come up with a new password. The link URL will be: `https://frontend-url/reset-password/?id={token}`
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static passwordResetBegin(requestBody: PasswordResetBeginRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/reset-password/begin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Checking the password reset token for existence and expiration.
   * @param token
   * @returns void
   * @throws ApiError
   */
  public static validateResetPasswordToken(token: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/reset-password/{token}',
      path: {
        token: token,
      },
      errors: {
        404: `Password reset token does not exist`,
        409: `The password reset link has already been used.`,
        410: `The password reset link has expired.`,
      },
    });
  }

  /**
   * Resetting and creating a new password.
   * @param token
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static resetPassword(token: string, requestBody: PasswordResetFinishRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/reset-password/{token}',
      path: {
        token: token,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        404: `Password reset token does not exist`,
        409: `The password reset link has already been used.`,
        410: `The password reset link has expired.`,
      },
    });
  }
}
