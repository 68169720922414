import React, { useMemo } from 'react';

import ReactSelect, {
  ActionMeta,
  components,
  ControlProps,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  GroupBase,
  InputActionMeta,
  MultiValue,
  OptionProps,
  SingleValue,
} from 'react-select';
import cx from 'classnames';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { SVGIconType, SelectOption } from 'types/common';

import Spinner from 'components/feedback/Spinner';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as ChevronIcon } from 'static/icons/chevrons/chevron-down.svg';

import styles from './Select.module.scss';
import { useTranslation } from 'react-i18next';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  export interface Props<Option, IsMulti, Group extends GroupBase<Option>> {
    iconStart?: SVGIconType;
    iconEnd?: SVGIconType;
    iconClassName?: string;
    optionIconClassName?: string;
  }
}

interface SelectProps {
  options: SelectOption[];
  name?: string;
  value?: SelectOption | SelectOption[] | null;
  isMulti?: boolean;
  placeholder?: string | null;
  label?: string | null;
  onChange?: (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption> | ActionMeta<SelectOption[]>
  ) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  error?: string;
  isSearchable?: boolean;
  hidePlaceholder?: boolean;
  filterOption?: (option: FilterOptionOption<SelectOption>, rawInput: string) => boolean;
  iconStart?: SVGIconType;
  iconEnd?: SVGIconType;
  className?: string;
  wrapperClassName?: string;
  iconClassName?: string;
  optionIconClassName?: string;
  disabled?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: string | null;
  isLoading?: boolean;
}

const Control: React.FC<ControlProps<SelectOption>> = ({ children, selectProps, ...props }) => {
  const { iconStart, iconEnd, iconClassName, value } = selectProps;

  const [IconStart, IconEnd] = props.isMulti
    ? [iconStart, iconEnd]
    : [(value as SelectOption)?.icon || iconStart, iconEnd];

  return (
    <components.Control {...props} selectProps={selectProps}>
      {IconStart && <IconStart className={cx(styles.iconStart, iconClassName)} />}
      {children}
      {IconEnd && <IconEnd className={cx(styles.iconEnd, iconClassName)} />}
    </components.Control>
  );
};

const DropdownIndicator: React.FC<DropdownIndicatorProps<SelectOption>> = (props) => {
  if ((props.selectProps.isClearable && props.hasValue) || props.selectProps.iconEnd) {
    return null;
  }

  return (
    <components.DropdownIndicator {...props}>
      <ChevronIcon className={styles.chevron} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: React.FC<ClearIndicatorProps<SelectOption>> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon className={styles.clear} />
    </components.ClearIndicator>
  );
};

const Option: React.FC<OptionProps<SelectOption>> = ({ children, ...props }) => {
  const Icon = useMemo(() => props.data.icon, [props.data.icon]);

  return (
    <components.Option {...props}>
      {Icon && <Icon className={cx(styles.optionIcon, props.selectProps.optionIconClassName)} />}
      {children}
    </components.Option>
  );
};

const Select: React.FC<SelectProps> = ({
  options,
  iconStart,
  iconEnd,
  name,
  value,
  isMulti,
  placeholder,
  label,
  error,
  onChange,
  onInputChange,
  isSearchable = false,
  filterOption,
  className,
  wrapperClassName,
  iconClassName,
  optionIconClassName,
  disabled,
  isClearable,
  isLoading,
  noOptionsMessage,
}) => {
  const { t } = useTranslation();

  placeholder ??= t('shared.labels.select') as string;
  noOptionsMessage ??= t('shared.labels.notFound') as string;

  return (
    <div className={cx(styles.Select, { [styles.hasError]: !!error, [styles.disabled]: disabled }, wrapperClassName)}>
      {(label || error) && (
        <Typography className={styles.label} variant="paragraph2" element="span" color="darkGray">
          {error || label}
        </Typography>
      )}

      <ReactSelect
        className={cx(styles.Select, className)}
        classNamePrefix="Select"
        options={options}
        iconStart={iconStart}
        iconEnd={iconEnd}
        name={name}
        value={value}
        onInputChange={onInputChange}
        placeholder={placeholder}
        onChange={onChange}
        isSearchable={isSearchable}
        menuPlacement="auto"
        filterOption={filterOption}
        components={{ Control, DropdownIndicator, Option, ClearIndicator }}
        iconClassName={iconClassName}
        optionIconClassName={optionIconClassName}
        isDisabled={disabled}
        noOptionsMessage={() => noOptionsMessage}
        isMulti={isMulti}
        isClearable={isClearable}
        isLoading={isLoading}
        loadingMessage={() => <Spinner className={styles.spinner} />}
      />
    </div>
  );
};

export default Select;
