import React from 'react';

import { useStore } from 'store';
import { observer } from 'mobx-react';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './AdminDeleteNotificationModal.module.scss';

const AdminDeleteNotificationModal: React.FC = () => {
  const { adminStore } = useStore();

  const handleDelete = () => {
    switch (adminStore.adminDeleteNotificationModalConfig?.type) {
      case 'system':
        adminStore.deleteSystemNotification(adminStore.adminDeleteNotificationModalConfig.id.toString());
        break;
      case 'user':
        adminStore.deleteUserNotification(adminStore.adminDeleteNotificationModalConfig.id.toString());
        break;
      default:
    }
  };

  return (
    <Modal
      className={styles.AdminDeleteNotificationModal}
      isOpen={!!adminStore.adminDeleteNotificationModalConfig}
      onClose={adminStore.closeAdminDeleteNotificationModal}
    >
      <Typography className={styles.message} element="p" variant="paragraph2" color="black">
        Вы уверены, что хотите удалить сообщение?
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          color="red"
          isLoading={adminStore.loading.deleteNotification}
          onClick={handleDelete}
        >
          Удалить
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          disabled={adminStore.loading.deleteNotification}
          onClick={adminStore.closeAdminDeleteNotificationModal}
        >
          Отменить
        </Button>
      </div>
    </Modal>
  );
};

export default observer(AdminDeleteNotificationModal);
