import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button } from 'components/inputs';

import styles from './KlondikeLockedDeskModal.module.scss';

const KlondikeLockedDeskModal: React.FC = () => {
  const {
    klondikeMarketingStore: { lockedDeskModalConfig, closeLockedDeskModal },
  } = useStore();
  const { t } = useTranslation();

  const handleClose = () => {
    closeLockedDeskModal();
  };

  const level = useMemo(() => {
    const levels = ['Red Gold', 'Yellow Gold', 'White Gold'];
    return lockedDeskModalConfig?.desk.name !== '1'
      ? lockedDeskModalConfig?.desk.blockName
      : levels[levels.findIndex((levelName) => levelName === lockedDeskModalConfig?.desk.blockName) - 1];
  }, [lockedDeskModalConfig]);

  return (
    <Modal className={styles.KlondikeLockedDeskModal} isOpen={!!lockedDeskModalConfig} onClose={handleClose}>
      <Typography className={styles.title} variant="heading6" element="h2">
        {t('modals.klondikeLockedDesk.title')}
      </Typography>

      <Typography className={styles.text} element="p">
        {t('modals.klondikeLockedDesk.text', { level })}
      </Typography>

      <Button className={styles.button} onClick={handleClose}>
        {t('modals.klondikeLockedDesk.button')}
      </Button>
    </Modal>
  );
};

export default observer(KlondikeLockedDeskModal);
