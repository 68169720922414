import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import RootStore from 'store';
import {
  KlondikeMarketingStatsFilterType,
  GroupedKlondikeMarketingDesksType,
  KlondikeLockedDeskModalConfigType,
  PurchaseKlondikePlacesModalConfigType,
} from 'types/klondikeMarketing';
import { InstallPlaceModalConfigType, UserClonesModalConfigType } from 'types/modalConfigs';
import { ApiError, DeskItemPlaces5, DeskListItem3, KlondaikMarketingService, MarketingStatistics } from 'api/client';

export default class KlondikeMarketingStore {
  rootStore: RootStore;

  purchasePlacesModalConfig: PurchaseKlondikePlacesModalConfigType | null;

  lockedDeskModalConfig: KlondikeLockedDeskModalConfigType | null;

  installPlaceModalConfig: InstallPlaceModalConfigType | null;

  userClonesModalConfig: UserClonesModalConfigType | null;

  desks: DeskListItem3[] | null;

  desk: DeskItemPlaces5 | null;

  depth: number;

  deskClonesTotal: number;

  isStatsFilterModalOpen: boolean;

  stats: {
    items: MarketingStatistics[];
    total: number;
    perPage: number;
    page: number;
    filter: KlondikeMarketingStatsFilterType;
  };

  loading: {
    getDesk: boolean;
    getDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getDesk: ApiError | null;
    getDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'klondikeMarketingStore' });
    this.rootStore = rootStore;
    this.purchasePlacesModalConfig = null;
    this.installPlaceModalConfig = null;
    this.userClonesModalConfig = null;
    this.isStatsFilterModalOpen = false;
    this.lockedDeskModalConfig = null;

    this.desks = null;
    this.desk = null;
    this.depth = 1;
    this.deskClonesTotal = 0;
    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        deskIds: [],
        lines: [],
        clones: false,
        placesLeft: [],
        username: '',
      },
    };

    this.loading = {
      getDesk: false,
      getDesks: false,
      getStats: false,
    };
    this.errors = {
      getDesk: null,
      getDesks: null,
    };
  }

  get desksGroupedByBlocks() {
    return this.desks
      ? (groupBy(this.desks, (desk) => desk.blockName.toLowerCase().split(' ')[0]) as GroupedKlondikeMarketingDesksType)
      : null;
  }

  get isDeskNotFound() {
    return this.errors.getDesk?.status === 404;
  }

  get isDeskPacked() {
    return [0, 1, 2].every((index) => !!this.desk?.items[index]);
  }

  get deskLength() {
    if (!this.desk?.current) return 0;
    return this.desk.items;
  }

  *getDesks() {
    try {
      this.loading.getDesks = true;
      const { data } = yield KlondaikMarketingService.getKlondaikDeskList();
      this.desks = data.items;
    } catch (error) {
      this.errors.getDesks = error as ApiError;
      console.log('[KlondikeMarketingStore] getDesks error:', error);
    } finally {
      this.loading.getDesks = false;
    }
  }

  *getDesk(id: number) {
    try {
      this.loading.getDesk = true;
      const { data: deskData } = yield KlondaikMarketingService.showKlondaikDesk(id);
      const { data: clonesData } = yield KlondaikMarketingService.myInstalledClonesInKlondaik(id);
      if (this.depth > 1) {
        // index of the last element in array tree
        const lastIndex = 2 ** this.depth * 2 - 2;
        let currentIndex = 1;
        while (Object.values(deskData.items).length < lastIndex + 2) {
          const rootPlaceId = deskData.items[currentIndex]?.id;
          const { data: rootDesk } = rootPlaceId
            ? yield KlondaikMarketingService.showKlondaikDeskFromPlace(rootPlaceId)
            : { data: { items: { 1: null, 2: null } } };
          deskData.items[currentIndex * 2 + 1] = rootDesk.items[1];
          deskData.items[currentIndex * 2 + 2] = rootDesk.items[2];
          currentIndex += 1;
        }
      }
      this.desk = deskData;
      this.deskClonesTotal = clonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[KlondikeMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  *getDeskByPlaceId(placeId: number) {
    this.loading.getDesk = true;
    try {
      const { data: deskData } = yield KlondaikMarketingService.showKlondaikDeskFromPlace(placeId);
      const { data: clonesData } = yield KlondaikMarketingService.myInstalledClonesInKlondaik(deskData.current.id);
      if (this.depth > 1) {
        // index of the last element in array tree
        const lastIndex = 2 ** this.depth * 2 - 2;
        let currentIndex = 1;
        while (Object.values(deskData.items).length < lastIndex + 2) {
          const rootPlaceId = deskData.items[currentIndex]?.id;
          const { data: rootDesk } = rootPlaceId
            ? yield KlondaikMarketingService.showKlondaikDeskFromPlace(rootPlaceId)
            : { data: { items: { 1: null, 2: null } } };
          deskData.items[currentIndex * 2 + 1] = rootDesk.items[1];
          deskData.items[currentIndex * 2 + 2] = rootDesk.items[2];
          currentIndex += 1;
        }
      }
      this.desk = deskData;
      this.deskClonesTotal = clonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[KlondikeMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  addTreeRow() {
    if (this.depth >= 5) return;
    this.depth = this.depth + 1;
  }

  removeTreeRow() {
    if (this.depth < 2) return;
    this.depth = this.depth - 1;
  }

  resetDesk() {
    this.errors.getDesk = null;
    this.desk = null;
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield KlondaikMarketingService.showKlondaikStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.deskIds,
        this.stats.filter.lines as (4 | 1 | 2 | 3 | 5)[],
        this.stats.filter.clones,
        this.stats.filter.placesLeft as (1 | 2)[],
        this.stats.filter.username
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[KlondikeMarketingStore] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: KlondikeMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openPurchasePlacesModal(config: PurchaseKlondikePlacesModalConfigType) {
    this.purchasePlacesModalConfig = config;
  }

  closePurchasePlacesModal() {
    this.purchasePlacesModalConfig = null;
  }

  openInstallPlaceModal(config: InstallPlaceModalConfigType) {
    this.installPlaceModalConfig = config;
  }

  closeInstallPlaceModal() {
    this.installPlaceModalConfig = null;
  }

  openUserClonesModal(config: UserClonesModalConfigType) {
    this.userClonesModalConfig = config;
  }

  closeUserClonesModal() {
    this.userClonesModalConfig = null;
  }

  openStatsFilterModal() {
    this.isStatsFilterModalOpen = true;
  }

  closeStatsFilterModal() {
    this.isStatsFilterModalOpen = false;
  }

  openLockedDeskModal(config: KlondikeLockedDeskModalConfigType) {
    this.lockedDeskModalConfig = config;
  }

  closeLockedDeskModal() {
    this.lockedDeskModalConfig = null;
  }
}
