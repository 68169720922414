import React, { useState } from 'react';

import numbro from 'numbro';
import { useStore } from 'store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Button from 'components/inputs/Button';
import Avatar from 'components/dataDisplay/Avatar';

import { ReactComponent as ChatIcon } from 'static/icons/chat.svg';
import { ReactComponent as ChatUnreadIcon } from 'static/icons/chat-unread.svg';

import styles from './Toolbar.module.scss';

const Toolbar: React.FC = () => {
  const { t } = useTranslation();
  const { userStore, accountingStore } = useStore();

  const [chatHasUnread] = useState(false);

  return (
    <div className={styles.Toolbar}>
      <span className={styles.balance}>
        <span className={styles.label}>{t('header.balance')}:</span>
        {numbro(accountingStore.totalBalance).format({ trimMantissa: false })}
      </span>

      <Button className={styles.chat} variant="wrapper">
        {chatHasUnread ? <ChatUnreadIcon className={styles.icon} /> : <ChatIcon className={styles.icon} />}
      </Button>

      <Avatar className={styles.avatar} avatar={userStore.user?.avatar} name={userStore.userFullName} size="sm" />
    </div>
  );
};

export default observer(Toolbar);
