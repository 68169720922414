import React from 'react';

import cx from 'classnames';

import { BreakpointType, TypographyColorType, TypographyElementType, TypographyStyleType } from 'types/common';

import styles from './Typography.module.scss';

interface TypographyProps {
  children?: React.ReactNode;
  className?: string;
  element?: TypographyElementType;
  variant?: TypographyStyleType;
  color?: TypographyColorType;
  dangerouslySetInnerHTML?: string;
  fontFamily?: 'montserrat' | 'inter';
  responsiveStyles?: {
    [breakpoint in BreakpointType]?: TypographyStyleType;
  };
}

const Typography: React.FC<TypographyProps> = ({
  children,
  className,
  element = 'p',
  variant = 'paragraph1',
  color = 'nightGreen',
  fontFamily = 'inter',
  dangerouslySetInnerHTML,
  responsiveStyles = {},
}) => {
  const Element = element;

  return (
    <Element
      className={cx(
        styles.Typography,
        styles[color],
        styles[variant],
        (Object.keys(responsiveStyles) as BreakpointType[]).map(
          (breakpoint) => styles[`${breakpoint}-${responsiveStyles[breakpoint]}`]
        ),
        className
      )}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: dangerouslySetInnerHTML } : undefined}
    >
      {children}
    </Element>
  );
};

export default Typography;
