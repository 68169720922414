import moment from 'moment';
import { AdminTransactionsFilterType } from 'types/adminTransactions';
import { AdminFilterDescriptionType, DateRange, SelectOption } from 'types/common';

export const directionOptions = [
  { value: 1, label: 'Входящая' },
  { value: -1, label: 'Исходящая' },
];

export const sortOptions = [
  { value: 'created', label: 'Дата' },
  { value: 'username', label: 'Логин' },
];

export const orderOptions = [
  { value: 'ASC', label: 'По возврастанию' },
  { value: 'DESC', label: 'По убыванию' },
];

const adminTransactionsFilters: AdminFilterDescriptionType<AdminTransactionsFilterType>[] = [
  {
    key: 'transactionUsername',
    label: 'Логин',
    input: { type: 'dynamicSelect', props: { dynamicOptions: 'users', isMulti: false, isSearchable: true } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'direction',
    label: 'Направление',
    input: { type: 'select', props: { options: directionOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'status',
    label: 'Статус',
    input: { type: 'dynamicSelect', props: { isMulti: true, dynamicOptions: 'status' } },
    valueFormatter: (options) => (options as SelectOption[]).map((option) => option.label).join(', '),
  },
  {
    key: 'type',
    label: 'Тип',
    input: { type: 'dynamicSelect', props: { isMulti: true, dynamicOptions: 'type' } },
    valueFormatter: (options) => (options as SelectOption[]).map((option) => option.label).join(', '),
  },
  {
    key: 'createdAt',
    label: 'Дата',
    input: { type: 'daterange' },
    valueFormatter: (range) =>
      `${moment((range as DateRange).startDate).format('DD.MM.YYYY')} - ${moment((range as DateRange).endDate).format(
        'DD.MM.YYYY'
      )}`,
  },
  {
    key: 'sort',
    label: 'Упорядочить по',
    input: { type: 'select', props: { options: sortOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'order',
    label: 'Порядок сортировки',
    input: { type: 'select', props: { options: orderOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
];

export default adminTransactionsFilters;
