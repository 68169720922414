import { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

export const useScrollLock = (flag: boolean) => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (flag && rootElement) {
      rootElement.style.overflow = 'hidden';
    } else if (rootElement) {
      rootElement.style.overflow = 'visible';
    }

    return () => {
      if (rootElement) rootElement.style.overflow = 'visible';
    };
  }, [flag]);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | null;
    height: number | null;
  }>({
    width: null,
    height: null,
  });

  const mobileLayout = useMemo(() => (!!windowSize.width ? windowSize.width < 992 : null), [windowSize.width]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowSize, mobileLayout };
};

export const useNavigateOnMobile = (mainRoute: string, navigateTo?: string) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mobileLayout } = useWindowSize();

  const hideNavigation = useMemo(
    () => mobileLayout !== null && mobileLayout && !matchPath(mainRoute, pathname),
    [mobileLayout, pathname, mainRoute]
  );

  useEffect(() => {
    if (navigateTo && mobileLayout !== null && !mobileLayout && !!matchPath(mainRoute, pathname)) {
      navigate(navigateTo, { replace: true });
    }
  }, [mobileLayout, navigate, pathname, navigateTo, mainRoute]);

  return hideNavigation;
};

export const useOnClickOutside = (
  ref: React.MutableRefObject<Node | null>,
  handler: (event?: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useOnScreen = (ref: React.MutableRefObject<Element | null>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref]);

  return isIntersecting;
};
