import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import { ReactComponent as TelegramIcon } from 'static/icons/socials/telegram.svg';

import styles from './TopboxNodeInfoModal.module.scss';

const TopboxNodeInfoModal: React.FC = () => {
  const { t } = useTranslation();
  const { topboxMarketingStore } = useStore();

  const nodeInfo = topboxMarketingStore.nodeInfoModalConfig?.nodeInfo;

  return (
    <Modal
      className={styles.TopboxNodeInfoModal}
      overlayClassName={styles.nodeInfoModalOverlay}
      isOpen={!!topboxMarketingStore.nodeInfoModalConfig}
    >
      <div className={styles.heading}>
        <Typography className={styles.username} element="span" variant="subtitle1" color="white">
          {nodeInfo?.user.username}
        </Typography>

        {nodeInfo?.line === 1 && (
          <Typography className={styles.line} element="span" variant="caption2" color="white">
            {t('topboxMarketingDeskPage.node.line', { line: 1 })}
          </Typography>
        )}
      </div>

      <div className={styles.info}>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="darkGray">
            Telegram:
          </Typography>

          <div className={styles.value}>
            <Typography element="span" variant="subtitle1" color="white">
              {nodeInfo?.user.telegram ? nodeInfo?.user.telegram : '—'}
            </Typography>

            {nodeInfo?.user.telegram && (
              <Button.Link
                className={styles.control}
                variant="wrapper"
                href={`https://t.me/${nodeInfo.user.telegram}`}
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon className={styles.icon} />
              </Button.Link>
            )}
          </div>
        </div>
      </div>

      <Button
        className={styles.button}
        variant="outlined"
        color="lightGray"
        onClick={topboxMarketingStore.closeNodeInfoModal}
      >
        {t('shared.controls.close')}
      </Button>
    </Modal>
  );
};

export default observer(TopboxNodeInfoModal);
