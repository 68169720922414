import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'App';
import Store, { StoreProvider } from 'store';
import reportWebVitals from 'reportWebVitals';

import 'api';
import 'config/i18n';
import 'config/moment';
import 'config/numbro';
import 'static/styles/index.scss';

const store = new Store();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
