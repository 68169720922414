import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { useStore } from 'store';

import { Modal } from 'components/feedback';

import styles from './TwoFactorSettingsModal.module.scss';
import { Typography } from 'components/dataDisplay';
import { useTranslation } from 'react-i18next';
import { ApiError, UsersService } from 'api/client';
import { Button } from 'components/inputs';

const TwoFactorSettingsModal: React.FC = () => {
  const { t } = useTranslation();

  const {
    layoutStore,
    userStore: { twoFactorSettingsModalConfig, closeTwoFactorSettingsModal, user, getUser },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const twoFactorFlag =
    twoFactorSettingsModalConfig?.type === 'auth' ? user?.enabledLogin2fa : user?.enabledPurchase2fa;

  const twoFactorMethod =
    twoFactorSettingsModalConfig?.type === 'auth' ? UsersService.enable2FaForLogin : UsersService.enable2FaForPurchase;

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await twoFactorMethod({ enabled: !twoFactorFlag });
      await getUser();
      closeTwoFactorSettingsModal();
    } catch (error) {
      const apiError = error as ApiError;
      layoutStore.openFeedbackModal(apiError?.body?.message || 'Неизвестная ошибка');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className={styles.TwoFactorSettingsModal}
      isOpen={!!twoFactorSettingsModalConfig}
      onClose={closeTwoFactorSettingsModal}
    >
      <Typography className={styles.title} variant="heading6" element="h2">
        {t(`modals.twoFactorSettingsModal.${twoFactorSettingsModalConfig?.type}.title`)}
      </Typography>

      <Typography className={styles.text} variant="paragraph2" element="p">
        {t(
          `modals.twoFactorSettingsModal.${twoFactorSettingsModalConfig?.type}.${twoFactorFlag ? 'disable' : 'enable'}`
        )}
      </Typography>

      <div className={styles.controls}>
        <Button variant="outlined" color="red" disabled={isLoading} onClick={closeTwoFactorSettingsModal}>
          {t('shared.controls.cancel')}
        </Button>
        <Button onClick={handleSubmit} isLoading={isLoading}>
          {twoFactorFlag ? t('shared.controls.confirm') : t('shared.controls.activate')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(TwoFactorSettingsModal);
