import React, { useMemo, useState } from 'react';

import * as yup from 'yup';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import numbro from 'numbro';
import { useStore } from 'store';
import routes from 'config/routes';
import { mapApiErrors } from 'utils/errorHandling';
import { ApiError, MagicBoxService } from 'api/client';

import { Modal } from 'components/feedback';
import { Avatar, Typography } from 'components/dataDisplay';
import { Button, NumberFieldWithControls, PasswordField } from 'components/inputs';

import coinsImg from 'static/images/join-magicbox-lot-modal-coins.png';

import styles from './JoinMagicBoxLotModal.module.scss';

interface JoinMagicBoxLotValues {
  quantity: number | string;
  password?: string;
}

const joinMagicBoxLotDefaultValues: JoinMagicBoxLotValues = {
  quantity: 1,
  password: '',
};

const JoinMagicBoxLotModal: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { magicBoxStore, layoutStore } = useStore();
  const modalConfig = magicBoxStore.joinLotModalConfig;

  const [isLoading, setIsLoading] = useState(false);
  const [isLastTicketPurchased, setIsLastTicketPurchased] = useState(false);

  const ticketsLeft = useMemo(
    () => (modalConfig?.lot.totalTickets || 0) - (modalConfig?.lot.ticketsBought || 0),
    [modalConfig]
  );

  const joinMagicBoxLotSchema: yup.SchemaOf<JoinMagicBoxLotValues> = useMemo(
    () =>
      yup.object().shape({
        quantity: yup
          .number()
          .nullable()
          .required(t('modals.joinMagicBoxLot.errors.quantityRequired') as string)
          .positive(t('modals.joinMagicBoxLot.errors.quantityPositive') as string)
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
        password: yup.string().when('$type', {
          is: (value: string) => value === 'private',
          then: (schema: yup.AnySchema) =>
            schema.required(t('modals.joinMagicBoxLot.errors.passwordRequired') as string),
        }),
      }),
    [t]
  );

  const joinMagicBoxLotForm = useForm({
    mode: 'onTouched',
    resolver: yupResolver(joinMagicBoxLotSchema, { context: { type: modalConfig?.lot.type } }),
    defaultValues: joinMagicBoxLotDefaultValues,
  });
  const watchQuantity = joinMagicBoxLotForm.watch('quantity');

  const handleClose = () => {
    magicBoxStore.closeJoinLotModal();
    joinMagicBoxLotForm.reset();
    setIsLastTicketPurchased(false);
  };

  const handleSubmit = joinMagicBoxLotForm.handleSubmit(async (values: JoinMagicBoxLotValues) => {
    try {
      setIsLoading(true);
      await MagicBoxService.buyRaffleTickets(modalConfig?.lot.id!, { ...values, quantity: values.quantity as number });
      magicBoxStore.getLot(modalConfig?.lot.id!);
      magicBoxStore.getLots();

      if (values.quantity === ticketsLeft) {
        setIsLastTicketPurchased(true);
      } else {
        handleClose();
      }
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, joinMagicBoxLotForm);
      } else {
        layoutStore.openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
      }
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Modal
      className={styles.JoinMagicBoxLotModal}
      headingClassName={styles.heading}
      contentClassName={styles.content}
      isOpen={!!modalConfig}
      heading={
        <>
          <img className={styles.image} src={coinsImg} alt="" />
          <Typography className={styles.label} element="span" variant="paragraph2" color="white">
            {t('shared.magicBoxLot.fields.prize')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="white">
            {modalConfig?.lot.prize}
          </Typography>
          <Typography className={styles.label} element="span" variant="paragraph2" color="white">
            {t('shared.magicBoxLot.fields.prizePerWinner')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="white">
            x{modalConfig?.lot.prizePerWinner}
          </Typography>
          <Typography className={styles.label} element="span" variant="paragraph2" color="white">
            {t('shared.magicBoxLot.fields.prizeAmount')}
          </Typography>
          <Typography className={styles.value} element="span" variant="subtitle1" color="white">
            {numbro(modalConfig?.lot.prizeAmount).format()}
          </Typography>
        </>
      }
    >
      {!isLastTicketPurchased && (
        <>
          <div className={styles.user}>
            <Avatar
              className={styles.avatar}
              avatar={modalConfig?.lot.creator.avatarUrl}
              name={`${modalConfig?.lot.creator.firstName} ${modalConfig?.lot.creator.lastName}`}
            />

            <div className={styles.text}>
              <Typography className={styles.fullName} element="span" variant="subtitle1" color="black">
                {`${modalConfig?.lot.creator.firstName} ${modalConfig?.lot.creator.lastName}`}
              </Typography>
              <Typography className={styles.username} element="span" variant="caption1" color="black">
                {modalConfig?.lot.creator.username}
              </Typography>
            </div>
          </div>

          <div className={styles.delimeter} />

          <Typography className={styles.description} element="p" variant="paragraph2" color="black">
            {t('modals.joinMagicBoxLot.text')}
          </Typography>

          <form className={styles.form} onSubmit={handleSubmit}>
            <Controller
              control={joinMagicBoxLotForm.control}
              name="quantity"
              render={({ field: { ref: _ref, ...fieldProps }, fieldState }) => (
                <div className={styles.fieldWrapper}>
                  <NumberFieldWithControls
                    className={styles.input}
                    {...fieldProps}
                    error={fieldState.error?.message}
                    label={t('modals.joinMagicBoxLot.labels.quantity')}
                    min={1}
                    max={ticketsLeft}
                    allowEmpty={false}
                  />
                  <Typography className={styles.hint} element="span" variant="caption1" color="blackTransparent">
                    {t('modals.joinMagicBoxLot.hint', { quantity: ticketsLeft })}
                  </Typography>
                </div>
              )}
            />

            {modalConfig?.lot.type === 'private' && (
              <Controller
                control={joinMagicBoxLotForm.control}
                name="password"
                render={({ field, fieldState }) => (
                  <PasswordField
                    className={styles.input}
                    {...field}
                    error={fieldState.error?.message}
                    label={t('modals.joinMagicBoxLot.labels.password')}
                  />
                )}
              />
            )}

            <div className={styles.fieldWrapper}>
              <Typography className={styles.label} element="span" variant="subtitle1" color="black">
                {t('modals.joinMagicBoxLot.price')}:
              </Typography>

              <Typography className={styles.value} element="span" variant="heading5" color="black">
                {(watchQuantity as number) * (modalConfig?.lot.ticketPrice || 0)}
              </Typography>
            </div>

            <div className={styles.controls}>
              <Button className={styles.button} size="large" type="submit" isLoading={isLoading}>
                {t('shared.magicBoxLot.join')}
              </Button>
              <Button
                className={cx(styles.button, styles.close)}
                size="medium"
                variant="outlined"
                color="black"
                onClick={handleClose}
                disabled={isLoading}
              >
                {t('shared.controls.close')}
              </Button>
            </div>
          </form>
        </>
      )}

      {isLastTicketPurchased && (
        <>
          <Typography className={styles.title} element="h2" variant="heading6" color="black">
            {t('modals.joinMagicBoxLot.success')}
          </Typography>

          {matchPath(routes.magicBoxLot, location.pathname) ? (
            <Button className={cx(styles.button, styles.close)} variant="outlined" onClick={handleClose}>
              {t('shared.magicBoxLot.checkoutResults')}
            </Button>
          ) : (
            <Button.RouterLink
              className={cx(styles.button, styles.close)}
              variant="outlined"
              onClick={handleClose}
              to={generatePath(routes.magicBoxLot, { lotId: modalConfig?.lot.id })}
            >
              {t('shared.magicBoxLot.checkoutResults')}
            </Button.RouterLink>
          )}
        </>
      )}
    </Modal>
  );
};

export default observer(JoinMagicBoxLotModal);
