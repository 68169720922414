import React, { useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import cx from 'classnames';
import numbro from 'numbro';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';
import { SelectOption } from 'types/common';
import { mapApiErrors } from 'utils/errorHandling';
import { ApiError, CreateRaffleRequest, MagicBoxService, RafflePrize } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, NumberFieldWithControls, PasswordField, Select } from 'components/inputs';

import styles from './CreateMagicBoxLotModal.module.scss';

interface CreateMagicBoxLotValues {
  type: string;
  marketing: string | null;
  prize: string | null;
  password?: string;
  passwordConfirmation?: string;
  totalTickets: number | string;
  prizesPerWinner: number | string;
  winnerCount: number | string;
}

const createMagicBoxLotDefaultValues: CreateMagicBoxLotValues = {
  type: 'public',
  marketing: null,
  prize: null,
  totalTickets: '',
  prizesPerWinner: '',
  winnerCount: '',
};

const CreateMagicBoxLotModal: React.FC = () => {
  const { t } = useTranslation();
  const { magicBoxStore, layoutStore } = useStore();
  const prizesOptions = magicBoxStore.prizesOptions;
  const marketingsOptions = magicBoxStore.marketingsOptions;

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<'create' | 'confirm' | 'success'>('create');

  const magicBoxLotTypeOptions: SelectOption[] = useMemo(
    () => ['public', 'private'].map((key) => ({ value: key, label: t(`shared.magicBoxLot.types.${key}`) })),
    [t]
  );

  const createMagicBoxLotSchema: yup.SchemaOf<CreateMagicBoxLotValues> = useMemo(
    () =>
      yup.object().shape({
        type: yup.string().required(),
        prize: yup
          .string()
          .nullable()
          .required(t('modals.createMagicBoxLot.errors.prizeRequired') as string),
        marketing: yup
          .string()
          .nullable()
          .required(t('modals.createMagicBoxLot.errors.marketingRequired') as string),
        password: yup.string().when('type', {
          is: (value: string) => value === 'private',
          then: (schema: yup.AnySchema) =>
            schema.required(t('modals.createMagicBoxLot.errors.passwordRequired') as string),
        }),
        passwordConfirmation: yup.string().when('type', {
          is: (value: string) => value === 'private',
          then: (schema: yup.AnySchema) =>
            schema
              .required(t('modals.createMagicBoxLot.errors.passwordConfirmationRequired') as string)
              .oneOf([yup.ref('password'), null], t('shared.errors.passwordsMissmatch') as string),
        }),
        totalTickets: yup
          .number()
          .nullable()
          .required(t('modals.createMagicBoxLot.errors.totalTicketsRequired') as string)
          .positive(t('modals.createMagicBoxLot.errors.totalTicketsPositive') as string)
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
        prizesPerWinner: yup
          .number()
          .nullable()
          .required(t('modals.createMagicBoxLot.errors.prizesPerWinnerRequired') as string)
          .positive(t('modals.createMagicBoxLot.errors.prizesPerWinnerPositive') as string)
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
        winnerCount: yup
          .number()
          .nullable()
          .required(t('modals.createMagicBoxLot.errors.winnerCountRequired') as string)
          .positive(t('modals.createMagicBoxLot.errors.winnerCountPositive') as string)
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
      }),
    [t]
  );

  const createMagicBoxLotForm = useForm<CreateMagicBoxLotValues>({
    mode: 'onTouched',
    resolver: yupResolver(createMagicBoxLotSchema),
    defaultValues: createMagicBoxLotDefaultValues,
  });

  const watchLotType = createMagicBoxLotForm.watch('type');
  const watchMarketing = createMagicBoxLotForm.watch('marketing');
  const watchPrize = createMagicBoxLotForm.watch('prize');
  const watchTotalTickets = createMagicBoxLotForm.watch('totalTickets');
  const watchWinnerCount = createMagicBoxLotForm.watch('winnerCount');
  const watchPrizesPerWinner = createMagicBoxLotForm.watch('prizesPerWinner');

  const calculatedPrize = useMemo(() => {
    if (!watchPrize) return 0;

    const prizePrice = magicBoxStore.prizes?.prizes.find((prize) => prize.title === watchPrize)?.price || 0;
    return prizePrice * ((watchWinnerCount as number) || 0) * ((watchPrizesPerWinner as number) || 0);
  }, [watchPrize, magicBoxStore.prizes, watchWinnerCount, watchPrizesPerWinner]);

  const handleClose = () => {
    magicBoxStore.closeCreateLotModal();
    createMagicBoxLotForm.reset();
    setStatus('create');
  };

  const handleSubmit = createMagicBoxLotForm.handleSubmit(async (values: CreateMagicBoxLotValues) => {
    if (status === 'create') {
      setStatus('confirm');
      return;
    }

    try {
      setIsLoading(true);
      await MagicBoxService.createRaffle({
        ...values,
        type: values.type as CreateRaffleRequest.type,
        prize: values.prize as RafflePrize,
        totalTickets: values.totalTickets as number,
        winnerCount: values.winnerCount as number,
        prizesPerWinner: values.prizesPerWinner as number,
      });
      magicBoxStore.getLots();
      setStatus('success');
    } catch (error) {
      setStatus('create');
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, createMagicBoxLotForm);
      } else {
        layoutStore.openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (magicBoxStore.isCreateLotModalOpen) {
      magicBoxStore.getPrizes();
    }
  }, [magicBoxStore, magicBoxStore.isCreateLotModalOpen]);

  return (
    <Modal
      className={styles.CreateMagicBoxLotModal}
      isOpen={magicBoxStore.isCreateLotModalOpen}
      onClose={handleClose}
      heading={t('modals.createMagicBoxLot.title')}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        {status === 'create' && (
          <>
            <Controller
              control={createMagicBoxLotForm.control}
              name="type"
              render={({ field, fieldState }) => (
                <Select
                  wrapperClassName={styles.input}
                  label={t('modals.createMagicBoxLot.labels.type')}
                  options={magicBoxLotTypeOptions}
                  error={fieldState.error?.message}
                  value={magicBoxLotTypeOptions.find((option) => option.value === field.value)}
                  onChange={(newValue) => field.onChange((newValue as SelectOption).value)}
                />
              )}
            />

            <Controller
              control={createMagicBoxLotForm.control}
              name="marketing"
              render={({ field, fieldState }) => (
                <Select
                  wrapperClassName={styles.input}
                  label={t('modals.createMagicBoxLot.labels.marketing')}
                  placeholder={t('modals.createMagicBoxLot.placeholders.marketing')}
                  isLoading={magicBoxStore.loading.getPrizes}
                  options={marketingsOptions}
                  error={fieldState.error?.message}
                  value={marketingsOptions.find((option) => option.value === field.value)}
                  onChange={(newValue) => field.onChange((newValue as SelectOption).value)}
                />
              )}
            />

            <Controller
              control={createMagicBoxLotForm.control}
              name="prize"
              render={({ field, fieldState }) => (
                <Select
                  wrapperClassName={styles.input}
                  label={t('modals.createMagicBoxLot.labels.prize')}
                  placeholder={t('modals.createMagicBoxLot.placeholders.prize')}
                  noOptionsMessage={t('modals.createMagicBoxLot.placeholders.prizeNoOptions')}
                  isLoading={magicBoxStore.loading.getPrizes}
                  options={prizesOptions[watchMarketing || ''] || []}
                  error={fieldState.error?.message}
                  value={(prizesOptions[watchMarketing || ''] || []).find((option) => option.value === field.value)}
                  onChange={(newValue) => field.onChange((newValue as SelectOption).value)}
                />
              )}
            />

            {watchLotType === 'private' && (
              <>
                <div className={styles.delimeter} />
                <Controller
                  control={createMagicBoxLotForm.control}
                  name="password"
                  render={({ field, fieldState }) => (
                    <PasswordField
                      className={styles.input}
                      {...field}
                      error={fieldState.error?.message}
                      label={t('modals.createMagicBoxLot.labels.password')}
                      placeholder={t('modals.createMagicBoxLot.placeholders.password')}
                    />
                  )}
                />
                <Controller
                  control={createMagicBoxLotForm.control}
                  name="passwordConfirmation"
                  render={({ field, fieldState }) => (
                    <PasswordField
                      className={styles.input}
                      {...field}
                      error={fieldState.error?.message}
                      label={t('modals.createMagicBoxLot.labels.passwordConfirmation')}
                      placeholder={t('modals.createMagicBoxLot.placeholders.password')}
                    />
                  )}
                />
                <div className={styles.delimeter} />
              </>
            )}

            <Controller
              control={createMagicBoxLotForm.control}
              name="totalTickets"
              render={({ field: { ref: _ref, ...fieldProps }, fieldState }) => (
                <NumberFieldWithControls
                  className={styles.input}
                  {...fieldProps}
                  error={fieldState.error?.message}
                  label={t('modals.createMagicBoxLot.labels.totalTickets')}
                  placeholder="0"
                  max={500}
                  min={2}
                />
              )}
            />
            <Controller
              control={createMagicBoxLotForm.control}
              name="winnerCount"
              render={({ field: { ref: _ref, ...fieldProps }, fieldState }) => (
                <NumberFieldWithControls
                  className={styles.input}
                  {...fieldProps}
                  error={fieldState.error?.message}
                  label={t('modals.createMagicBoxLot.labels.winnerCount')}
                  placeholder="0"
                  max={500}
                  min={1}
                />
              )}
            />
            <Controller
              control={createMagicBoxLotForm.control}
              name="prizesPerWinner"
              render={({ field: { ref: _ref, ...fieldProps }, fieldState }) => (
                <NumberFieldWithControls
                  className={styles.input}
                  {...fieldProps}
                  error={fieldState.error?.message}
                  label={t('modals.createMagicBoxLot.labels.prizesPerWinner')}
                  placeholder="0"
                />
              )}
            />

            <div className={styles.fieldWrapper}>
              <Typography className={styles.label} element="span" variant="subtitle2" color="blackTransparent">
                {t('modals.createMagicBoxLot.total')}:
              </Typography>

              <Typography className={styles.value} element="span" variant="heading6" color="black">
                {numbro(calculatedPrize).format({ trimMantissa: false })}
              </Typography>
            </div>

            <div className={styles.fieldWrapper}>
              <Typography className={styles.label} element="span" variant="subtitle2" color="blackTransparent">
                {t('modals.createMagicBoxLot.price')}:
              </Typography>

              <Typography className={styles.value} element="span" variant="heading6" color="black">
                {numbro(calculatedPrize / ((watchTotalTickets as number) || 1)).format({ trimMantissa: false })}
              </Typography>
            </div>
            <div className={styles.controls}>
              <Button className={styles.button} size="large" type="submit" isLoading={isLoading}>
                {t('shared.controls.create')}
              </Button>
              <Button
                className={cx(styles.button, styles.close)}
                size="medium"
                variant="outlined"
                color="black"
                onClick={handleClose}
                disabled={isLoading}
              >
                {t('shared.controls.close')}
              </Button>
            </div>
          </>
        )}

        {status === 'confirm' && (
          <>
            <Typography className={styles.text} element="span" variant="subtitle2" color="blackTransparent">
              {t('modals.createMagicBoxLot.confirm')}
            </Typography>

            <div className={styles.controls}>
              <Button className={styles.button} size="large" type="submit" isLoading={isLoading}>
                {t('shared.controls.confirm')}
              </Button>
              <Button
                className={cx(styles.button, styles.close)}
                size="medium"
                variant="outlined"
                color="black"
                onClick={() => setStatus('create')}
                disabled={isLoading}
              >
                {t('shared.controls.cancel')}
              </Button>
            </div>
          </>
        )}

        {status === 'success' && (
          <>
            <Typography className={styles.text} element="span" variant="subtitle2" color="blackTransparent">
              <Trans i18nKey="modals.createMagicBoxLot.success" components={{ br: <br /> }} />
            </Typography>

            <div className={styles.controls}>
              <Button
                className={cx(styles.button, styles.close)}
                size="medium"
                variant="outlined"
                color="black"
                onClick={handleClose}
                disabled={isLoading}
              >
                {t('shared.controls.close')}
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default observer(CreateMagicBoxLotModal);
