import React, { useImperativeHandle, useRef } from 'react';

import cx from 'classnames';

import Typography from 'components/dataDisplay/Typography';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  children?: React.ReactNode;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string;
  onClick?: React.MouseEventHandler;
}

const Checkbox = React.forwardRef<HTMLInputElement | null, CheckboxProps>(
  ({ children, name, checked, disabled, onChange, className, error, onClick }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    const checkboxProps = {
      name,
      checked,
      disabled,
      onChange,
      onClick,
    };

    return (
      <label className={cx(styles.Checkbox, { [styles.disabled]: disabled, [styles.hasError]: !!error }, className)}>
        <input ref={inputRef} type="checkbox" {...checkboxProps} />
        <button className={styles.button} onClick={onClick}>
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.83215 7.70473C4.6427 7.87312 4.35722 7.87312 4.16778 7.70472L0.837912 4.74478C0.391952 4.34836 0.391957 3.65155 0.837923 3.25514C1.2155 2.91952 1.78449 2.91952 2.16206 3.25516L4.49996 5.33337L9.83794 0.588505C10.2155 0.252882 10.7845 0.252884 11.1621 0.58851C11.608 0.984927 11.608 1.68174 11.1621 2.07816L4.83215 7.70473Z"
              fill="white"
            />
          </svg>
        </button>

        {children && (
          <Typography className={styles.label} variant="caption1" element="span">
            {children}
          </Typography>
        )}
      </label>
    );
  }
);

export default Checkbox;
