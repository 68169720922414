import React from 'react';
import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  NodeInfoModalConfig,
  UserClonesModalConfigType,
  PurchaseDeskModalConfigType,
  InstallPlaceModalConfigType,
  PurchaseClonesModalConfigType,
} from 'types/modalConfigs';
import RootStore from 'store';
import { MainMarketingStatsFilterType } from 'types/mainMarketingStats';
import mainMarketingBlocksColorSchemes from 'config/mainMarketingBlocksColorSchemes';
import { ApiError, DeskItem, DeskItemPlaces, MainMarketingService, MainStatistics } from 'api/client';
import { GroupedMainMarketingDesksType, MainMarketingBlocksType, MainMarketingColorSchemeType } from 'types/common';

export default class MainMarketingStore {
  rootStore: RootStore;

  purchaseDeskModalConfig: PurchaseDeskModalConfigType | null;

  purchaseClonesModalConfig: PurchaseClonesModalConfigType | null;

  installPlaceModalConfig: InstallPlaceModalConfigType | null;

  userClonesModalConfig: UserClonesModalConfigType | null;

  isStatsFilterModalOpen: boolean;

  isMainMarketingPromoModalOpen: boolean;

  nodeInfoModalConfig: NodeInfoModalConfig | null;

  desk: DeskItemPlaces | null;

  deskClonesTotal: number;

  desks: DeskItem[] | null;

  stats: {
    items: MainStatistics[];
    total: number;
    perPage: number;
    page: number;
    filter: MainMarketingStatsFilterType;
  };

  loading: {
    getDesk: boolean;
    getDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getDesk: ApiError | null;
    getDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'mainMarketing' });
    this.rootStore = rootStore;
    this.purchaseDeskModalConfig = null;
    this.purchaseClonesModalConfig = null;
    this.installPlaceModalConfig = null;
    this.userClonesModalConfig = null;
    this.isStatsFilterModalOpen = false;
    this.isMainMarketingPromoModalOpen = false;
    this.nodeInfoModalConfig = null;
    this.desk = null;
    this.deskClonesTotal = 0;
    this.desks = null;
    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        deskIds: [],
        lines: [],
        clones: false,
        placesLeft: [],
        username: '',
      },
    };
    this.loading = {
      getDesk: false,
      getDesks: false,
      getStats: false,
    };
    this.errors = {
      getDesk: null,
      getDesks: null,
    };
  }

  get deskFormattedTitle() {
    if (!this.desk?.current.blockName) return '';

    const parsedLabel = this.desk.current.blockName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

    return React.createElement(
      React.Fragment,
      null,
      React.createElement('span', null, parsedLabel[0]),
      `${parsedLabel.slice(1).join('')} ${this.desk.current.name}`
    );
  }

  get deskColorScheme(): MainMarketingColorSchemeType {
    if (!this.desk?.current.blockName) return 'green';

    return mainMarketingBlocksColorSchemes[this.desk.current.blockName as MainMarketingBlocksType];
  }

  get isDeskNotFound() {
    return this.errors.getDesk?.status === 404;
  }

  get desksGroupedByBlocks() {
    return this.desks ? (groupBy(this.desks, (desk) => desk.blockName) as GroupedMainMarketingDesksType) : null;
  }

  *getDesks() {
    try {
      this.loading.getDesks = true;
      const { data } = yield MainMarketingService.mainMarketingGetDeskList();
      this.desks = data.items;
    } catch (error) {
      this.errors.getDesks = error as ApiError;
      console.log('[MainMarketingStore] getDesks error:', error);
    } finally {
      this.loading.getDesks = false;
    }
  }

  *getDesk(id: string) {
    try {
      this.loading.getDesk = true;
      const { data: deskData } = yield MainMarketingService.mainMarketingShowDesk(id);
      const { data: deskClonesData } = yield MainMarketingService.mainMarketingMyInstalledClones(parseInt(id));
      this.desk = deskData;
      this.deskClonesTotal = deskClonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[MainMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  *getDeskByPlaceId(placeId: string) {
    this.loading.getDesk = true;
    try {
      const { data: deskData } = yield MainMarketingService.mainMarketingShowDeskFromPlace(placeId);
      const { data: deskClonesData } = yield MainMarketingService.mainMarketingMyInstalledClones(
        parseInt(deskData.current.id)
      );
      this.desk = deskData;
      this.deskClonesTotal = deskClonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[MainMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  resetDesk() {
    this.errors.getDesk = null;
    this.desk = null;
    this.deskClonesTotal = 0;
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield MainMarketingService.mainMarketingGetDeskStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.deskIds,
        this.stats.filter.lines as (4 | 1 | 2 | 3 | 5)[],
        this.stats.filter.clones,
        this.stats.filter.placesLeft as (4 | 1 | 2 | 3 | 5 | 6)[],
        this.stats.filter.username
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[MainMarketingDeskStatsPage] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: MainMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openPurchaseDeskModal(config: PurchaseDeskModalConfigType) {
    this.purchaseDeskModalConfig = config;
  }

  closePurchaseDeskModal() {
    this.purchaseDeskModalConfig = null;
  }

  openPurchaseClonesModal(config: PurchaseClonesModalConfigType) {
    this.purchaseClonesModalConfig = config;
  }

  closePurchaseClonesModal() {
    this.purchaseClonesModalConfig = null;
  }

  openInstallPlaceModal(config: InstallPlaceModalConfigType) {
    this.installPlaceModalConfig = config;
  }

  closeInstallPlaceModal() {
    this.installPlaceModalConfig = null;
  }

  openUserClonesModal(config: UserClonesModalConfigType) {
    this.userClonesModalConfig = config;
  }

  closeUserClonesModal() {
    this.userClonesModalConfig = null;
  }

  openStatsFilterModal() {
    this.isStatsFilterModalOpen = true;
  }

  closeStatsFilterModal() {
    this.isStatsFilterModalOpen = false;
  }

  openMainMarketingPromoModal() {
    this.isMainMarketingPromoModalOpen = true;
  }

  closeMainMarketingPromoModal() {
    this.isMainMarketingPromoModalOpen = false;
  }

  openNodeInfoModal(config: NodeInfoModalConfig) {
    this.nodeInfoModalConfig = config;
  }

  closeNodeInfoModal() {
    this.nodeInfoModalConfig = null;
  }
}
