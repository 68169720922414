import React, { useMemo, useState } from 'react';

import * as yup from 'yup';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { mapApiErrors } from 'utils/errorHandling';
import { ApiError, UsersService } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, PasswordField } from 'components/inputs';

import styles from './RemoveFromStructureModal.module.scss';

type UnpinFields = {
  financialPassword: string;
};

const RemoveFromStructureModal: React.FC = () => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const unpinSchema: yup.SchemaOf<UnpinFields> = useMemo(
    () =>
      yup.object().shape({
        financialPassword: yup
          .string()
          .required(t('modals.removeFromStructure.errors.financialPasswordRequired') as string),
      }),
    [t]
  );

  const unpinForm = useForm<UnpinFields>({
    resolver: yupResolver(unpinSchema),
    mode: 'onTouched',
    defaultValues: {
      financialPassword: '',
    },
  });

  const handleSubmit = unpinForm.handleSubmit(async (values) => {
    try {
      setIsLoading(true);
      await UsersService.unpinUserFromTeam({
        ...values,
        unpinUsername: userStore.removeFromStructureModalConfig?.username!,
      });
      userStore.removeFromStructureModalConfig?.onSubmit();
      setSuccess(true);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, unpinForm);
      } else {
        unpinForm.setError('financialPassword', {
          message: apiError?.body?.message || t('shared.errors.unknownError'),
        });
      }
    } finally {
      setIsLoading(false);
    }
  });

  const handleClose = () => {
    userStore.closeRemoveFromStructureModal();
    setSuccess(false);
    unpinForm.reset({ financialPassword: '' });
  };

  return (
    <Modal
      className={styles.RemoveFromStructureModal}
      onClose={handleClose}
      isOpen={!!userStore.removeFromStructureModalConfig}
    >
      {!success && (
        <>
          <Typography className={styles.title} variant="subtitle1">
            {t('modals.removeFromStructure.title')}
          </Typography>

          <Typography className={styles.text} variant="paragraph2">
            <Trans i18nKey="modals.removeFromStructure.text" components={{ b: <b />, br: <br /> }} />
          </Typography>

          <form className={styles.form} onSubmit={handleSubmit}>
            <Controller
              name="financialPassword"
              control={unpinForm.control}
              render={({ field, fieldState }) => (
                <PasswordField
                  {...field}
                  error={fieldState.error?.message}
                  label={t('modals.removeFromStructure.labels.financialPassword')}
                  placeholder={t('modals.removeFromStructure.placeholders.financialPassword')}
                />
              )}
            />

            <div className={styles.controls}>
              <Button className={styles.button} color="red" type="submit" isLoading={isLoading}>
                {t('modals.removeFromStructure.submit')}
              </Button>
              <Button className={styles.button} variant="outlined" onClick={handleClose} disabled={isLoading}>
                {t('shared.controls.cancel')}
              </Button>
            </div>
          </form>
        </>
      )}

      {success && (
        <>
          <Typography className={styles.title} variant="subtitle1">
            {t('modals.removeFromStructure.success.title')}
          </Typography>

          <Typography className={styles.text} variant="paragraph2">
            {t('modals.removeFromStructure.success.text')}
          </Typography>

          <Button className={styles.button} onClick={handleClose} variant="outlined">
            OK
          </Button>
        </>
      )}
    </Modal>
  );
};

export default observer(RemoveFromStructureModal);
