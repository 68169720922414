import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

import routes from 'config/routes';
import { UserData } from 'api/client';

import Button from 'components/inputs/Button';
import Card from 'components/surfaces/Card';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as EditIcon } from 'static/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'static/icons/trash.svg';

import styles from './AdminNotificationCard.module.scss';

interface AdminNotificationCardProps {
  message: string;
  createdAt?: string;
  readAt?: string | null;
  isDeleted?: boolean;
  sender?: UserData;
  reciever?: UserData;
  onEdit?: () => void;
  onDelete?: () => void;
}

const AdminNotificationCard: React.FC<AdminNotificationCardProps> = ({
  message,
  createdAt,
  readAt,
  isDeleted,
  sender,
  reciever,
  onEdit,
  onDelete,
}) => {
  return (
    <Card className={styles.AdminNotificationCard}>
      <div className={styles.data}>
        {sender && (
          <Typography className={styles.info} variant="caption1" color="blackTransparent">
            Отправитель:{' '}
            {
              <Button.RouterLink
                className={styles.link}
                variant="text"
                to={generatePath(routes.adminUser, { userId: sender.id })}
              >
                {sender.username}
              </Button.RouterLink>
            }
          </Typography>
        )}
        {reciever && (
          <Typography className={styles.info} variant="caption1" color="blackTransparent">
            Получатель:{' '}
            {
              <Button.RouterLink
                className={styles.link}
                variant="text"
                to={generatePath(routes.adminUser, { userId: reciever.id })}
              >
                {reciever.username}
              </Button.RouterLink>
            }
          </Typography>
        )}
        {createdAt && (
          <Typography className={styles.info} variant="caption1" color="blackTransparent">
            Дата отправки: {moment(createdAt).format('DD.MM.YYYY HH:mm')}
          </Typography>
        )}
        {readAt !== undefined && (
          <Typography className={styles.info} variant="caption1" color="blackTransparent">
            Дата прочтения: {readAt ? moment(readAt).format('DD.MM.YYYY HH:mm') : 'Отсутствует'}
          </Typography>
        )}
        {isDeleted && (
          <Typography className={styles.info} variant="caption1" color="red">
            Удалено
          </Typography>
        )}

        <Typography className={styles.message} variant="paragraph2" color="black">
          {message}
        </Typography>
      </div>

      <div className={styles.controls}>
        {onEdit && (
          <Button className={cx(styles.button, styles.edit)} variant="wrapper" onClick={onEdit}>
            <EditIcon className={styles.icon} />
          </Button>
        )}
        {onDelete && (
          <Button className={cx(styles.button, styles.delete)} variant="wrapper" onClick={onDelete}>
            <TrashIcon className={styles.icon} />
          </Button>
        )}
      </div>
    </Card>
  );
};

export default AdminNotificationCard;
