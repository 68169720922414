import React, { forwardRef, useState } from 'react';

import Button from 'components/inputs/Button';
import TextField from 'components/inputs/TextField';

import { ReactComponent as EyeIcon } from 'static/icons/eye.svg';
import { ReactComponent as EyeCrossedIcon } from 'static/icons/eye-crossed.svg';

import styles from './PasswordField.module.scss';

interface PasswordFieldProps {
  name?: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler;
  onFocus?: React.FocusEventHandler;
  disabled?: boolean;
  label?: string | null;
  placeholder?: string | null;
  error?: string;
  className?: string;
}

const PasswordField = forwardRef<(HTMLInputElement & HTMLTextAreaElement) | null, PasswordFieldProps>(
  ({ name, value, onChange, onBlur, onFocus, disabled, label, placeholder, error, className }, ref) => {
    const [showPassowrd, setShowPassword] = useState(false);

    return (
      <div className={styles.PasswordField}>
        <TextField
          ref={ref}
          type={showPassowrd ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          error={error}
          className={className}
        />

        <Button className={styles.toggleButton} variant="wrapper" onClick={() => setShowPassword((prev) => !prev)}>
          {showPassowrd ? <EyeCrossedIcon className={styles.eyeIcon} /> : <EyeIcon className={styles.eyeIcon} />}
        </Button>
      </div>
    );
  }
);

export default PasswordField;
