import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  UserClonesModalConfigType,
  InstallPlaceModalConfigType,
  PurchaseBoomboxPlacesModalConfigType,
} from 'types/modalConfigs';
import RootStore from 'store';
import { GroupedBoomboxMarketingDesksType } from 'types/common';
import { ApiError, MainStatistics, DeskItemPlaces2, BoomBoxMarketingService, DeskListItem } from 'api/client';

import { BoomboxMarketingStatsFilterType } from 'types/boomboxMarketingStats';

export default class BoomboxMarketingStore {
  rootStore: RootStore;

  purchasePlacesModalConfig: PurchaseBoomboxPlacesModalConfigType | null;

  installPlaceModalConfig: InstallPlaceModalConfigType | null;

  userClonesModalConfig: UserClonesModalConfigType | null;

  desk: DeskItemPlaces2 | null;

  deskClonesTotal: number;

  desks: DeskListItem[] | null;

  isStatsFilterModalOpen: boolean;

  stats: {
    items: MainStatistics[];
    total: number;
    perPage: number;
    page: number;
    filter: BoomboxMarketingStatsFilterType;
  };

  loading: {
    getDesk: boolean;
    getDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getDesk: ApiError | null;
    getDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'boomboxMarketingStore' });
    this.rootStore = rootStore;
    this.purchasePlacesModalConfig = null;
    this.installPlaceModalConfig = null;
    this.userClonesModalConfig = null;

    this.desk = null;
    this.deskClonesTotal = 0;
    this.desks = null;
    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        deskIds: [],
        lines: [],
        clones: false,
        placesLeft: [],
        username: '',
      },
    };

    this.isStatsFilterModalOpen = false;

    this.loading = {
      getDesk: false,
      getDesks: false,
      getStats: false,
    };
    this.errors = {
      getDesk: null,
      getDesks: null,
    };
  }

  get desksGroupedByBlocks() {
    return this.desks ? (groupBy(this.desks, (desk) => desk.blockName) as GroupedBoomboxMarketingDesksType) : null;
  }

  get isDeskNotFound() {
    return this.errors.getDesk?.status === 404;
  }

  get isDeskPacked() {
    return (this.desk?.current.isTriadic ? [0, 1, 2, 3] : [0, 1, 3]).every((index) => !!this.desk?.items[index]);
  }

  *getDesks() {
    try {
      this.loading.getDesks = true;
      const { data } = yield BoomBoxMarketingService.getBoomBoxDeskList();
      this.desks = data.items;
    } catch (error) {
      this.errors.getDesks = error as ApiError;
      console.log('[BoomboxMarketingStore] getDesks error:', error);
    } finally {
      this.loading.getDesks = false;
    }
  }

  *getDesk(id: number) {
    try {
      this.loading.getDesk = true;
      const { data: deskData } = yield BoomBoxMarketingService.showBoomBoxDesk(id);
      const { data: clonesData } = yield BoomBoxMarketingService.myInstalledClonesInBoomBox(id);
      this.desk = deskData;
      this.deskClonesTotal = clonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[BoomboxMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  *getDeskByPlaceId(placeId: number) {
    this.loading.getDesk = true;
    try {
      const { data: deskData } = yield BoomBoxMarketingService.showBoomBoxDeskFromPlace(placeId);
      const { data: clonesData } = yield BoomBoxMarketingService.myInstalledClonesInBoomBox(deskData.current.id);
      this.desk = deskData;
      this.deskClonesTotal = clonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[BoomboxMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  resetDesk() {
    this.errors.getDesk = null;
    this.desk = null;
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield BoomBoxMarketingService.showBoomBoxStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.deskIds,
        this.stats.filter.lines as (4 | 1 | 2 | 3 | 5)[],
        this.stats.filter.clones,
        this.stats.filter.placesLeft as (1 | 2 | 3)[],
        this.stats.filter.username
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[BoomboxMarketingStore] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: BoomboxMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openStatsFilterModal() {
    this.isStatsFilterModalOpen = true;
  }

  closeStatsFilterModal() {
    this.isStatsFilterModalOpen = false;
  }

  openPurchasePlacesModal(config: PurchaseBoomboxPlacesModalConfigType) {
    this.purchasePlacesModalConfig = config;
  }

  closePurchasePlacesModal() {
    this.purchasePlacesModalConfig = null;
  }

  openInstallPlaceModal(config: InstallPlaceModalConfigType) {
    this.installPlaceModalConfig = config;
  }

  closeInstallPlaceModal() {
    this.installPlaceModalConfig = null;
  }

  openUserClonesModal(config: UserClonesModalConfigType) {
    this.userClonesModalConfig = config;
  }

  closeUserClonesModal() {
    this.userClonesModalConfig = null;
  }
}
