/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublishReviewResponse } from '../models/PublishReviewResponse';
import type { TopPartnersResponse } from '../models/TopPartnersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicService {
  /**
   * Gets the top partners.
   * @returns any Returns the top partners.
   * @throws ApiError
   */
  public static getPartnersTop(): CancelablePromise<{
    status: boolean;
    data: TopPartnersResponse;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/top',
    });
  }

  /**
   * Gets public reviews
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns public reviews
   * @throws ApiError
   */
  public static getPublicReviews(
    offset?: number,
    limit: number = 50
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<PublishReviewResponse>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/reviews',
      query: {
        offset: offset,
        limit: limit,
      },
    });
  }
}
