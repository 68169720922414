import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from 'store';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './FeedbackModal.module.scss';

const FeedbackModal: React.FC = () => {
  const { layoutStore } = useStore();

  return (
    <Modal
      className={styles.FeedbackModal}
      isOpen={!!layoutStore.feedbackModalMessage}
      onClose={layoutStore.closeFeedbackModal}
    >
      <Typography className={styles.message} element="p" variant="paragraph1">
        {layoutStore.feedbackModalMessage}
      </Typography>

      <Button className={styles.button} onClick={layoutStore.closeFeedbackModal}>
        OK
      </Button>
    </Modal>
  );
};

export default observer(FeedbackModal);
