/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MarketingPlace = {
  type: MarketingPlace.type;
  placeId: number;
};

export namespace MarketingPlace {
  export enum type {
    MAIN_PLACE = 'MainPlace',
  }
}
