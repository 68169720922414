import React from 'react';
import ReactPaginate from 'react-paginate';
import cx from 'classnames';

import { ReactComponent as ChevronRight } from 'static/icons/chevrons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'static/icons/chevrons/chevron-left.svg';

import styles from './Pagination.module.scss';

interface PaginationProps {
  className?: string;
  page: number;
  onChange: (newPage: number) => void;
  total: number;
  perPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ className, page, onChange, total, perPage }) => (
  <ReactPaginate
    className={cx(styles.Pagination, className)}
    pageLinkClassName={styles.page}
    nextLinkClassName={styles.page}
    previousLinkClassName={styles.page}
    breakLinkClassName={styles.page}
    activeLinkClassName={styles.active}
    disabledLinkClassName={styles.disabled}
    pageCount={total <= perPage ? 0 : Math.ceil(total / perPage)}
    pageRangeDisplayed={3}
    marginPagesDisplayed={1}
    renderOnZeroPageCount={() => null}
    onPageChange={(newPage) => {
      onChange(newPage.selected);
    }}
    previousLabel={<ChevronLeft />}
    nextLabel={<ChevronRight />}
    forcePage={total <= perPage ? undefined : page}
  />
);

export default Pagination;
