import React, { useMemo, useState } from 'react';

import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';
import { ApiError } from 'api/client';
import { mapApiErrors } from 'utils/errorHandling';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, TextField } from 'components/inputs';

import styles from './DepositModal.module.scss';

interface DepositValues {
  amount: number | string;
}

const DepositModal: React.FC = () => {
  const { t } = useTranslation();
  const { accountingStore, layoutStore } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const depositSchema: yup.SchemaOf<DepositValues> = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()
          .nullable()
          .required(t('modals.deposit.errors.amountRequired') as string)
          .positive(t('modals.deposit.errors.amountPositive') as string)
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
      }),
    [t]
  );

  const depositForm = useForm<DepositValues>({
    resolver: yupResolver(depositSchema),
    mode: 'onTouched',
    defaultValues: {
      amount: '',
    },
  });

  const handleClose = () => {
    depositForm.reset({ amount: '' });
    accountingStore.closeDepositModal();
  };

  const handleDepositSubmit = depositForm.handleSubmit(async (values: DepositValues) => {
    const paymentTab = window.open('', '_blank');
    try {
      paymentTab?.document.write('Loading...');
      setIsLoading(true);
      const { data } = await accountingStore.depositModalConfig?.submitHandler({ amount: values.amount as number })!;
      (paymentTab?.location)!.href = data.url;
      handleClose();
    } catch (error) {
      paymentTab?.close();
      const apiError = error as ApiError;
      if (apiError?.body?.errors) {
        mapApiErrors(apiError.body.errors, depositForm);
      } else {
        layoutStore.openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
      }
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Modal className={styles.DepositModal} isOpen={!!accountingStore.depositModalConfig} onClose={handleClose}>
      <Typography className={styles.title} element="h2" variant="subtitle1" color="black">
        {t('modals.deposit.title', { platform: accountingStore.depositModalConfig?.platform })}
      </Typography>

      <form className={styles.form} onSubmit={handleDepositSubmit}>
        <Controller
          control={depositForm.control}
          name="amount"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              label={t('modals.deposit.labels.amount')}
              error={fieldState.error?.message}
            />
          )}
        />

        <div className={styles.controls}>
          <Button className={styles.button} isLoading={isLoading} type="submit">
            {t('modals.deposit.submit')}
          </Button>
          <Button className={styles.button} disabled={isLoading} variant="outlined" onClick={handleClose}>
            {t('shared.controls.cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(DepositModal);
