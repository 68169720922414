/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InstallPartnerRequest = {
  /**
   * 1 - left place / 2 - right place / 3 - left-left place / 4 - left-right place / 5 - right-left place / 6 - right-right place
   */
  place: InstallPartnerRequest.place;
  rootPlaceId: number;
  partnerPlaceId: number;
};

export namespace InstallPartnerRequest {
  /**
   * 1 - left place / 2 - right place / 3 - left-left place / 4 - left-right place / 5 - right-left place / 6 - right-right place
   */
  export enum place {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
  }
}
