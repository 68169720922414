import React, { useMemo, useState } from 'react';

import numbro from 'numbro';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { ApiError, KlondaikMarketingService } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, TextField } from 'components/inputs';

import { ReactComponent as PlusIcon } from 'static/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'static/icons/minus.svg';

import styles from './PurchaseKlondikePlacesModal.module.scss';

const PurchaseKlondikePlacesModal: React.FC = () => {
  const {
    layoutStore,
    accountingStore: { getBalance },
    klondikeMarketingStore: {
      purchasePlacesModalConfig,
      closePurchasePlacesModal,
      getDesks,
      getDesk,
      getDeskByPlaceId,
    },
  } = useStore();
  const { t } = useTranslation();

  const [isSubmited, setIsSubmited] = useState(false);
  const [quantity, setQuantity] = useState<string>('1');
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  const clonesPrice = useMemo(
    () =>
      numbro((purchasePlacesModalConfig?.desk.price || 0) * (parseInt(quantity) || 0)).format({
        trimMantissa: false,
      }),
    [purchasePlacesModalConfig, quantity]
  );

  const incrementQuantity = () => {
    if (parseInt(quantity) < 100) {
      setQuantity((parseInt(quantity) + 1).toString());
    } else {
      setQuantity('100');
    }
  };

  const decrementQuantity = () => {
    if (parseInt(quantity) > 1) {
      setQuantity((parseInt(quantity) - 1).toString());
    } else {
      setQuantity('1');
    }
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setQuantity('');
      return;
    }

    const newValue = parseInt(event.target.value);
    setQuantity(Math.min(Math.max(1, newValue), 100).toString());
  };

  const handleClose = () => {
    setQuantity('1');
    setIsSubmited(false);
    closePurchasePlacesModal();
  };

  const handleSubmit = async () => {
    if (!purchasePlacesModalConfig) return;
    try {
      setIsPurchaseLoading(true);
      await KlondaikMarketingService.buyKlondaikPlaces(purchasePlacesModalConfig.desk.id, {
        quantity: parseInt(quantity),
      });
      getDesks();
      getBalance();

      if (purchasePlacesModalConfig.clones && purchasePlacesModalConfig.placeId) {
        getDeskByPlaceId(purchasePlacesModalConfig.placeId);
      } else if (purchasePlacesModalConfig.clones) {
        getDesk(purchasePlacesModalConfig.desk.id);
      }
    } catch (error) {
      const apiError = error as ApiError;
      layoutStore.openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
    } finally {
      setIsPurchaseLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className={styles.PurchaseKlondikePlacesModal}
      variant="unwrapped"
      isOpen={!!purchasePlacesModalConfig}
      onClose={handleClose}
    >
      {!isSubmited && (
        <>
          <Typography className={styles.title} variant="heading6" element="h2" color="boneWhite">
            {!!purchasePlacesModalConfig?.clones
              ? t('modals.klondikePurchaseDesk.titleClones')
              : t('modals.klondikePurchaseDesk.titlePlaces')}
          </Typography>

          <Typography className={styles.text} variant="paragraph1" element="p" color="boneWhite">
            <Trans
              i18nKey="modals.klondikePurchaseDesk.label"
              components={{ br: <br /> }}
              values={{
                entity: t(!!purchasePlacesModalConfig?.clones ? 'shared.plurals.clones' : 'shared.plurals.places', {
                  count: 5,
                }),
              }}
            />
          </Typography>

          <div className={styles.counter}>
            <Button className={styles.decrement} variant="outlined" color="lightGray" onClick={decrementQuantity}>
              <MinusIcon className={styles.icon} />
            </Button>

            <TextField className={styles.input} value={quantity} onChange={handleQuantityChange} />

            <Button className={styles.increment} variant="outlined" color="lightGray" onClick={incrementQuantity}>
              <PlusIcon className={styles.icon} />
            </Button>
          </div>

          <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
            {t('modals.klondikePurchaseDesk.price')}:
          </Typography>
          <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
            {clonesPrice} <span>ВВ</span>
          </Typography>

          <div className={styles.controls}>
            <Button variant="outlined" onClick={handleClose} color="lightGray">
              {t('shared.controls.cancel')}
            </Button>

            <Button disabled={!parseInt(quantity)} onClick={() => setIsSubmited(true)}>
              {t('modals.klondikePurchaseDesk.purchase')}
            </Button>
          </div>
        </>
      )}

      {isSubmited && (
        <>
          <Typography className={styles.clonesToPurchase} variant="heading6" element="p" color="boneWhite">
            <Trans
              i18nKey="modals.klondikePurchaseDesk.confirmation.title"
              components={{ br: <br />, span: <span /> }}
              values={{
                quantity,
                entity: t(!!purchasePlacesModalConfig?.clones ? 'shared.plurals.clones' : 'shared.plurals.places', {
                  count: parseInt(quantity),
                }),
              }}
            />
          </Typography>

          <Typography className={styles.clonesToPurchase} variant="paragraph1" element="p" color="boneWhite">
            {t('modals.klondikePurchaseDesk.confirmation.text', {
              quantity,
              price: clonesPrice,
              entity: t(!!purchasePlacesModalConfig?.clones ? 'shared.plurals.clones' : 'shared.plurals.places', {
                count: parseInt(quantity),
              }),
            })}
          </Typography>

          <div className={styles.controls}>
            <Button
              variant="outlined"
              onClick={() => setIsSubmited(false)}
              color="lightGray"
              disabled={isPurchaseLoading}
            >
              {t('shared.controls.cancel')}
            </Button>

            <Button onClick={handleSubmit} isLoading={isPurchaseLoading}>
              {t('shared.controls.confirm')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default observer(PurchaseKlondikePlacesModal);
