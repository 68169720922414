/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyRaffleTicketRequest } from '../models/BuyRaffleTicketRequest';
import type { CreateRaffleRequest } from '../models/CreateRaffleRequest';
import type { PrizeListResponse } from '../models/PrizeListResponse';
import type { RaffleParticipant } from '../models/RaffleParticipant';
import type { RaffleView } from '../models/RaffleView';
import type { RaffleViewModel } from '../models/RaffleViewModel';
import type { RaffleWasCreatedResponse } from '../models/RaffleWasCreatedResponse';
import type { Ticket } from '../models/Ticket';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MagicBoxService {
  /**
   * Gets a list of prizes
   * @returns any Returns a list of prizes
   * @throws ApiError
   */
  public static getPrizes(): CancelablePromise<{
    status: boolean;
    data: PrizeListResponse;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/magicbox/prizes',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets a list of raffles
   * @param offset Offset
   * @param limit Limit
   * @param ticketPriceFrom Ticket price from
   * @param ticketPriceTo Ticket price to
   * @param prizeAmountFrom Prize amount from
   * @param prizeAmountTo Prize amount to
   * @param active Status - active
   * @param completed Status - completed
   * @param participating Lots in which I participate
   * @param myLots My Lots
   * @returns any Returns a list of raffles
   * @throws ApiError
   */
  public static getRaffleList(
    offset?: number,
    limit: number = 20,
    ticketPriceFrom?: number,
    ticketPriceTo?: number,
    prizeAmountFrom?: number,
    prizeAmountTo?: number,
    active: boolean = true,
    completed: boolean = true,
    participating: boolean = false,
    myLots: boolean = false
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<RaffleViewModel>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/magicbox/raffles',
      query: {
        offset: offset,
        limit: limit,
        ticket_price_from: ticketPriceFrom,
        ticket_price_to: ticketPriceTo,
        prize_amount_from: prizeAmountFrom,
        prize_amount_to: prizeAmountTo,
        active: active,
        completed: completed,
        participating: participating,
        my_lots: myLots,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the raffle by id
   * @param raffleId Raffle ID
   * @param withParticipants With All Participants
   * @returns any Returns the raffle by id
   * @throws ApiError
   */
  public static getOneRaffle(
    raffleId: string,
    withParticipants: boolean = false
  ): CancelablePromise<{
    status: boolean;
    data: RaffleView;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/magicbox/raffle/{raffleId}',
      path: {
        raffleId: raffleId,
      },
      query: {
        with_participants: withParticipants,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Cancels an active raffle.
   * Cancel will be available to the user who created the raffle.
   *
   * If places were purchased by users in the raffle, after the cancellation of the raffle, all funds must be returned to the users who purchased the tickets.
   * @param raffleId Raffle ID
   * @returns void
   * @throws ApiError
   */
  public static cancelRaffle(raffleId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/magicbox/raffle/{raffleId}',
      path: {
        raffleId: raffleId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets the list of participants in the raffle
   * @param raffleId Raffle ID
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns the list of participants in the raffle
   * @throws ApiError
   */
  public static getRaffleParticipants(
    raffleId: string,
    offset?: number,
    limit: number = 18
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<RaffleParticipant>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/magicbox/raffle/{raffleId}/participants',
      path: {
        raffleId: raffleId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the list of tickets in the raffle
   * @param raffleId Raffle ID
   * @param offset Offset
   * @param limit Limit
   * @param onlyWinner Only Winner Tickets
   * @returns any Returns the list of tickets in the raffle
   * @throws ApiError
   */
  public static getRaffleTickets(
    raffleId: string,
    offset?: number,
    limit: number = 18,
    onlyWinner: boolean = false
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<Ticket>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/magicbox/raffle/{raffleId}/tickets',
      path: {
        raffleId: raffleId,
      },
      query: {
        offset: offset,
        limit: limit,
        onlyWinner: onlyWinner,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Buys tickets for the raffle
   * @param raffleId Raffle ID
   * @param requestBody Indicates the number of tickets purchased
   * @returns void
   * @throws ApiError
   */
  public static buyRaffleTickets(raffleId: string, requestBody: BuyRaffleTicketRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/magicbox/raffle/{raffleId}/tickets',
      path: {
        raffleId: raffleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Creates a raffle
   * @param requestBody
   * @returns any Returns the ID of the created raffle
   * @throws ApiError
   */
  public static createRaffle(requestBody: CreateRaffleRequest): CancelablePromise<{
    status: boolean;
    data: RaffleWasCreatedResponse;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/magicbox/raffle',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }
}
