import React, { useEffect, useRef } from 'react';

import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { UserData } from 'api/client';
import { useOnScreen } from 'utils/hooks';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as TrashIcon } from 'static/icons/trash.svg';

import styles from './NotificationCard.module.scss';

interface NotificationCardProps {
  message: string;
  isHtml?: boolean;
  sender?: UserData;
  isRead?: boolean;
  onRead?: () => void;
  onDelete?: () => void;
}

const insertLinksInMessage = (message: string): React.ReactNode => {
  const searchRes = message.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g
  );
  if (!searchRes) return message;

  const parsingRes = searchRes.reduce(
    (acc, match) => {
      const splitedMessage = acc.right.split(match);
      const left = splitedMessage[0];
      const right = splitedMessage.slice(1, splitedMessage.length).join(match);

      return {
        left: (
          <>
            {acc.left}
            {left}
            <Button.Link className={styles.link} variant="text" href={match}>
              {match}
            </Button.Link>
          </>
        ),
        right,
      };
    },
    { left: <></>, right: message }
  );

  return parsingRes.left;
};

const NotificationCard: React.FC<NotificationCardProps> = ({ message, sender, isHtml, onDelete, isRead, onRead }) => {
  const cardRef = useRef(null);
  const { t } = useTranslation();
  const isInView = useOnScreen(cardRef);

  useEffect(() => {
    if (isInView && isRead === false && onRead) {
      onRead();
    }
  }, [isInView, isRead, onRead]);

  return (
    <Card className={styles.NotificationCard} ref={cardRef}>
      <div className={styles.data}>
        <Typography className={styles.sender} variant="caption1" color="blackTransparent">
          {sender ? (
            <Trans
              i18nKey="notificationsPage.userSignature"
              components={{ span: <span className={styles.link} /> }}
              values={{ username: sender.username }}
            />
          ) : (
            t('notificationsPage.companySignature')
          )}
        </Typography>

        {isHtml ? (
          <Typography className={styles.message} variant="paragraph2" color="black" dangerouslySetInnerHTML={message} />
        ) : (
          <Typography className={styles.message} variant="paragraph2" color="black">
            {insertLinksInMessage(message)}
          </Typography>
        )}
      </div>

      <div className={styles.controls}>
        {onDelete && (
          <Button className={cx(styles.button, styles.delete)} variant="wrapper" onClick={onDelete}>
            <TrashIcon className={styles.icon} />
          </Button>
        )}
      </div>
    </Card>
  );
};

export default NotificationCard;
