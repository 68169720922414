/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateReviewRequest = {
  text: string;
  /**
   * New: 0<br/>Approved: 1<br/>Declined: 2
   */
  status: UpdateReviewRequest.status;
  publishedOnMain: boolean;
};

export namespace UpdateReviewRequest {
  /**
   * New: 0<br/>Approved: 1<br/>Declined: 2
   */
  export enum status {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}
