import { makeAutoObservable } from 'mobx';

import {
  UsersService,
  ReviewResponse,
  CurrentUserResponse,
  UserNotificationItem,
  SummaryUserStructure,
  SystemNotificationItem,
  SiteNotificationResponse,
  NotificationService,
  StarsOnlineRequestItem,
} from 'api/client';
import {
  CreateReviewModalConfig,
  CreateNotificationModalConfig,
  DeleteNotificationModalConfig,
  RemoveFromStructureModalConfig,
  TwoFactorSettingsModalConfig,
} from 'types/modalConfigs';
import moment from 'moment';
import RootStore from 'store';
import { UserType } from 'types/common';
import { sortBy } from 'lodash';

export default class UserStore {
  rootStore: RootStore;

  user: UserType | null;

  userStructureSummary: SummaryUserStructure | null;

  starsOnlineRequests: StarsOnlineRequestItem[] | null;

  systemNotifications: {
    items: SystemNotificationItem[];
    total: number;
    page: number;
    perPage: number;
  };

  userNotifications: {
    items: UserNotificationItem[];
    total: number;
    page: number;
    perPage: number;
  };

  magicBoxNotifications: {
    items: SiteNotificationResponse[];
    total: number;
    page: number;
    perPage: number;
  };

  reviews: {
    items: ReviewResponse[];
    total: number;
    page: number;
    perPage: number;
  };

  deleteNotificationModalConfig: DeleteNotificationModalConfig | null;

  createNotificationModalConfig: CreateNotificationModalConfig | null;

  createReviewModalConfig: CreateReviewModalConfig | null;

  removeFromStructureModalConfig: RemoveFromStructureModalConfig | null;

  twoFactorSettingsModalConfig: TwoFactorSettingsModalConfig | null;

  loading: {
    getUser: boolean;
    getSystemNotifications: boolean;
    getUserNotifications: boolean;
    getMagicBoxNotifications: boolean;
    deleteNotification: boolean;
    getReviews: boolean;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'userStore' });
    this.rootStore = rootStore;
    this.user = null;
    this.userStructureSummary = null;
    this.starsOnlineRequests = null;

    this.systemNotifications = {
      items: [],
      total: 0,
      page: 0,
      perPage: 8,
    };

    this.userNotifications = {
      items: [],
      total: 0,
      page: 0,
      perPage: 8,
    };

    this.reviews = {
      items: [],
      total: 0,
      page: 0,
      perPage: 12,
    };

    this.magicBoxNotifications = {
      items: [],
      total: 0,
      page: 0,
      perPage: 8,
    };

    this.deleteNotificationModalConfig = null;
    this.createNotificationModalConfig = null;
    this.createReviewModalConfig = null;
    this.removeFromStructureModalConfig = null;
    this.twoFactorSettingsModalConfig = null;

    this.loading = {
      getUser: false,
      getSystemNotifications: false,
      getUserNotifications: false,
      deleteNotification: false,
      getMagicBoxNotifications: false,
      getReviews: false,
    };
  }

  get refLink() {
    return `${window.location.origin}/register?ref=${this.user?.username}`;
  }

  get userFullName() {
    return `${this.user?.firstName || ''} ${this.user?.lastName || ''}`;
  }

  get userLastLinkRequest() {
    return this.starsOnlineRequests
      ? sortBy(this.starsOnlineRequests, (request) => request.createdAt)[this.starsOnlineRequests.length - 1]
      : null;
  }

  *getUser() {
    try {
      this.loading.getUser = true;
      const { data: userData } = yield UsersService.getCurrentUser();
      const { data: userStructureSummary } = yield UsersService.getSummaryStatisticsByCurrentUser();
      this.user = userData;
      this.userStructureSummary = userStructureSummary;
    } catch (error) {
      console.log('[UserStore] getUser error:', error);
    } finally {
      this.loading.getUser = false;
    }
  }

  *getUserStarsOnlineRequests() {
    try {
      let response: { data: { items: StarsOnlineRequestItem[]; total: number } } =
        yield UsersService.getStarsOnlineRequestList(0, 100, false);
      if (response.data.total > 100) {
        response = yield UsersService.getStarsOnlineRequestList(response.data.total - 100, 100, false);
      }
      this.starsOnlineRequests = response.data.items;
    } catch (error) {
      console.log('[UserStore] getUser error:', error);
    }
  }

  resetUser() {
    this.user = null;
    this.systemNotifications = {
      ...this.systemNotifications,
      page: 0,
      items: [],
      total: 0,
    };
    this.userNotifications = {
      ...this.userNotifications,
      page: 0,
      items: [],
      total: 0,
    };
  }

  updateUser(newUser: UserType | CurrentUserResponse) {
    this.user = newUser as UserType;
  }

  *getSystemNotifications() {
    try {
      this.loading.getSystemNotifications = true;
      const { data } = yield UsersService.getSystemNotifications(
        this.systemNotifications.perPage * this.systemNotifications.page,
        this.systemNotifications.perPage
      );
      this.systemNotifications = {
        ...this.systemNotifications,
        ...data,
      };
    } catch (error) {
      console.log('[UserStore] getSystemNotifications error:', error);
    } finally {
      this.loading.getSystemNotifications = false;
    }
  }

  updateSystemNotificationsPage(newPage: number) {
    this.systemNotifications.page = newPage;
    this.getSystemNotifications();
  }

  *getUserNotifications() {
    try {
      this.loading.getUserNotifications = true;
      const { data } = yield UsersService.getPersonalNotifications(
        this.userNotifications.perPage * this.userNotifications.page,
        this.userNotifications.perPage
      );
      this.userNotifications = {
        ...this.userNotifications,
        ...data,
      };
    } catch (error) {
      console.log('[UserStore] getUserNotifications error:', error);
    } finally {
      this.loading.getUserNotifications = false;
    }
  }

  updateUserNotificationsPage(newPage: number) {
    this.userNotifications.page = newPage;
    this.getUserNotifications();
  }

  *deleteUserNotification(id: string) {
    try {
      this.loading.deleteNotification = true;
      yield UsersService.deletePersonalNotification(id);
      yield this.getUserNotifications();
      this.closeDeleteNotificationModal();
    } catch (error) {
      console.log('[UserStore] deleteUserNotification error:', error);
    } finally {
      this.loading.deleteNotification = false;
    }
  }

  *getMagicBoxNotifications() {
    try {
      this.loading.getMagicBoxNotifications = true;
      const { data } = yield NotificationService.getEventNotifications(
        'magicbox',
        this.magicBoxNotifications.perPage * this.magicBoxNotifications.page,
        this.magicBoxNotifications.perPage
      );
      this.magicBoxNotifications = {
        ...this.magicBoxNotifications,
        ...data,
      };
    } catch (error) {
      console.log('[UserStore] getMagicBoxNotifications error:', error);
    } finally {
      this.loading.getMagicBoxNotifications = false;
    }
  }

  updateMagicBoxNotificationsPage(newPage: number) {
    this.magicBoxNotifications.page = newPage;
    this.getMagicBoxNotifications();
  }

  *deleteMagicBoxNotification(id: number) {
    try {
      this.loading.deleteNotification = true;
      yield NotificationService.deleteEventNotification(id);
      yield this.getMagicBoxNotifications();
      this.closeDeleteNotificationModal();
    } catch (error) {
      console.log('[UserStore] deleteMagicBoxNotification error:', error);
    } finally {
      this.loading.deleteNotification = false;
    }
  }

  *readMagicBoxNotification(id: number) {
    try {
      yield NotificationService.readEventNotifications(id);
      this.magicBoxNotifications = {
        ...this.magicBoxNotifications,
        items: this.magicBoxNotifications.items.map((notification) =>
          notification.id === id ? { ...notification, readAt: moment().format('DD.MM.YYYY HH:mm') } : notification
        ),
      };
    } catch (error) {
      console.log('[UserStore] deleteMagicBoxNotification error:', error);
    } finally {
      this.loading.deleteNotification = false;
    }
  }

  openDeleteNotificationModal(config: DeleteNotificationModalConfig) {
    this.deleteNotificationModalConfig = config;
  }

  closeDeleteNotificationModal() {
    this.deleteNotificationModalConfig = null;
  }

  openCreateNotificationModal(config: CreateNotificationModalConfig) {
    this.createNotificationModalConfig = config;
  }

  closeCreateNotificationModal() {
    this.createNotificationModalConfig = null;
  }

  *getReviews() {
    try {
      this.loading.getReviews = true;
      const { data } = yield UsersService.getReviews(this.reviews.perPage * this.reviews.page, this.reviews.perPage);
      this.reviews = {
        ...this.reviews,
        ...data,
      };
    } catch (error) {
      console.log('[UserStore] getReviews error', error);
    } finally {
      this.loading.getReviews = false;
    }
  }

  updateReviewsPage(newPage: number) {
    this.reviews.page = newPage;
    this.getReviews();
  }

  openCreateReviewModal(config: CreateReviewModalConfig) {
    this.createReviewModalConfig = config;
  }

  closeCreateReviewModal() {
    this.createReviewModalConfig = null;
  }

  openRemoveFromStructureModal(config: RemoveFromStructureModalConfig) {
    this.removeFromStructureModalConfig = config;
  }

  closeRemoveFromStructureModal() {
    this.removeFromStructureModalConfig = null;
  }

  openTwoFactorSettingsModal(config: TwoFactorSettingsModalConfig) {
    this.twoFactorSettingsModalConfig = config;
  }

  closeTwoFactorSettingsModal() {
    this.twoFactorSettingsModalConfig = null;
  }
}
