import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { KlondikeMarketingStatsFilterType } from 'types/klondikeMarketing';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './KlondikeStatsFilterModal.module.scss';

const KlondikeStatsFilterModal: React.FC = () => {
  const { t } = useTranslation();
  const { klondikeMarketingStore } = useStore();

  const [filterValues, setFilterValues] = useState<KlondikeMarketingStatsFilterType>({
    deskIds: [],
    lines: [],
    placesLeft: [],
    clones: false,
    username: '',
  });

  const handleSelect = (key: keyof KlondikeMarketingStatsFilterType, value: number) => {
    if ((filterValues[key] as number[]).includes(value)) {
      setFilterValues({
        ...filterValues,
        [key]: (filterValues[key] as number[]).filter((filterValue) => filterValue !== value),
      });
    } else {
      setFilterValues({ ...filterValues, [key]: [...(filterValues[key] as number[]), value] });
    }
  };

  const handleSubmit = () => {
    klondikeMarketingStore.updateStatsFilter(filterValues);
    klondikeMarketingStore.closeStatsFilterModal();
  };

  const handleSwitchCLones = (newValue: boolean) => {
    setFilterValues({ ...filterValues, clones: newValue, lines: newValue ? [] : filterValues.lines });
  };

  useEffect(() => {
    klondikeMarketingStore.getDesks();
  }, [klondikeMarketingStore]);

  useEffect(() => {
    if (klondikeMarketingStore.isStatsFilterModalOpen) {
      setFilterValues(klondikeMarketingStore.stats.filter);
    } else {
      setFilterValues({ deskIds: [], lines: [], placesLeft: [], clones: false, username: '' });
    }
  }, [klondikeMarketingStore.isStatsFilterModalOpen, klondikeMarketingStore.stats.filter]);

  return (
    <Modal
      className={styles.KlondikeStatsFilterModal}
      isOpen={!!klondikeMarketingStore.isStatsFilterModalOpen}
      onClose={klondikeMarketingStore.closeStatsFilterModal}
    >
      <Typography className={styles.title} element="h2" variant="heading6" color="black">
        {t('shared.filter')}
      </Typography>

      <div className={styles.clonesSwitcher}>
        <Button
          className={cx(styles.option, { [styles.selected]: !filterValues.clones })}
          variant="wrapper"
          onClick={() => handleSwitchCLones(false)}
        >
          {t('modals.mainMarketingStatsFilter.tabs.partners')}
        </Button>
        <Button
          className={cx(styles.option, { [styles.selected]: filterValues.clones })}
          variant="wrapper"
          onClick={() => handleSwitchCLones(true)}
        >
          {t('modals.mainMarketingStatsFilter.tabs.clones')}
        </Button>
      </div>

      <div className={styles.filters}>
        {!filterValues.clones && (
          <div className={styles.filter}>
            <Typography className={styles.label} element="span" variant="paragraph1" color="black">
              {t('modals.mainMarketingStatsFilter.labesl.line')}:
            </Typography>

            <div className={cx(styles.options, styles.line)}>
              {[1, 2, 3, 4, 5].map((filterOption) => (
                <Button
                  className={cx(styles.filterOption, {
                    [styles.active]: filterValues.lines.includes(filterOption),
                  })}
                  variant="wrapper"
                  key={filterOption}
                  onClick={() => handleSelect('lines', filterOption)}
                >
                  {filterOption}
                </Button>
              ))}
            </div>
          </div>
        )}

        <div className={styles.filter}>
          <Typography className={styles.label} element="span" variant="paragraph1" color="black">
            {t('modals.mainMarketingStatsFilter.labesl.desk')}:
          </Typography>

          <div className={cx(styles.deskGroup, styles.red)}>
            <span className={styles.label}>Red</span>
            <div className={styles.options}>
              {klondikeMarketingStore.desksGroupedByBlocks?.red.map((desk) => (
                <Button
                  className={cx(styles.filterOption, { [styles.active]: filterValues.deskIds.includes(desk.id) })}
                  variant="wrapper"
                  key={desk.id}
                  onClick={() => handleSelect('deskIds', desk.id)}
                >
                  {desk.name}
                </Button>
              ))}
            </div>
          </div>
          <div className={cx(styles.deskGroup, styles.yellow)}>
            <span className={styles.label}>Yellow</span>
            <div className={styles.options}>
              {klondikeMarketingStore.desksGroupedByBlocks?.yellow.map((desk) => (
                <Button
                  className={cx(styles.filterOption, { [styles.active]: filterValues.deskIds.includes(desk.id) })}
                  variant="wrapper"
                  key={desk.id}
                  onClick={() => handleSelect('deskIds', desk.id)}
                >
                  {desk.name}
                </Button>
              ))}
            </div>
          </div>
          <div className={cx(styles.deskGroup, styles.white)}>
            <span className={styles.label}>White</span>
            <div className={styles.options}>
              {klondikeMarketingStore.desksGroupedByBlocks?.white.map((desk) => (
                <Button
                  className={cx(styles.filterOption, { [styles.active]: filterValues.deskIds.includes(desk.id) })}
                  variant="wrapper"
                  key={desk.id}
                  onClick={() => handleSelect('deskIds', desk.id)}
                >
                  {desk.name}
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.filter}>
          <Typography className={styles.label} element="span" variant="paragraph1" color="black">
            {t('modals.mainMarketingStatsFilter.labesl.placesLeft')}:
          </Typography>

          <div className={cx(styles.options, styles.places)}>
            {[1, 2].map((filterOption) => (
              <Button
                className={cx(styles.filterOption, {
                  [styles.active]: filterValues.placesLeft.includes(filterOption),
                })}
                variant="wrapper"
                key={filterOption}
                onClick={() => handleSelect('placesLeft', filterOption)}
              >
                {filterOption}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.controls}>
        <Button variant="outlined" color="black" size="large" onClick={klondikeMarketingStore.closeStatsFilterModal}>
          {t('shared.controls.close')}
        </Button>
        <Button size="large" onClick={handleSubmit}>
          {t('shared.controls.apply')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(KlondikeStatsFilterModal);
