import React, { FormEvent, Fragment, useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { NumericFormat } from 'react-number-format';

import { useStore } from 'store';
import { AdminService } from 'api/client';
import { AdminFiltersType } from 'types/modalConfigs';
import { DateRange, SelectOption } from 'types/common';
import { AdminUsersFilterType } from 'types/adminUsers';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, DateRangePicker, Select, TextField } from 'components/inputs';

import styles from './AdminFilterModal.module.scss';

const AdminFilterModal: React.FC = () => {
  const { adminStore, accountingStore } = useStore();
  const { adminFilterModalConfig } = adminStore;

  const [usersOptions, setUsersOptions] = useState<SelectOption[]>([]);
  const [formValues, setFormValues] = useState<AdminFiltersType>({});
  const [isUsersOptionsLoading, setIsUsersOptionsLoading] = useState<keyof AdminUsersFilterType | null>(null);

  const getUsersOptions = debounce(async (query: string, key: keyof AdminUsersFilterType) => {
    try {
      setIsUsersOptionsLoading(key);
      const response = await AdminService.getUserList(0, 10, query);
      setUsersOptions(response.data.items.map((user) => ({ value: user.id, label: user.username })));
    } catch (error) {
      console.log('[AdminFilterModal] getUsersOptions error', error);
    } finally {
      setIsUsersOptionsLoading(null);
    }
  }, 500);

  const dynamicOptions = {
    users: {
      options: usersOptions,
      getter: getUsersOptions,
      loading: isUsersOptionsLoading,
    },
    type: {
      options: accountingStore.transactionsTypes,
      getter: null,
      loading: null,
    },
    status: {
      options: accountingStore.transactionsStatuses,
      getter: null,
      loading: null,
    },
  };

  useEffect(() => {
    if (!!adminFilterModalConfig) {
      setFormValues({ ...adminFilterModalConfig.filter });
    }
  }, [adminFilterModalConfig]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    adminStore.closeAdminFilterModal();
    adminFilterModalConfig?.onSubmit(
      adminFilterModalConfig?.filtersLits.reduce((acc, filter) => {
        switch (filter.input.type) {
          case 'number':
            return {
              ...acc,
              [filter.key]: formValues[filter.key] ? parseFloat(formValues[filter.key] as string) : undefined,
            };
          case 'dynamicSelect':
            return {
              ...acc,
              [filter.key]: filter.input.props?.isMulti
                ? (formValues[filter.key] as SelectOption[])?.length
                  ? formValues[filter.key]
                  : undefined
                : formValues[filter.key],
            };
          case 'select':
          case 'daterange':
          case 'text':
          default:
            return { ...acc, [filter.key]: formValues[filter.key] || undefined };
        }
      }, {} as AdminFiltersType)
    );
  };

  return (
    <Modal
      className={styles.AdminFilterModal}
      isOpen={!!adminStore.adminFilterModalConfig}
      onClose={adminStore.closeAdminFilterModal}
    >
      <Typography className={styles.title} element="h2" variant="heading6" color="black">
        Фильтр
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit}>
        {adminFilterModalConfig?.filtersLits.map((filter) => (
          <Fragment key={filter.key}>
            {filter.input.type === 'text' && (
              <TextField
                label={filter.label}
                value={(formValues[filter.key] as string) ?? ''}
                onChange={(event) => setFormValues({ ...formValues, [filter.key]: event.target.value })}
              />
            )}

            {filter.input.type === 'number' && (
              <NumericFormat
                label={filter.label}
                value={(formValues[filter.key] as string) ?? ''}
                customInput={TextField}
                onChange={(event) => setFormValues({ ...formValues, [filter.key]: event.target.value })}
              />
            )}

            {filter.input.type === 'select' && (
              <Select
                label={filter.label}
                placeholder=""
                value={formValues[filter.key] as SelectOption | SelectOption[]}
                onChange={(newValue) => setFormValues({ ...formValues, [filter.key]: newValue })}
                options={filter.input.props?.options || []}
              />
            )}

            {filter.input.type === 'dynamicSelect' && (
              <Select
                isSearchable
                label={filter.label}
                placeholder=""
                isMulti={filter.input.props?.isMulti}
                value={(formValues[filter.key] as SelectOption | SelectOption[]) ?? []}
                onChange={(newValue) => setFormValues({ ...formValues, [filter.key]: newValue })}
                options={dynamicOptions[filter.input.props?.dynamicOptions!].options}
                isLoading={dynamicOptions[filter.input.props?.dynamicOptions!]?.loading === filter.key}
                onInputChange={(newValue) =>
                  dynamicOptions[filter.input.props?.dynamicOptions!]?.getter?.(
                    newValue,
                    filter.key as keyof AdminUsersFilterType
                  )
                }
              />
            )}

            {filter.input.type === 'daterange' && (
              <DateRangePicker
                label={filter.label}
                value={formValues[filter.key] as DateRange}
                onChange={(newValue) => setFormValues({ ...formValues, [filter.key]: newValue })}
              />
            )}
          </Fragment>
        ))}

        <div className={styles.controls}>
          <Button className={styles.button} type="submit">
            Применить
          </Button>
          <Button className={styles.button} variant="outlined" onClick={adminStore.closeAdminFilterModal}>
            Отмена
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(AdminFilterModal);
