import React, { useState } from 'react';

import numbro from 'numbro';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { ApiError, TicTacMarketingService } from 'api/client';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './InstallTictacPlaceModal.module.scss';

const InstallTictacPlaceModal: React.FC = () => {
  const { t } = useTranslation();
  const { placeId } = useParams();
  const { tictacMarketingStore, layoutStore } = useStore();
  const { desk, installPlaceModalConfig, closeInstallPlaceModal } = tictacMarketingStore;

  const [isInstallLoading, setIsInstallLoading] = useState(false);

  const handleClose = () => {
    closeInstallPlaceModal();
  };

  const handleInstall = async () => {
    try {
      setIsInstallLoading(true);
      await TicTacMarketingService.buyTicTacClone(
        installPlaceModalConfig?.placeId ?? desk?.items[0]?.id!,
        installPlaceModalConfig?.place as 'L' | 'R'
      );

      if (placeId) {
        tictacMarketingStore.getDeskByPlaceId(parseInt(placeId));
      } else {
        tictacMarketingStore.getDesk(desk?.current.id!);
      }
      handleClose();
    } catch (error) {
      layoutStore.openFeedbackModal((error as ApiError)?.body?.message || t('shared.errors.unknownError'));
    } finally {
      setIsInstallLoading(false);
    }
  };

  return (
    <Modal
      className={styles.InstallTictacPlaceModal}
      variant="unwrapped"
      isOpen={!!installPlaceModalConfig}
      onClose={handleClose}
    >
      <Typography className={styles.title} variant="heading6" element="h2" color="white">
        {t('modals.installTictacPlace.title')}
      </Typography>

      <Typography className={styles.text} variant="paragraph2" element="p" color="boneWhite">
        {t('modals.installTictacPlace.text')}
      </Typography>

      <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
        {t('modals.installTictacPlace.price')}:
      </Typography>
      <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
        {numbro(desk?.current.price).format()} <span>ВВ</span>
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          variant="outlined"
          color="lightGray"
          disabled={isInstallLoading}
          onClick={handleClose}
        >
          {t('shared.controls.cancel')}
        </Button>
        <Button className={styles.button} isLoading={isInstallLoading} onClick={handleInstall}>
          {t('modals.installTictacPlace.submit')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(InstallTictacPlaceModal);
