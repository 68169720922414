import React from 'react';

import cx from 'classnames';
import { motion } from 'framer-motion';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  className?: string;
  overlay?: boolean;
  variant?: 'compact' | 'overlay' | 'bluredOverlay';
}

const Spinner: React.FC<SpinnerProps> = ({ className, variant = 'compact' }) => {
  if (variant === 'bluredOverlay') {
    return (
      <motion.div
        className={styles.bluredOverlay}
        initial="hidden"
        animate="shown"
        exit="hidden"
        variants={{
          shown: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <svg className={cx(styles.Spinner, className)} viewBox="0 0 50 50" fill="none">
          <circle cx="25" cy="25" r="20" stroke="currentColor" strokeWidth="5" />
        </svg>
      </motion.div>
    );
  }

  return (
    <svg className={cx(styles.Spinner, styles[variant], className)} viewBox="0 0 50 50" fill="none">
      <circle cx="25" cy="25" r="20" stroke="currentColor" strokeWidth="5" />
    </svg>
  );
};

export default Spinner;
