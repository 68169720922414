import { makeAutoObservable } from 'mobx';

import RootStore from 'store';
import { SelectOption } from 'types/common';
import { TransactionsFilterModalConfig } from 'types/transactions';
import { TransactionTypeView } from 'api/client/models/TransactionTypeView';
import { TransactionStatusView } from 'api/client/models/TransactionStatusView';
import { DepositModalConfigType, WithdrawModalConfigType } from 'types/modalConfigs';
import { AccountingService, BalanceViewModel, EarnedData } from 'api/client';

export default class AccountingStore {
  rootStore: RootStore;

  balance: BalanceViewModel | null;

  earnings: EarnedData | null;

  transactionsTypes: SelectOption[];

  transactionsStatuses: SelectOption[];

  withdrawModalConfig: WithdrawModalConfigType | null;

  depositModalConfig: DepositModalConfigType | null;

  transactionsFilterModalConfig: TransactionsFilterModalConfig | null;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'accountingStore' });
    this.rootStore = rootStore;
    this.withdrawModalConfig = null;
    this.depositModalConfig = null;
    this.transactionsFilterModalConfig = null;
    this.balance = null;
    this.earnings = null;
    this.transactionsTypes = [];
    this.transactionsStatuses = [];
  }

  get totalBalance() {
    return (this.balance?.balanceAmount || 0) + (this.balance?.internalBalanceAmount || 0);
  }

  *getBalance() {
    try {
      const { data } = yield AccountingService.getCurrentUserBalance();
      this.balance = data;
    } catch (error) {
      console.log('[AccountingStore] getBalance error:', error);
    }
  }

  *getEearnedAmount() {
    try {
      const { data } = yield AccountingService.getMyEarnedAmount();
      this.earnings = data;
    } catch (error) {
      console.log('[AccountingStore] getEearnedAmount error:', error);
    }
  }

  withdraw(amount: number) {
    try {
      if (amount <= 0) throw new Error('amount must be positive');

      this.balance = {
        ...this.balance!,
        balanceAmount: this.balance?.balanceAmount! - amount,
        version: this.balance?.version! + 1,
      };
    } catch (error) {
      console.log('[AccountingStore] withdraw error:', error);
    }
  }

  transfer(amount: number) {
    try {
      if (amount <= 0) throw new Error('amount must be positive');

      const [newInternalBalanceAmount, newBalanceAmount] =
        this.balance?.internalBalanceAmount! < amount
          ? [0, this.balance?.balanceAmount! - (amount - this.balance?.internalBalanceAmount!)]
          : [this.balance?.internalBalanceAmount! - amount, this.balance?.balanceAmount!];

      this.balance = {
        ...this.balance!,
        internalBalanceAmount: newInternalBalanceAmount,
        balanceAmount: newBalanceAmount,
        version: this.balance?.version! + 1,
      };
    } catch (error) {
      console.log('[AccountingStore] withdraw error:', error);
    }
  }

  *getTransactionsTypes() {
    try {
      const { data } = yield AccountingService.getTransactionTypes();
      this.transactionsTypes = (data.items as TransactionTypeView[]).map((transactionType) => ({
        label: transactionType.title,
        value: transactionType.value,
      }));
    } catch (error) {
      console.log('[AccountingStore] getTransactionsTypes errors:', error);
    }
  }

  *getTransactionsStatuses() {
    try {
      const { data } = yield AccountingService.getTransactionStatuses();
      this.transactionsStatuses = (data.items as TransactionStatusView[]).map((transactionStatus) => ({
        label: transactionStatus.title,
        value: transactionStatus.value,
      }));
    } catch (error) {
      console.log('[AccountingStore] getTransactionsStatuses errors:', error);
    }
  }

  openDepositModal(config: DepositModalConfigType) {
    this.depositModalConfig = config;
  }

  closeDepositModal() {
    this.depositModalConfig = null;
  }

  openWithdrawModal(config: WithdrawModalConfigType) {
    this.withdrawModalConfig = config;
  }

  closeWithdrawModal() {
    this.withdrawModalConfig = null;
  }

  openTransactionsFilterModal(config: TransactionsFilterModalConfig) {
    this.transactionsFilterModalConfig = config;
  }

  closeTransactionsFilterModal() {
    this.transactionsFilterModalConfig = null;
  }
}
