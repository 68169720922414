import React from 'react';

import { useStore } from 'store';
import routes from 'config/routes';

import Button from 'components/inputs/Button';
import Logo from 'components/dataDisplay/Logo';

import { ReactComponent as MenuIcon } from 'static/icons/menu.svg';

import styles from './Header.module.scss';

const Header: React.FC = () => {
  const { layoutStore } = useStore();

  return (
    <div className={styles.Header}>
      <Button.RouterLink to={routes.main} variant="wrapper">
        <Logo className={styles.logo} iconClassName={styles.icon} textClassName={styles.text} color="white" />
      </Button.RouterLink>

      <Button className={styles.menu} variant="wrapper" onClick={layoutStore.openSidebar}>
        <MenuIcon className={styles.icon} />
      </Button>
    </div>
  );
};

export default Header;
