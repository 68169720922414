import React, { ChangeEvent, useMemo, useState } from 'react';

import numbro from 'numbro';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { TopBoxMarketingService } from 'api/client';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button, TextField } from 'components/inputs';

import { ReactComponent as PlusIcon } from 'static/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'static/icons/minus.svg';

import styles from './PurchaseTopboxClonesModal.module.scss';

const PurchaseTopboxClonesModal: React.FC = () => {
  const { t } = useTranslation();
  const { topboxMarketingStore, layoutStore, accountingStore } = useStore();
  const { purchaseClonesModalConfig } = topboxMarketingStore;

  const [isSubmited, setIsSubmited] = useState(false);
  const [quantity, setQuantity] = useState<string>('1');
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  const clonesPrice = useMemo(
    () =>
      numbro((purchaseClonesModalConfig?.desk.price || 0) * (parseInt(quantity) || 0)).format({
        trimMantissa: false,
      }),
    [purchaseClonesModalConfig, quantity]
  );

  const incrementQuantity = () => {
    if (parseInt(quantity) < 100) {
      setQuantity((parseInt(quantity) + 1).toString());
    } else {
      setQuantity('100');
    }
  };

  const decrementQuantity = () => {
    if (parseInt(quantity) > 1) {
      setQuantity((parseInt(quantity) - 1).toString());
    } else {
      setQuantity('1');
    }
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setQuantity('');
      return;
    }

    const newValue = parseInt(event.target.value);
    setQuantity(Math.min(Math.max(1, newValue), 100).toString());
  };

  const handleClose = () => {
    setQuantity('1');
    setIsSubmited(false);
    topboxMarketingStore.closePurchaseClonesModal();
  };

  const handleSubmit = () => {
    if (purchaseClonesModalConfig) {
      setIsPurchaseLoading(true);
      TopBoxMarketingService.topBoxMarketingBuyClone(purchaseClonesModalConfig.desk.id, {
        quantity: parseInt(quantity),
      })
        .then(() => {
          purchaseClonesModalConfig.onSubmit();
          accountingStore.getBalance();
        })
        .catch((error) => {
          layoutStore.openFeedbackModal(error?.body?.message || t('shared.errors.unknownError'));
        })
        .finally(() => {
          setIsPurchaseLoading(false);
          handleClose();
        });
    }
  };

  return (
    <Modal
      className={styles.PurchaseTopboxClonesModal}
      variant="unwrapped"
      isOpen={!!purchaseClonesModalConfig}
      onClose={handleClose}
    >
      {!isSubmited && (
        <>
          <Typography className={styles.title} variant="heading6" element="h2" color="boneWhite">
            {t('modals.purchaseTopboxClones.title')}
          </Typography>

          <Typography className={styles.text} variant="paragraph1" element="p" color="boneWhite">
            <Trans i18nKey="modals.purchaseTopboxClones.hint" components={{ br: <br /> }} />
          </Typography>

          <div className={styles.counter}>
            <Button className={styles.decrement} variant="outlined" color="lightGray" onClick={decrementQuantity}>
              <MinusIcon className={styles.icon} />
            </Button>

            <TextField className={styles.input} value={quantity} onChange={handleQuantityChange} />

            <Button className={styles.increment} variant="outlined" color="lightGray" onClick={incrementQuantity}>
              <PlusIcon className={styles.icon} />
            </Button>
          </div>

          <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
            {t('modals.purchaseTopboxClones.price')}:
          </Typography>
          <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
            {clonesPrice} <span>ВВ</span>
          </Typography>

          <div className={styles.controls}>
            <Button variant="outlined" onClick={handleClose} color="lightGray">
              {t('shared.controls.cancel')}
            </Button>

            <Button disabled={!parseInt(quantity)} onClick={() => setIsSubmited(true)}>
              {t('modals.purchaseTopboxClones.purchase')}
            </Button>
          </div>
        </>
      )}

      {isSubmited && (
        <>
          <Typography className={styles.clonesToPurchase} variant="heading6" element="p" color="boneWhite">
            <Trans
              i18nKey="modals.purchaseTopboxClones.confirmation.title"
              components={{ span: <span />, br: <br /> }}
              values={{ quantity, plural: t('shared.plurals.clones', { count: parseInt(quantity) }) }}
            />
          </Typography>

          <Typography className={styles.clonesToPurchase} variant="paragraph1" element="p" color="boneWhite">
            {t('modals.purchaseTopboxClones.confirmation.text', {
              quantity,
              plural: t('shared.plurals.clones', { count: parseInt(quantity) }),
              price: clonesPrice,
            })}
          </Typography>

          <div className={styles.controls}>
            <Button
              variant="outlined"
              onClick={() => setIsSubmited(false)}
              color="lightGray"
              disabled={isPurchaseLoading}
            >
              {t('shared.controls.cancel')}
            </Button>

            <Button onClick={handleSubmit} isLoading={isPurchaseLoading}>
              {t('shared.controls.confirm')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default observer(PurchaseTopboxClonesModal);
