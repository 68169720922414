import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './DeleteMagicBoxLotModal.module.scss';

const DeleteMagicBoxLotModal: React.FC = () => {
  const { t } = useTranslation();
  const { magicBoxStore } = useStore();

  return (
    <Modal
      className={styles.DeleteMagicBoxLotModal}
      isOpen={!!magicBoxStore.deleteLotModalConfig}
      onClose={magicBoxStore.closeDeleteLotModal}
      heading={t('modals.deleteMagicBoxLot.title')}
    >
      <Typography className={styles.message} element="p" variant="paragraph2" color="black">
        {t('modals.deleteMagicBoxLot.text')}
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          color="red"
          isLoading={magicBoxStore.loading.deleteLot}
          onClick={() => magicBoxStore.deleteLot(magicBoxStore.deleteLotModalConfig?.lot.id!)}
        >
          {t('modals.deleteMagicBoxLot.submit')}
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          disabled={magicBoxStore.loading.deleteLot}
          onClick={magicBoxStore.closeDeleteLotModal}
        >
          {t('shared.controls.close')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(DeleteMagicBoxLotModal);
