import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './DeleteNotificationModal.module.scss';

const DeleteNotificationModal: React.FC = () => {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const modalConfig = userStore.deleteNotificationModalConfig;

  const handleDelete = () => {
    switch (modalConfig?.type) {
      case 'magicbox':
        userStore.deleteMagicBoxNotification(modalConfig.id);
        break;
      case 'personal':
        userStore.deleteUserNotification(modalConfig.id.toString());
        break;
    }
  };

  return (
    <Modal
      className={styles.DeleteNotificationModal}
      isOpen={!!modalConfig}
      onClose={userStore.closeDeleteNotificationModal}
    >
      <Typography className={styles.message} element="p" variant="paragraph2" color="black">
        {t('modals.deleteNotification.text')}
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          color="red"
          isLoading={userStore.loading.deleteNotification}
          onClick={handleDelete}
        >
          {t('shared.controls.delete')}
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          disabled={userStore.loading.deleteNotification}
          onClick={userStore.closeDeleteNotificationModal}
        >
          {t('shared.controls.cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(DeleteNotificationModal);
