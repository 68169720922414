/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { User } from './User';

export type PlaceItem4 = {
  id: number;
  deskId: number;
  /**
   * -1 - parent place, 0 - current place, 1 - left place, 2 - right place
   */
  place: PlaceItem4.place;
  line: number | null;
  user: User;
  createdAt: string;
  subCount: number;
};

export namespace PlaceItem4 {
  /**
   * -1 - parent place, 0 - current place, 1 - left place, 2 - right place
   */
  export enum place {
    '_-1' = -1,
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}
