import React from 'react';

import cx from 'classnames';
import { motion } from 'framer-motion';
import { BackLink } from 'components/navigation';

import styles from './SubPage.module.scss';

interface SubPageProps {
  className?: string;
  backLinkPresence?: 'onMobile' | 'always' | 'none';
  backLinkRoute?: string;
  children: React.ReactNode;
}

const SubPage: React.FC<SubPageProps> = ({ className, backLinkPresence = 'onMobile', backLinkRoute, children }) => {
  return (
    <motion.div
      className={cx(styles.SubPage, className)}
      variants={{ show: { opacity: 1, marginLeft: '0px' }, hide: { opacity: 0, marginLeft: '-16px' } }}
      initial="hide"
      animate="show"
      exit="hide"
    >
      {backLinkPresence !== 'none' && (
        <BackLink
          className={cx(styles.backLink, { [styles.onMobile]: backLinkPresence === 'onMobile' })}
          to={backLinkRoute}
        />
      )}

      {children}
    </motion.div>
  );
};

export default SubPage;
