import { TopboxLevels } from 'types/topboxMarketing';

import rubyImg from 'static/images/gems/ruby.svg';
import emeraldImg from 'static/images/gems/emerald.svg';
import diamondImg from 'static/images/gems/diamond.svg';

export const TOPBOX_LEVELS_MAP = {
  [TopboxLevels.Emerald]: {
    id: 1,
    image: emeraldImg,
  },
  [TopboxLevels.Ruby]: {
    id: 2,
    image: rubyImg,
  },
  [TopboxLevels.Diamond]: {
    id: 3,
    image: diamondImg,
  },
};

export const TOPBOX_FILTER_LINES = [1, 2, 3, 4, 5];
