import moment from 'moment';
import { AdminUsersFilterType } from 'types/adminUsers';
import { AdminFilterDescriptionType, DateRange, SelectOption } from 'types/common';

export const isAdminOptions = [
  { value: 1, label: 'Да' },
  { value: 0, label: 'Нет' },
];

export const isActiveOptions = [
  { value: 1, label: 'Да' },
  { value: 0, label: 'Нет' },
];

export const sortOptions = [
  { value: 'created', label: 'Дата регистрации' },
  { value: 'username', label: 'Логин' },
  { value: 'balance', label: 'Баланс' },
  { value: 'internal_balance', label: 'Внутренний баланс' },
];

export const orderOptions = [
  { value: 'ASC', label: 'По возврастанию' },
  { value: 'DESC', label: 'По убыванию' },
];

const adminUsersFilters: AdminFilterDescriptionType<AdminUsersFilterType>[] = [
  {
    key: 'username',
    label: 'Логин',
    input: { type: 'text' },
  },
  {
    key: 'fullName',
    label: 'Имя',
    input: { type: 'text' },
  },
  {
    key: 'email',
    label: 'E-mail',
    input: { type: 'text' },
  },
  {
    key: 'phone',
    label: 'Телефон',
    input: { type: 'text' },
  },
  {
    key: 'id',
    label: 'Пользователи',
    input: { type: 'dynamicSelect', props: { isMulti: true, isSearchable: true, dynamicOptions: 'users' } },
    valueFormatter: (options) => (options as SelectOption[]).map((option) => option.label).join(', '),
  },
  {
    key: 'inviterId',
    label: 'Кураторы',
    input: { type: 'dynamicSelect', props: { isMulti: true, isSearchable: true, dynamicOptions: 'users' } },
    valueFormatter: (options) => (options as SelectOption[]).map((option) => option.label).join(', '),
  },
  {
    key: 'isAdmin',
    label: 'Администратор',
    input: { type: 'select', props: { options: isAdminOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'isActive',
    label: 'Статус авторизации',
    input: { type: 'select', props: { options: isActiveOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'telegram',
    label: 'Telegram',
    input: { type: 'text' },
  },
  {
    key: 'vk',
    label: 'VK',
    input: { type: 'text' },
  },
  {
    key: 'instagram',
    label: 'Instagram',
    input: { type: 'text' },
  },
  {
    key: 'createdAt',
    label: 'Дата регистрации',
    input: { type: 'daterange' },
    valueFormatter: (range) =>
      `${moment((range as DateRange).startDate).format('DD.MM.YYYY')} - ${moment((range as DateRange).endDate).format(
        'DD.MM.YYYY'
      )}`,
  },
  {
    key: 'balanceGte',
    label: 'Баланс не менее',
    input: { type: 'number' },
  },
  {
    key: 'balanceLte',
    label: 'Баланс не более',
    input: { type: 'number' },
  },
  {
    key: 'internalBalanceGte',
    label: 'Внутренний баланс не менее',
    input: { type: 'number' },
  },
  {
    key: 'internalBalanceLte',
    label: 'Внутренний баланс не более',
    input: { type: 'number' },
  },
  {
    key: 'sumBalancesGte',
    label: 'Суммарный баланс не менее',
    input: { type: 'number' },
  },
  {
    key: 'sumBalancesLte',
    label: 'Суммарный баланс не более',
    input: { type: 'number' },
  },
  {
    key: 'sort',
    label: 'Упорядочить по',
    input: { type: 'select', props: { options: sortOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'order',
    label: 'Порядок сортировки',
    input: { type: 'select', props: { options: orderOptions } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
];

export default adminUsersFilters;
