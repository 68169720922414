import { makeAutoObservable } from 'mobx';

import RootStore from 'store';
import { findIndex, groupBy, mapValues } from 'lodash';
import { SelectOption } from 'types/common';
import { MagicBoxLotsFilterType } from 'types/magicBox';
import { DeleteMagicBoxLotModalConfig, JoinMagicBoxLotModalConfig } from 'types/modalConfigs';
import {
  ApiError,
  MagicBoxService,
  PrizeListResponse,
  RaffleParticipant,
  RaffleView,
  RaffleViewModel,
} from 'api/client';

export default class MagicBoxStore {
  rootStore: RootStore;

  lots: {
    items: RaffleViewModel[];
    item: RaffleView | null;
    total: number;
    page: number;
    perPage: number;
    filter: MagicBoxLotsFilterType;
  };

  prizes: PrizeListResponse | null;

  isCreateLotModalOpen: boolean;

  joinLotModalConfig: JoinMagicBoxLotModalConfig | null;

  deleteLotModalConfig: DeleteMagicBoxLotModalConfig | null;

  isLotsFilterModalOpen: boolean;

  loading: {
    getPrizes: boolean;
    getLots: boolean;
    getLot: boolean;
    deleteLot: boolean;
  };

  errors: {
    getLot: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'magicBoxStore' });
    this.rootStore = rootStore;

    this.lots = {
      items: [],
      item: null,
      total: 0,
      page: 0,
      perPage: 12,
      filter: {
        active: true,
        completed: true,
        myLots: false,
        participating: false,
        prizeAmountFrom: '',
        prizeAmountTo: '',
        ticketPriceFrom: '',
        ticketPriceTo: '',
      },
    };

    this.prizes = null;

    this.isCreateLotModalOpen = false;
    this.joinLotModalConfig = null;
    this.isLotsFilterModalOpen = false;
    this.deleteLotModalConfig = null;

    this.loading = {
      getPrizes: false,
      getLots: false,
      getLot: false,
      deleteLot: false,
    };

    this.errors = {
      getLot: null,
    };
  }

  get marketingsOptions(): SelectOption[] {
    return this.prizes?.marketings?.map((marketing) => ({ label: marketing, value: marketing })) || [];
  }

  get prizesOptions(): { [key: string]: SelectOption[] } {
    return mapValues(groupBy(this.prizes?.prizes || [], 'marketing'), (prizes) =>
      prizes.map((prize) => ({ label: prize.title, value: prize.title }))
    );
  }

  get lotTicketsLeft() {
    return Math.max((this.lots.item?.raffle.totalTickets || 0) - (this.lots.item?.raffle.ticketsBought || 0), 0);
  }

  get lotParticipants() {
    return {
      winners:
        this.lots.item?.raffle.winners.reduce((acc, winner) => {
          const winnerIndex = findIndex(acc, (item) => item.user.username === winner.username);
          const participantIndex = findIndex(
            this.lots.item?.participants,
            (item) => item.user.username === winner.username
          );
          if (winnerIndex === -1) {
            return [
              ...acc,
              { user: winner, prizesCount: 1, count: this.lots.item?.participants[participantIndex]?.count! },
            ];
          } else {
            acc[winnerIndex].prizesCount += 1;
            return acc;
          }
        }, [] as (RaffleParticipant & { prizesCount: number })[]) || [],
      participants:
        this.lots.item?.participants.filter(
          (participant) =>
            !this.lots.item?.raffle.winners.find((winner) => winner.username === participant.user.username)
        ) || [],
    };
  }

  get isLotNotFound() {
    return this.errors.getLot?.body?.message === 'Not Found';
  }

  *getPrizes() {
    try {
      this.loading.getPrizes = true;
      const { data } = yield MagicBoxService.getPrizes();
      this.prizes = data;
    } catch (error) {
      console.log('[MagicBoxStore] getPrizes error', error);
    } finally {
      this.loading.getPrizes = false;
    }
  }

  *getLots() {
    try {
      this.loading.getLots = true;
      const { data } = yield MagicBoxService.getRaffleList(
        this.lots.page * this.lots.perPage,
        this.lots.perPage,
        parseFloat(this.lots.filter.ticketPriceFrom) || undefined,
        parseFloat(this.lots.filter.ticketPriceTo) || undefined,
        parseFloat(this.lots.filter.prizeAmountFrom) || undefined,
        parseFloat(this.lots.filter.prizeAmountTo) || undefined,
        this.lots.filter.active,
        this.lots.filter.completed,
        this.lots.filter.participating,
        this.lots.filter.myLots
      );
      this.lots = {
        ...this.lots,
        ...data,
      };
    } catch (error) {
      console.log('[MagicBoxStore] getLots error', error);
    } finally {
      this.loading.getLots = false;
    }
  }

  updateLotsPage(newPage: number) {
    this.lots.page = newPage;
    this.getLots();
  }

  updateLotsFilter(newFilter: MagicBoxLotsFilterType) {
    this.lots.filter = newFilter;
    this.getLots();
  }

  openCreateLotModal() {
    this.isCreateLotModalOpen = true;
  }

  closeCreateLotModal() {
    this.isCreateLotModalOpen = false;
  }

  openJoinLotModal(config: JoinMagicBoxLotModalConfig) {
    this.joinLotModalConfig = config;
  }

  closeJoinLotModal() {
    this.joinLotModalConfig = null;
  }

  openLotsFilterModal() {
    this.isLotsFilterModalOpen = true;
  }

  closeLotsFilterModal() {
    this.isLotsFilterModalOpen = false;
  }

  *getLot(id: string) {
    try {
      this.loading.getLot = true;
      const { data } = yield MagicBoxService.getOneRaffle(id, true);
      this.lots = {
        ...this.lots,
        item: data,
      };
    } catch (error) {
      console.log('[MagicBoxStore] getLot error', error);
      this.errors.getLot = error as ApiError;
    } finally {
      this.loading.getLot = false;
    }
  }

  *deleteLot(id: string) {
    try {
      this.loading.deleteLot = true;
      yield MagicBoxService.cancelRaffle(id);
      this.closeDeleteLotModal();
      this.getLots();
    } catch (error) {
      console.log('[MagicBoxStore] deleteLot error', error);
      this.rootStore.layoutStore.openFeedbackModal((error as ApiError).body?.message);
    } finally {
      this.loading.deleteLot = false;
    }
  }

  openDeleteLotModal(config: DeleteMagicBoxLotModalConfig) {
    this.deleteLotModalConfig = config;
  }

  closeDeleteLotModal() {
    this.deleteLotModalConfig = null;
  }

  resetLot() {
    this.lots.item = null;
    this.loading.getLot = false;
    this.errors.getLot = null;
  }
}
