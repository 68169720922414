import React from 'react';

import { useStore } from 'store';
import { observer } from 'mobx-react';

import { Button } from 'components/inputs';
import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';

import styles from './AdminDeleteReviewModal.module.scss';

const AdminDeleteReviewModal: React.FC = () => {
  const { adminStore } = useStore();

  return (
    <Modal
      className={styles.AdminDeleteReviewModal}
      isOpen={!!adminStore.adminDeleteReviewModalConfig}
      onClose={adminStore.closeAdminDeleteReviewModal}
    >
      <Typography className={styles.message} element="p" variant="paragraph2" color="black">
        Вы уверены, что хотите удалить отзыв?
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          color="red"
          isLoading={adminStore.loading.deleteReview}
          onClick={() => adminStore.deleteReview(adminStore.adminDeleteReviewModalConfig?.id!)}
        >
          Удалить
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          disabled={adminStore.loading.deleteReview}
          onClick={adminStore.closeAdminDeleteReviewModal}
        >
          Отменить
        </Button>
      </div>
    </Modal>
  );
};

export default observer(AdminDeleteReviewModal);
