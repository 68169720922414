import React, { useState } from 'react';

import cx from 'classnames';
import ReactDropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { fileToBase64 } from 'utils/media';

import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as DocumentIcon } from 'static/icons/document.svg';

import styles from './Dropzone.module.scss';

interface DropzoneProps {
  name?: string;
  className?: string;
  onChange?: (file: File) => void;
  label?: string | null;
  error?: string;
  disabled?: boolean;
}

const Dropzone: React.FC<DropzoneProps> = ({ name, className, onChange, error, label, disabled }) => {
  const { t } = useTranslation();

  const [preview, setPreview] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleDrop = async (files: File[]) => {
    setPreview(await fileToBase64(files[0]));
    setFileName(files[0].name);
    onChange && onChange(files[0]);
  };

  return (
    <ReactDropzone
      disabled={disabled}
      onDrop={handleDrop}
      multiple={false}
      accept={{ 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] }}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className={cx(styles.Dropzone, { [styles.hasError]: !!error, [styles.disabled]: disabled }, className)}
          {...getRootProps()}
        >
          {preview ? (
            <img className={styles.preview} src={preview} alt="preview" />
          ) : (
            <DocumentIcon className={styles.icon} />
          )}
          <div className={styles.text}>
            <Typography className={styles.name} element="span" variant="subtitle1" color="black">
              {label}
            </Typography>
            <Typography className={styles.hint} element="span" variant="caption1" color="blackTransparent">
              {error || fileName || t('shared.labels.uploadImage')}
            </Typography>
          </div>
          <input name={name} {...getInputProps()} />
        </div>
      )}
    </ReactDropzone>
  );
};

export default Dropzone;
