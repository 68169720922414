import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import { RaffleParticipant } from 'api/client';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Avatar from 'components/dataDisplay/Avatar';
import Typography from 'components/dataDisplay/Typography';

import { ReactComponent as ChairIcon } from 'static/icons/chair.svg';

import styles from './MagicBoxLotParticipantCard.module.scss';

interface MagicBoxLotParticipantCardProps {
  participant?: RaffleParticipant & { prizesCount?: number };
  newSlot?: boolean;
  isWinner?: boolean;
}

const MagicBoxLotParticipantCard: React.FC<MagicBoxLotParticipantCardProps> = ({ participant, newSlot, isWinner }) => {
  const { t } = useTranslation();
  const { magicBoxStore } = useStore();

  return (
    <Card
      className={cx(styles.MagicBoxLotParticipantCard, {
        [styles.taken]: !!participant && !newSlot,
        [styles.empty]: !participant && !newSlot,
        [styles.winner]: isWinner,
      })}
    >
      {participant && (
        <>
          <div className={styles.counter}>
            <div className={styles.iconWrapper}>
              <ChairIcon className={styles.icon} />
            </div>

            <Typography element="span" variant="paragraph2" color="white">
              {participant.count}
            </Typography>
          </div>

          <Avatar
            className={styles.avatar}
            avatar={participant.user.avatarUrl}
            size="lg"
            name={`${participant.user.firstName} ${participant.user.lastName}`}
          />
          <Typography className={styles.username} element="p" variant="subtitle1" color="white">
            {participant.user.username}
          </Typography>
          {isWinner && (
            <>
              <div className={styles.delimeter} />

              <div className={styles.info}>
                <Typography element="span" variant="subtitle1" color="white">
                  {magicBoxStore.lots.item?.raffle.prize}
                </Typography>
                <Typography element="span" variant="subtitle1" color="white">
                  {participant.prizesCount}
                </Typography>
              </div>
            </>
          )}
        </>
      )}

      {!participant && (
        <>
          <ChairIcon className={styles.chair} />

          {newSlot && (
            <Button
              className={styles.button}
              variant="outlined"
              size="small"
              color="black"
              onClick={() => magicBoxStore.openJoinLotModal({ lot: magicBoxStore.lots.item?.raffle! })}
            >
              {t('magicBoxLotPage.takePlace')}
            </Button>
          )}
        </>
      )}
    </Card>
  );
};

export default MagicBoxLotParticipantCard;
