/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { User } from './User';

export type PlaceItem = {
  id: number;
  deskId: number;
  /**
   * -1 - parent place, 0 - current place, 1 - left place, 2 - right place, 3 - left-left place, 4 - left-right place, 5 - right-left place, 6 - right-right place
   */
  place: PlaceItem.place;
  line: number | null;
  user: User;
  createdAt: string;
  subCount: number;
};

export namespace PlaceItem {
  /**
   * -1 - parent place, 0 - current place, 1 - left place, 2 - right place, 3 - left-left place, 4 - left-right place, 5 - right-left place, 6 - right-right place
   */
  export enum place {
    '_-1' = -1,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
    '_7' = 7,
  }
}
