import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { AuthService } from 'api/client';
import { UserRoleType } from 'types/common';

export const setAccessToken = (token: string) => {
  const decodedJwt: { exp: number } = jwtDecode(token);
  localStorage.setItem('token', token);
  localStorage.setItem('expires_at', decodedJwt.exp.toString());
};

export const setUserRole = (role: UserRoleType) => {
  localStorage.setItem('role', role);
};

export const getAccessToken = () => {
  return {
    token: localStorage.getItem('token'),
    role: localStorage.getItem('role') as UserRoleType,
    expiresAt: parseInt(localStorage.getItem('expires_at') || ''),
  };
};

export const clearAccessToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('role');
};

export const isAccessTokenValid = () => {
  const { expiresAt, token } = getAccessToken();
  if (!token || !expiresAt) return false;

  return moment().isBefore(moment.unix(expiresAt));
};

export const invalidateToken = () => {
  return AuthService.tokenInvalidate()
    .catch(() => {})
    .finally(() => {
      clearAccessToken();
      window.location.replace('/login');
    });
};

export const refreshToken = () => {
  return AuthService.tokenRefresh()
    .then(({ data }) => {
      setAccessToken(data.token);

      return data;
    })
    .catch(() => {
      invalidateToken();
    });
};

export const logTodaysLoginCount = () => {
  const loginCountInfo = getLastLoginsSummary();

  if (
    !loginCountInfo.date ||
    moment(loginCountInfo.date, 'DD.MM.YYYY').startOf('day').isBefore(moment().startOf('day'))
  ) {
    localStorage.setItem('lastLoginDate', moment().format('DD.MM.YYYY'));
    localStorage.setItem('todaysLoginCount', '1');

    return 1;
  } else {
    localStorage.setItem('todaysLoginCount', (loginCountInfo.count + 1).toString());
    return loginCountInfo.count + 1;
  }
};

export const getLastLoginsSummary = () => {
  const todaysLoginCount = localStorage.getItem('todaysLoginCount');
  return {
    date: localStorage.getItem('lastLoginDate'),
    count: todaysLoginCount ? parseInt(todaysLoginCount) : 0,
  };
};
