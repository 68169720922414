import React, { useCallback, useState } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ApiError, MegaBoxMarketingService } from 'api/client';
import { useStore } from 'store';

import { Typography } from 'components/dataDisplay';
import { Modal } from 'components/feedback';
import { Button } from 'components/inputs';

import { ReactComponent as PartyFace } from 'static/icons/party-face.svg';

import styles from './PurchaseMegaBoxPlacesModal.module.scss';

const PurchaseMegaBoxPlacesModal: React.FC = () => {
  const {
    layoutStore: { openFeedbackModal },
    accountingStore: { getBalance },
    megaBoxMarketingStore: { purchasePlacesModalConfig, closePurchasePlacesModal, getDesks },
  } = useStore();
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  const handleClose = useCallback(() => {
    closePurchasePlacesModal();
    setIsSuccess(false);
  }, [closePurchasePlacesModal]);

  const handleSubmit = useCallback(async () => {
    if (!purchasePlacesModalConfig) return;
    try {
      setIsPurchaseLoading(true);
      await MegaBoxMarketingService.buyMegaBoxPreOrder(purchasePlacesModalConfig.desk.id, {});
      getDesks();
      getBalance();
      setIsSuccess(true);
    } catch (error) {
      const apiError = error as ApiError;
      openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
    } finally {
      setIsPurchaseLoading(false);
    }
  }, [getDesks, getBalance, openFeedbackModal, purchasePlacesModalConfig, t]);

  return (
    <Modal className={styles.PurchaseMegaBoxPlacesModal} isOpen={!!purchasePlacesModalConfig} onClose={handleClose}>
      {isSuccess ? (
        <>
          <Typography className={styles.title} variant="heading6" element="h2">
            {t('modals.megaBoxPurchaseDesk.success.title')}
          </Typography>

          <PartyFace className={styles.successIcon} />

          <Typography className={styles.successText} variant="paragraph1" element="p">
            {t('modals.megaBoxPurchaseDesk.success.text')}
          </Typography>

          <div className={styles.controls}>
            <Button onClick={handleClose}>{t('modals.megaBoxPurchaseDesk.success.button')}</Button>
          </div>
        </>
      ) : (
        <>
          <Typography className={styles.title} variant="heading6" element="h2">
            {t('modals.megaBoxPurchaseDesk.title')}
          </Typography>

          {purchasePlacesModalConfig?.desk.requireBonusDesk && (
            <Typography className={styles.text} variant="paragraph1" element="p">
              {t('modals.megaBoxPurchaseDesk.hint')}
            </Typography>
          )}

          <div className={styles.prices}>
            <div className={styles.priceRow}>
              <Typography className={styles.label} variant="paragraph1" element="p">
                {t('modals.megaBoxPurchaseDesk.price')}
              </Typography>
              <Typography className={styles.price} variant="heading6" element="p">
                {purchasePlacesModalConfig?.desk.price} <span>ВВ</span>
              </Typography>
            </div>
            {purchasePlacesModalConfig?.desk.requireBonusDesk && (
              <div className={styles.priceRow}>
                <Typography className={styles.label} variant="paragraph1" element="p">
                  {t('modals.megaBoxPurchaseDesk.bonusMatrixPrice')}
                </Typography>
                <Typography className={styles.price} variant="heading6" element="p">
                  {purchasePlacesModalConfig.desk.bonusDeskPrice} <span>ВВ</span>
                </Typography>
              </div>
            )}
          </div>

          <div className={styles.total}>
            <Typography className={styles.label} variant="paragraph1" element="p">
              {t('modals.megaBoxPurchaseDesk.total')}
            </Typography>
            <Typography className={styles.price} variant="heading6" element="p">
              {(purchasePlacesModalConfig?.desk.price ?? 0) +
                (purchasePlacesModalConfig?.desk.requireBonusDesk
                  ? purchasePlacesModalConfig?.desk?.bonusDeskPrice ?? 0
                  : 0)}{' '}
              <span>ВВ</span>
            </Typography>
          </div>

          <div className={styles.controls}>
            <Button onClick={handleSubmit} isLoading={isPurchaseLoading}>
              {t('modals.megaBoxPurchaseDesk.purchase')}
            </Button>

            <Button variant="outlined" onClick={handleClose} color="red">
              {t('shared.controls.cancel')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default observer(PurchaseMegaBoxPlacesModal);
