import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button /* ClipboardCopy */ } from 'components/inputs';

// import { ReactComponent as CopyIcon } from 'static/icons/copy.svg';
import { ReactComponent as TelegramIcon } from 'static/icons/socials/telegram.svg';

import styles from './MainMarketingNodeInfoModal.module.scss';

const MainMarketingNodeInfoModal: React.FC = () => {
  const { t } = useTranslation();
  const { mainMarketingStore } = useStore();

  const nodeInfo = mainMarketingStore.nodeInfoModalConfig?.nodeInfo;

  return (
    <Modal
      className={styles.MainMarketingNodeInfoModal}
      overlayClassName={styles.nodeInfoModalOverlay}
      isOpen={!!mainMarketingStore.nodeInfoModalConfig}
    >
      <div className={styles.heading}>
        <Typography className={styles.username} element="span" variant="subtitle1" color="white">
          {nodeInfo?.user.username}
        </Typography>

        {nodeInfo?.line === 1 && (
          <Typography className={styles.line} element="span" variant="caption2" color="white">
            {t('mainMarketingDeskPage.node.line', { line: 1 })}
          </Typography>
        )}
      </div>

      <div className={styles.info}>
        <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="darkGray">
            Telegram:
          </Typography>

          <div className={styles.value}>
            <Typography element="span" variant="subtitle1" color="white">
              {nodeInfo?.user.telegram ? nodeInfo?.user.telegram : '—'}
            </Typography>

            {nodeInfo?.user.telegram && (
              <Button.Link
                className={styles.control}
                variant="wrapper"
                href={`https://t.me/${nodeInfo.user.telegram}`}
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon className={styles.icon} />
              </Button.Link>
            )}
          </div>
        </div>

        {/* <div className={styles.item}>
          <Typography className={styles.label} element="span" variant="caption1" color="darkGray">
            Телефон:
          </Typography>

          <div className={styles.value}>
            <Typography element="span" variant="subtitle1" color="white">
              {nodeInfo?.user.phone}
            </Typography>

            <ClipboardCopy className={styles.control} value={nodeInfo?.user.phone}>
              <CopyIcon className={styles.icon} />
            </ClipboardCopy>
          </div>
        </div> */}
      </div>

      <Button
        className={styles.button}
        variant="outlined"
        color="lightGray"
        onClick={mainMarketingStore.closeNodeInfoModal}
      >
        {t('shared.controls.close')}
      </Button>
    </Modal>
  );
};

export default observer(MainMarketingNodeInfoModal);
