import axios from 'axios';

import { REACT_APP_API_URL } from 'const/env';
import { OpenAPI as ApiConfig } from 'api/client';
import { getAccessToken, invalidateToken, isAccessTokenValid, refreshToken } from 'utils/auth';
import { request } from './client/core/request';
import { ApiRequestOptions } from './client/core/ApiRequestOptions';

ApiConfig.BASE = REACT_APP_API_URL;
ApiConfig.TOKEN = async () => {
  const { token } = getAccessToken();
  return token!;
};

axios.interceptors.request.use(async (config) => {
  if (false && !isAccessTokenValid() && getAccessToken().token && !config.url?.includes('/api/token')) {
    refreshToken();
  }

  if (config.url?.includes('/api/token') || config.url?.includes('/api/signin')) {
    config.withCredentials = true;
  }

  return config;
});

type RequestRetryType = (token: string) => void;

let isRefreshing = false;
let refreshQueue: RequestRetryType[] = [];

const onRefreshed = (token: string) => {
  refreshQueue = refreshQueue.filter((requestRetry) => requestRetry(token));
};

const subscribeTokenRefresh = (requestRetry: RequestRetryType) => {
  refreshQueue.push(requestRetry);
};

axios.interceptors.response.use(
  (config) => config,
  (error) => {
    const originalRequest = { ...error.config };
    const errorMessage: string = error.response?.data?.message;

    if (originalRequest?.url?.includes('token/invalidate') || originalRequest?.url?.includes('token/refresh')) {
      return Promise.reject(error);
    }

    if (errorMessage === 'Expired JWT Token' || errorMessage === 'Invalid JWT Token') {
      if (!isRefreshing) {
        isRefreshing = true;

        refreshToken().then((tokenResponse) => {
          isRefreshing = false;
          if (tokenResponse) {
            onRefreshed(tokenResponse.token);
          } else {
            refreshQueue = [];
          }
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers = { Authorization: `Bearer ${token}` };
          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    } else if (error && error.response?.status === 401 && getAccessToken().token) {
      invalidateToken();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export const apiRequest = (options: ApiRequestOptions) => request(ApiConfig, options);
