/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyMegaboxPlaceRequest } from '../models/BuyMegaboxPlaceRequest';
import type { LineMegaBoxStatisticsItem } from '../models/LineMegaBoxStatisticsItem';
import type { PreOrderDeskListItem } from '../models/PreOrderDeskListItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MegaBoxMarketingService {
  /**
   * Gets the list of desks in the MegaBox marketing for pre-order
   * @returns any Returns the list of desks in the MegaBox marketing for pre-order
   * @throws ApiError
   */
  public static getMegaBoxPreOrderDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<PreOrderDeskListItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/pre-order/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Buys a buy pre-order in the MegaBox marketing
   * @param deskId MegaBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buyMegaBoxPreOrder(deskId: number, requestBody: BuyMegaboxPlaceRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/megabox/pre-order/buy/{deskId}',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets pre-order desk statistics in MegaBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param line line id
   * @param username Username query
   * @param isBuy Filter for users who buy MegaBox
   * @returns any Returns desk statistics in MegaBox marketing
   * @throws ApiError
   */
  public static getMegaBoxPreOrderStatistics(
    offset?: number,
    limit: number = 12,
    line: number = 1,
    username?: string,
    isBuy?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<LineMegaBoxStatisticsItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/pre-order/statistics',
      query: {
        offset: offset,
        limit: limit,
        line: line,
        username: username,
        isBuy: isBuy,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }
}
