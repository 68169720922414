import { AdminUserNotificationsFilterType } from 'types/adminUserNotifications';
import { AdminFilterDescriptionType, SelectOption } from 'types/common';

const adminUserNotificationsFilters: AdminFilterDescriptionType<AdminUserNotificationsFilterType>[] = [
  {
    key: 'fromUser',
    label: 'Отправитель',
    input: { type: 'dynamicSelect', props: { dynamicOptions: 'users', isMulti: false, isSearchable: true } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
  {
    key: 'toUser',
    label: 'Получатель',
    input: { type: 'dynamicSelect', props: { dynamicOptions: 'users', isMulti: false, isSearchable: true } },
    valueFormatter: (option) => (option as SelectOption).label,
  },
];

export default adminUserNotificationsFilters;
