/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BoomBoxStatistics } from '../models/BoomBoxStatistics';
import type { BuyPlacesRequest } from '../models/BuyPlacesRequest';
import type { DeskItemPlaces2 } from '../models/DeskItemPlaces2';
import type { DeskListItem } from '../models/DeskListItem';
import type { FoundDeskUser } from '../models/FoundDeskUser';
import type { UserInstalledClone } from '../models/UserInstalledClone';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BoomBoxMarketingService {
  /**
   * Gets the list of desks in the BoomBox marketing
   * @returns any Returns the list of desks in the BoomBox marketing
   * @throws ApiError
   */
  public static getBoomBoxDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DeskListItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the structure of the desk.
   * @param deskId BoomBox Desk ID
   * @returns any Returns the structure of the desk.
   * @throws ApiError
   */
  public static showBoomBoxDesk(deskId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces2;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/desk/{deskId}/show',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting from the given place.
   * @param placeId BoomBox Place ID
   * @returns any Returns the desk structure starting from the given place.
   * @throws ApiError
   */
  public static showBoomBoxDeskFromPlace(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces2;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/place/{placeId}/show',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on next level.
   * @param placeId BoomBox Place ID
   * @returns any Returns the desk structure starting on next level.
   * @throws ApiError
   */
  public static showBoomBoxNextLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces2;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/place/{placeId}/next-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on current level.
   * @param placeId BoomBox Place ID
   * @returns any Returns the desk structure starting on current level.
   * @throws ApiError
   */
  public static showBoomBoxCurrentLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces2;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/place/{placeId}/current-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Buys places or clones in BoomBox marketing with automatic placement in the matrix
   * @param deskId BoomBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buyBoomBoxPlaces(deskId: number, requestBody: BuyPlacesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/boombox/{deskId}/buy/places',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Buys a clone in BoomBox marketing with placement in a given place
   * @param placeId BoomBox Parent Place ID
   * @param position Place Position
   * @returns void
   * @throws ApiError
   */
  public static buyBoomBoxClone(placeId: number, position: 'L' | 'M' | 'R'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/boombox/buy/clone/{placeId}/{position}',
      path: {
        placeId: placeId,
        position: position,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a list of my installed clones in BoomBox marketing.
   * @param deskId BoomBox Desk ID
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of my installed clones in BoomBox marketing.
   * @throws ApiError
   */
  public static myInstalledClonesInBoomBox(
    deskId: number,
    offset?: number,
    limit: number = 50
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserInstalledClone>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/desk/{deskId}/my-clones',
      path: {
        deskId: deskId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets desk statistics in BoomBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param filterDesk Desk ids
   * @param filterLine Lines
   * @param filterClones Show only clones
   * @param filterPlacesLeft Places before closing
   * @param filterUsername Username query
   * @returns any Returns desk statistics in BoomBox marketing
   * @throws ApiError
   */
  public static showBoomBoxStatistics(
    offset?: number,
    limit: number = 12,
    filterDesk?: Array<number>,
    filterLine?: Array<1 | 2 | 3 | 4 | 5>,
    filterClones?: boolean,
    filterPlacesLeft?: Array<1 | 2 | 3>,
    filterUsername?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<BoomBoxStatistics>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/desk/statistics',
      query: {
        offset: offset,
        limit: limit,
        'filter[desk][]': filterDesk,
        'filter[line][]': filterLine,
        'filter[clones]': filterClones,
        'filter[placesLeft][]': filterPlacesLeft,
        'filter[username]': filterUsername,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Searches for a user in the desk by username in BoomBox marketing
   * @param deskId BoomBox Desk ID
   * @param q Username
   * @param limit
   * @returns any Returns found users on the desk
   * @throws ApiError
   */
  public static searchUserInBoomBoxMarketing(
    deskId: number,
    q: string,
    limit: number = 10
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FoundDeskUser>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/boombox/desk/{deskId}/search/user',
      path: {
        deskId: deskId,
      },
      query: {
        q: q,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
