import { makeAutoObservable } from 'mobx';

import RootStore from 'store';

export default class LayoutStore {
  rootStore: RootStore;

  feedbackModalMessage: string | null;

  isSidebarOpen: boolean;

  isSnowEnabled: boolean;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'layoutStore' });
    this.rootStore = rootStore;
    this.feedbackModalMessage = null;
    this.isSidebarOpen = false;
    this.isSnowEnabled = localStorage.getItem('snow') === 'enabled';
  }

  openFeedbackModal(message: string) {
    this.feedbackModalMessage = message;
  }

  closeFeedbackModal() {
    this.feedbackModalMessage = null;
  }

  openSidebar() {
    this.isSidebarOpen = true;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  toggleSnow() {
    this.isSnowEnabled = !this.isSnowEnabled;

    localStorage.setItem('snow', this.isSnowEnabled ? 'enabled' : 'disabled');
  }
}
