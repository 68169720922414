/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyPlacesRequest } from '../models/BuyPlacesRequest';
import type { DeskItemPlaces5 } from '../models/DeskItemPlaces5';
import type { DeskListItem3 } from '../models/DeskListItem3';
import type { FoundDeskUser } from '../models/FoundDeskUser';
import type { MarketingStatistics } from '../models/MarketingStatistics';
import type { UserInstalledClone3 } from '../models/UserInstalledClone3';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KlondaikMarketingService {
  /**
   * Gets the list of desks in the Klondaik marketing
   * @returns any Returns the list of desks in the Klondaik marketing
   * @throws ApiError
   */
  public static getKlondaikDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DeskListItem3>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the structure of the desk.
   * @param deskId Klondaik Desk ID
   * @returns any Returns the structure of the desk.
   * @throws ApiError
   */
  public static showKlondaikDesk(deskId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces5;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/desk/{deskId}/show',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting from the given place.
   * @param placeId Klondaik Place ID
   * @returns any Returns the desk structure starting from the given place.
   * @throws ApiError
   */
  public static showKlondaikDeskFromPlace(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces5;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/place/{placeId}/show',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on next level.
   * @param placeId Klondaik Place ID
   * @returns any Returns the desk structure starting on next level.
   * @throws ApiError
   */
  public static showKlondaikNextLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces5;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/place/{placeId}/next-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on current level.
   * @param placeId Klondaik Place ID
   * @returns any Returns the desk structure starting on current level.
   * @throws ApiError
   */
  public static showKlondaikCurrentLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces5;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/place/{placeId}/current-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Buys places or clones in Klondaik marketing with automatic placement in the matrix
   * @param deskId Klondaik Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buyKlondaikPlaces(deskId: number, requestBody: BuyPlacesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/klondaik/{deskId}/buy/places',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Buys a clone in Klondaik marketing with placement in a given place
   * @param placeId Klondaik Parent Place ID
   * @param position Place Position
   * @returns void
   * @throws ApiError
   */
  public static buyKlondaikClone(placeId: number, position: 'L' | 'M' | 'R'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/klondaik/buy/clone/{placeId}/{position}',
      path: {
        placeId: placeId,
        position: position,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a list of my installed clones in Klondaik marketing.
   * @param deskId Klondaik Desk ID
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of my installed clones in Klondaik marketing.
   * @throws ApiError
   */
  public static myInstalledClonesInKlondaik(
    deskId: number,
    offset?: number,
    limit: number = 50
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserInstalledClone3>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/desk/{deskId}/my-clones',
      path: {
        deskId: deskId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets desk statistics in Klondaik marketing
   * @param offset Offset
   * @param limit Limit
   * @param filterDesk Desk ids
   * @param filterLine Lines
   * @param filterClones Show only clones
   * @param filterPlacesLeft Places before closing
   * @param filterUsername Username query
   * @returns any Returns desk statistics in Klondaik marketing
   * @throws ApiError
   */
  public static showKlondaikStatistics(
    offset?: number,
    limit: number = 12,
    filterDesk?: Array<number>,
    filterLine?: Array<1 | 2 | 3 | 4 | 5>,
    filterClones?: boolean,
    filterPlacesLeft?: Array<1 | 2 | 3>,
    filterUsername?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<MarketingStatistics>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/desk/statistics',
      query: {
        offset: offset,
        limit: limit,
        'filter[desk][]': filterDesk,
        'filter[line][]': filterLine,
        'filter[clones]': filterClones,
        'filter[placesLeft][]': filterPlacesLeft,
        'filter[username]': filterUsername,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Searches for a user in the desk by username in Klondaik marketing
   * @param deskId Klondaik Desk ID
   * @param q Username
   * @param limit
   * @returns any Returns found users on the desk
   * @throws ApiError
   */
  public static searchUserInKlondaikMarketing(
    deskId: number,
    q: string,
    limit: number = 10
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FoundDeskUser>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/klondaik/desk/{deskId}/search/user',
      path: {
        deskId: deskId,
      },
      query: {
        q: q,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
