/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminChangeUserPasswordRequest } from '../models/AdminChangeUserPasswordRequest';
import type { AdminSystemNotificationItem } from '../models/AdminSystemNotificationItem';
import type { AdminUserItem } from '../models/AdminUserItem';
import type { ChangeAvatarRequest } from '../models/ChangeAvatarRequest';
import type { ChangeCuratorRequest } from '../models/ChangeCuratorRequest';
import type { ChangeEmailRequest } from '../models/ChangeEmailRequest';
import type { ChangeFirstNameRequest } from '../models/ChangeFirstNameRequest';
import type { ChangeInstagramLoginRequest } from '../models/ChangeInstagramLoginRequest';
import type { ChangeLastNameRequest } from '../models/ChangeLastNameRequest';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ChangePhoneRequest } from '../models/ChangePhoneRequest';
import type { ChangeTelegramLoginRequest } from '../models/ChangeTelegramLoginRequest';
import type { ChangeUsernameRequest } from '../models/ChangeUsernameRequest';
import type { ChangeVkLoginRequest } from '../models/ChangeVkLoginRequest';
import type { CreateNotificationRequest } from '../models/CreateNotificationRequest';
import type { CreateReviewRequest } from '../models/CreateReviewRequest';
import type { CurrentUserResponse } from '../models/CurrentUserResponse';
import type { DetailUserNotification } from '../models/DetailUserNotification';
import type { Enable2FaRequest } from '../models/Enable2FaRequest';
import type { FullReviewResponse } from '../models/FullReviewResponse';
import type { PublicUserStatistics } from '../models/PublicUserStatistics';
import type { RegisteredUserResponse } from '../models/RegisteredUserResponse';
import type { RegistrationUserRequest } from '../models/RegistrationUserRequest';
import type { ReviewResponse } from '../models/ReviewResponse';
import type { StarsOnlineLinkRequest } from '../models/StarsOnlineLinkRequest';
import type { StarsOnlineRequestItem } from '../models/StarsOnlineRequestItem';
import type { StructureUser } from '../models/StructureUser';
import type { SummaryUserStructure } from '../models/SummaryUserStructure';
import type { SystemNotificationItem } from '../models/SystemNotificationItem';
import type { SystemNotificationMessageRequest } from '../models/SystemNotificationMessageRequest';
import type { TelegramBindUserRequest } from '../models/TelegramBindUserRequest';
import type { TelegramBindUserResponse } from '../models/TelegramBindUserResponse';
import type { UnpinUserFromTeamRequest } from '../models/UnpinUserFromTeamRequest';
import type { UpdateReviewRequest } from '../models/UpdateReviewRequest';
import type { UserLogItem } from '../models/UserLogItem';
import type { UserNotificationItem } from '../models/UserNotificationItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {
  /**
   * Gets public statistics about system users
   * @returns any Returns public statistics about system users
   * @throws ApiError
   */
  public static getPublicUserStatistics(): CancelablePromise<{
    status: boolean;
    data: PublicUserStatistics;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/user/statistics',
    });
  }

  /**
   * User registration
   * @param requestBody
   * @returns any Returns information about the registered user
   * @throws ApiError
   */
  public static registrationUser(requestBody: RegistrationUserRequest): CancelablePromise<{
    status: boolean;
    data: RegisteredUserResponse;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Checks if the authentication token is alive
   * @returns void
   * @throws ApiError
   */
  public static checkAliveToken(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/check',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets information about the currently logged in user.
   * @returns any Returns information about the currently logged in user.
   * @throws ApiError
   */
  public static getCurrentUser(): CancelablePromise<{
    status: boolean;
    data: CurrentUserResponse;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * User avatar upload
   * @param formData
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserAvatar(formData: ChangeAvatarRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/settings/avatar',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Deletes the user avatar
   * @returns void
   * @throws ApiError
   */
  public static deleteCurrentUserAvatar(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/user/settings/avatar',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Changes the first name of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserFirstName(requestBody: ChangeFirstNameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/first-name',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the last name of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserLastName(requestBody: ChangeLastNameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/last-name',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the mobile phone number of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserPhone(requestBody: ChangePhoneRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/phone',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the password of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserPassword(requestBody: ChangePasswordRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the financial password of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserFinancialPassword(requestBody: ChangePasswordRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/financial-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the instagram login of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserInstagramLogin(requestBody: ChangeInstagramLoginRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/social/instagram',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Changes the vk login of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserVkLogin(requestBody: ChangeVkLoginRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/social/vk',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Changes the telegram login of the current user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changeCurrentUserTelegramLogin(requestBody: ChangeTelegramLoginRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/social/telegram',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Enables 2fa for login
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static enable2FaForLogin(requestBody: Enable2FaRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/2fa/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Enables 2fa for purchase
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static enable2FaForPurchase(requestBody: Enable2FaRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/2fa/purchase',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Unpins a user from a team
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static unpinUserFromTeam(requestBody: UnpinUserFromTeamRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/unpin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets the structure of the current user
   * @param offset Offset
   * @param limit Limit
   * @param search Search by username, firstName and lastName
   * @returns any Returns a list of the structure of the current user
   * @throws ApiError
   */
  public static getCurrentUserStructure(
    offset?: number,
    limit: number = 20,
    search?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<StructureUser>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/structure/list',
      query: {
        offset: offset,
        limit: limit,
        search: search,
      },
    });
  }

  /**
   * Gets summary statistics for invited users
   * @returns any Returns summary statistics for invited users
   * @throws ApiError
   */
  public static getSummaryStatisticsByCurrentUser(): CancelablePromise<{
    status: boolean;
    data: SummaryUserStructure;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/structure/statistics',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Sends a message to the curator
   * @param requestBody Sends a message to the curator
   * @returns void
   * @throws ApiError
   */
  public static writeMessageToCurator(requestBody: CreateNotificationRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/notification/message/to/curator',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Sends messages to the team (1st line).
   * @param requestBody Sends messages to the team (1st line).
   * @returns void
   * @throws ApiError
   */
  public static writeMessageToTeam(requestBody: CreateNotificationRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/notification/message/to/team',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a list of the user's personal notifications.
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of the user's personal notifications.
   * @throws ApiError
   */
  public static getPersonalNotifications(
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserNotificationItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/notification/personal/list',
      query: {
        offset: offset,
        limit: limit,
      },
    });
  }

  /**
   * Gets a list of the system notifications.
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of the system notifications.
   * @throws ApiError
   */
  public static getSystemNotifications(
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<SystemNotificationItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/notification/system/list',
      query: {
        offset: offset,
        limit: limit,
      },
    });
  }

  /**
   * Removes a personal notification.
   * @param notificationId Notification Message ID
   * @returns void
   * @throws ApiError
   */
  public static deletePersonalNotification(notificationId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/user/notification/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Create review
   * @param requestBody Create review.
   * @returns void
   * @throws ApiError
   */
  public static createReview(requestBody: CreateReviewRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/review',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets review list
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns approved reviews or user reviews
   * @throws ApiError
   */
  public static getReviews(
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<ReviewResponse>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/reviews',
      query: {
        offset: offset,
        limit: limit,
      },
    });
  }

  /**
   * Link user to telegram
   * @param requestBody
   * @returns any Returns data after binding
   * @throws ApiError
   */
  public static bindTelegram(requestBody: TelegramBindUserRequest): CancelablePromise<{
    status: boolean;
    data: TelegramBindUserResponse;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/telegram/users/bind',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `User not found`,
      },
    });
  }

  /**
   * Link user to StarsOnline
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static starsOnlineLink(requestBody: StarsOnlineLinkRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/stars-online/link',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `User not found`,
      },
    });
  }

  /**
   * Gets stars online request links
   * @param offset Offset
   * @param limit Limit
   * @param unprocessed Unprocessed requests only
   * @returns any Returns stars online request links
   * @throws ApiError
   */
  public static getStarsOnlineRequestList(
    offset?: number,
    limit: number = 50,
    unprocessed?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<StarsOnlineRequestItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/stars-online-request/list',
      query: {
        offset: offset,
        limit: limit,
        unprocessed: unprocessed,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `User not found`,
      },
    });
  }

  /**
   * Approves or reject stars online request
   * @param action Action
   * @param id Request ID
   * @returns void
   * @throws ApiError
   */
  public static approveStarsOnlineRequest(action: 'approve' | 'reject', id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/stars-online-request/{action}/{id}',
      path: {
        action: action,
        id: id,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `User not found`,
      },
    });
  }

  /**
   * Unlink user to StarsOnline
   * @returns void
   * @throws ApiError
   */
  public static starsOnlineUnlink(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/stars-online-request/unlink',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `User not found`,
      },
    });
  }

  /**
   * Gets a list of users
   * @param offset Offset
   * @param limit Limit
   * @param username Filter by username
   * @param fullName Filter by full name
   * @param email Filter by email
   * @param phone Filter by phone
   * @param id Filter by user ids
   * @param inviterId Filter by inviter user ids
   * @param isAdmin Filter by whether the user is an administrator
   * @param isActive Filter by whether the user is active or blocked
   * @param telegram Filter by telegram username
   * @param vk Filter by vkontakte username
   * @param instagram Filter by instagram username
   * @param createdAtStart Filter by registration date after
   * @param createdAtEnd Filter by registration date before
   * @param balanceGte Filter users with balance from passed value
   * @param balanceLte Filter users with balance up to the passed value
   * @param internalBalanceGte Filter users with internal balance from passed value
   * @param internalBalanceLte Filter users with internal balance up to the passed value
   * @param sumBalancesGte Filter users by the sum balances from a passed amount
   * @param sumBalancesLte Filter users by the sum balances to a passed amount
   * @param sort Sort results
   * @param order Order by results
   * @returns any Returns a list of users
   * @throws ApiError
   */
  public static getUserList(
    offset?: number,
    limit: number = 20,
    username?: string,
    fullName?: string,
    email?: string,
    phone?: string,
    id?: Array<string>,
    inviterId?: Array<string>,
    isAdmin?: 0 | 1 | null,
    isActive?: 0 | 1 | null,
    telegram?: string,
    vk?: string,
    instagram?: string,
    createdAtStart: string = '',
    createdAtEnd: string = '',
    balanceGte: number | null = null,
    balanceLte: number | null = null,
    internalBalanceGte: number | null = null,
    internalBalanceLte: number | null = null,
    sumBalancesGte: number | null = null,
    sumBalancesLte: number | null = null,
    sort?: 'created' | 'username' | 'balance' | 'internal_balance',
    order?: 'ASC' | 'DESC'
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<AdminUserItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users',
      query: {
        offset: offset,
        limit: limit,
        username: username,
        fullName: fullName,
        email: email,
        phone: phone,
        'id[]': id,
        'inviterId[]': inviterId,
        isAdmin: isAdmin,
        isActive: isActive,
        telegram: telegram,
        vk: vk,
        instagram: instagram,
        'createdAt[start]': createdAtStart,
        'createdAt[end]': createdAtEnd,
        'balance[gte]': balanceGte,
        'balance[lte]': balanceLte,
        'internalBalance[gte]': internalBalanceGte,
        'internalBalance[lte]': internalBalanceLte,
        'sumBalances[gte]': sumBalancesGte,
        'sumBalances[lte]': sumBalancesLte,
        sort: sort,
        order: order,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets a user by id.
   * @param userId User ID
   * @returns any Returns a user by username.
   * @throws ApiError
   */
  public static getOneUser(userId: string): CancelablePromise<{
    status: boolean;
    data: AdminUserItem;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/{userId}',
      path: {
        userId: userId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator changes user's first name of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserFirstName(userId: string, requestBody: ChangeFirstNameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/first-name',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes email of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserEmail(userId: string, requestBody: ChangeEmailRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/email',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes user's last name of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserLastName(userId: string, requestBody: ChangeLastNameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/last-name',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the user's curator
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeCurator(userId: string, requestBody: ChangeCuratorRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/curator',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes username of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserUsername(userId: string, requestBody: ChangeUsernameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/username',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes the mobile phone number of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserPhone(userId: string, requestBody: ChangePhoneRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/phone',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Admin changes user financial password
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserFinancialPassword(
    userId: string,
    requestBody: AdminChangeUserPasswordRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/financial-password',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Admin changes user password
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserPassword(
    userId: string,
    requestBody: AdminChangeUserPasswordRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/password',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes the instagram login of a specific user
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserInstagramLogin(
    userId: string,
    requestBody: ChangeInstagramLoginRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/social/instagram',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator changes the telegram login of a specific user
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserTelegramLogin(
    userId: string,
    requestBody: ChangeTelegramLoginRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/social/telegram',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator changes the vk login of a specific user
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserVkLogin(userId: string, requestBody: ChangeVkLoginRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/social/vk',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator enables 2fa for login
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminEnable2FaForLogin(userId: string, requestBody: Enable2FaRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/2fa/login',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator enables 2fa for purchase
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminEnable2FaForPurchase(userId: string, requestBody: Enable2FaRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/2fa/purchase',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Blocks the user
   * @param userId User ID
   * @returns void
   * @throws ApiError
   */
  public static blockUser(userId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/users/{userId}/block',
      path: {
        userId: userId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Unblocks the user
   * @param userId User ID
   * @returns void
   * @throws ApiError
   */
  public static unblockUser(userId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/users/{userId}/unblock',
      path: {
        userId: userId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Assign role
   * @param userId User ID
   * @param role New Role
   * @returns void
   * @throws ApiError
   */
  public static assignRole(userId: string, role: 'admin' | 'support' | 'smm'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/role/{role}',
      path: {
        userId: userId,
        role: role,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Unassign role
   * @param userId User ID
   * @param role Delete Role
   * @returns void
   * @throws ApiError
   */
  public static unassignRole(userId: string, role: 'admin' | 'support' | 'smm'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/users/{userId}/role/{role}',
      path: {
        userId: userId,
        role: role,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the authorization log of a specific user
   * @param userId User ID
   * @param offset Offset
   * @param limit Limit
   * @param createdAtStart Filter by date after
   * @param createdAtEnd Filter by date before
   * @param order Order by results
   * @returns any Returns the authorization log of a specific user
   * @throws ApiError
   */
  public static getUserAuthLogs(
    userId: string,
    offset?: number,
    limit: number = 20,
    createdAtStart: string = '',
    createdAtEnd: string = '',
    order: 'ASC' | 'DESC' = 'DESC'
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserLogItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/{userId}/logs/auth',
      path: {
        userId: userId,
      },
      query: {
        offset: offset,
        limit: limit,
        'createdAt[start]': createdAtStart,
        'createdAt[end]': createdAtEnd,
        order: order,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets a list of system notifications for the admin.
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of system notifications for the admin.
   * @throws ApiError
   */
  public static getAdminSystemNotificationList(
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<AdminSystemNotificationItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/notification/system',
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Creates a system notification for all users.
   * @param requestBody Creates a system notification for all users.
   * @returns void
   * @throws ApiError
   */
  public static createSystemNotification(requestBody: SystemNotificationMessageRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/users/notification/system',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Updates the system notification.
   * @param notificationId
   * @param requestBody Updates the system notification.
   * @returns void
   * @throws ApiError
   */
  public static updateSystemNotification(
    notificationId: string,
    requestBody: SystemNotificationMessageRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/notification/system/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Deletes the system notification.
   * @param notificationId
   * @returns void
   * @throws ApiError
   */
  public static deleteSystemNotification(notificationId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/users/notification/system/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of personal user notifications
   * @param offset Offset
   * @param limit Limit
   * @param fromUser From user ID
   * @param toUser To user ID
   * @returns any Returns a list of system notifications for the admin.
   * @throws ApiError
   */
  public static getAdminPersonalNotificationList(
    offset?: number,
    limit: number = 20,
    fromUser?: string,
    toUser?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DetailUserNotification>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/notification/personal',
      query: {
        offset: offset,
        limit: limit,
        fromUser: fromUser,
        toUser: toUser,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Removes the user's personal notification.
   * @param notificationId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonalUserNotification(notificationId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/users/notification/personal/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of reviews for the admin
   * @param offset Offset
   * @param limit Limit
   * @param status Review Status
   * @param isPublishedOnMain Is it published on the main
   * @returns any Returns a list of reviews for the admin
   * @throws ApiError
   */
  public static getAdminReviewList(
    offset?: number,
    limit: number = 20,
    status?: 0 | 1 | 2,
    isPublishedOnMain?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FullReviewResponse>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/reviews',
      query: {
        offset: offset,
        limit: limit,
        status: status,
        isPublishedOnMain: isPublishedOnMain,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Changes the review status to accepted or rejected
   * @param reviewId Review ID
   * @param status New status
   * @returns void
   * @throws ApiError
   */
  public static changeReviewStatus(reviewId: string, status: 'approve' | 'decline'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/review/{reviewId}/status/{status}',
      path: {
        reviewId: reviewId,
        status: status,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Adds or removes review from the main page
   * @param reviewId Review ID
   * @param action Add or remove a review from the main
   * @returns void
   * @throws ApiError
   */
  public static publishedReviewOnMainPage(reviewId: string, action: 'add' | 'remove'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/review/{reviewId}/main-page/{action}',
      path: {
        reviewId: reviewId,
        action: action,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Updates the review
   * @param reviewId Review ID
   * @param requestBody Updates the review
   * @returns void
   * @throws ApiError
   */
  public static updateReview(reviewId: string, requestBody: UpdateReviewRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/review/{reviewId}',
      path: {
        reviewId: reviewId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Deletes the review
   * @param reviewId Review ID
   * @returns void
   * @throws ApiError
   */
  public static deleteReview(reviewId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/review/{reviewId}',
      path: {
        reviewId: reviewId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
