import moment from 'moment';
import { ReactNode } from 'react';
import { makeAutoObservable } from 'mobx';

import {
  ApiError,
  AdminService,
  AdminUserItem,
  FullReviewResponse,
  DetailUserNotification,
  UpdateTransactionRequest,
  DetailTransactionViewModel,
  AdminSystemNotificationItem,
  SystemNotificationMessageRequest,
} from 'api/client';
import {
  AdminFilterModalConfigType,
  AdminEditReviewModalConfig,
  AdminDeleteReviewModalConfig,
  AdminDeleteNotificationModalConfig,
  AdminNotificationFormModalConfigType,
} from 'types/modalConfigs';
import RootStore from 'store';
import { UserType } from 'types/common';
import adminUsersFilters from 'const/adminUsersFilters';
import { AdminUsersFilterType } from 'types/adminUsers';
import adminReviewsFilters from 'const/adminReviewsFilters';
import { AdminReviewsFilterType } from 'types/adminReviews';
import adminTransactionsFilters from 'const/adminTransactionsFIlters';
import { AdminTransactionsFilterType } from 'types/adminTransactions';
import { TransactionStatus, TransactionType } from 'types/transactions';
import adminUserNotificationsFilters from 'const/adminUserNotificationsFilters';
import { AdminUserNotificationsFilterType } from 'types/adminUserNotifications';

export default class AdminStore {
  rootStore: RootStore;

  users: {
    page: number;
    perPage: number;
    total: number;
    filter: AdminUsersFilterType;
    items: AdminUserItem[];
    item: (AdminUserItem & UserType) | null;
  };

  transactions: {
    page: number;
    perPage: number;
    total: number;
    filter: AdminTransactionsFilterType;
    items: DetailTransactionViewModel[];
    item: DetailTransactionViewModel | null;
  };

  systemNotifications: {
    items: AdminSystemNotificationItem[];
    total: number;
    perPage: number;
    page: number;
  };

  userNotifications: {
    items: DetailUserNotification[];
    total: number;
    perPage: number;
    page: number;
    filter: AdminUserNotificationsFilterType;
  };

  reviews: {
    items: FullReviewResponse[];
    total: number;
    perPage: number;
    page: number;
    filter: AdminReviewsFilterType;
  };

  adminFilterModalConfig: AdminFilterModalConfigType | null;

  isAdminTransactionUpdateModalOpen: boolean;

  adminNotificationFormModalConfig: AdminNotificationFormModalConfigType | null;

  adminDeleteNotificationModalConfig: AdminDeleteNotificationModalConfig | null;

  adminEditReviewModalConfig: AdminEditReviewModalConfig | null;

  adminDeleteReviewModalConfig: AdminDeleteReviewModalConfig | null;

  loading: {
    getUsers: boolean;
    getUser: boolean;
    blockUser: boolean;
    unblockUser: boolean;
    getTransactions: boolean;
    getTransaction: boolean;
    updateTransaction: boolean;
    approveTransaction: boolean;
    rejectTransaction: boolean;
    getSystemNotifications: boolean;
    createOrUpdateSystemNotification: boolean;
    deleteNotification: boolean;
    getUserNotifications: boolean;
    getReviews: boolean;
    deleteReview: boolean;
  };

  errors: {
    getUser: ApiError | null;
    getTransaction: ApiError | null;
    blockUser: ApiError | null;
    unblockUser: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'adminStore' });
    this.rootStore = rootStore;

    this.users = {
      page: 0,
      perPage: 10,
      total: 0,
      filter: {},
      items: [],
      item: null,
    };

    this.transactions = {
      page: 0,
      perPage: 10,
      total: 0,
      filter: {},
      items: [],
      item: null,
    };

    this.systemNotifications = {
      items: [],
      total: 0,
      page: 0,
      perPage: 5,
    };

    this.userNotifications = {
      items: [],
      total: 0,
      page: 0,
      perPage: 5,
      filter: {},
    };

    this.reviews = {
      items: [],
      total: 0,
      page: 0,
      perPage: 5,
      filter: {},
    };

    this.adminFilterModalConfig = null;
    this.isAdminTransactionUpdateModalOpen = false;
    this.adminNotificationFormModalConfig = null;
    this.adminDeleteNotificationModalConfig = null;
    this.adminEditReviewModalConfig = null;
    this.adminDeleteReviewModalConfig = null;

    this.loading = {
      getUsers: false,
      getUser: false,
      blockUser: false,
      unblockUser: false,
      getTransactions: false,
      getTransaction: false,
      updateTransaction: false,
      approveTransaction: false,
      rejectTransaction: false,
      getSystemNotifications: false,
      createOrUpdateSystemNotification: false,
      deleteNotification: false,
      getUserNotifications: false,
      getReviews: false,
      deleteReview: false,
    };

    this.errors = {
      getUser: null,
      getTransaction: null,
      blockUser: null,
      unblockUser: null,
    };
  }

  get hasAppliedUsersFilters() {
    return Object.keys(this.users.filter).reduce(
      (acc: boolean, key) => this.users.filter[key as keyof AdminUsersFilterType] !== undefined || acc,
      false
    );
  }

  get hasAppliedTransactionsFilters() {
    return Object.keys(this.transactions.filter).reduce(
      (acc: boolean, key) => this.transactions.filter[key as keyof AdminTransactionsFilterType] !== undefined || acc,
      false
    );
  }

  get appliedUsersFilters() {
    return adminUsersFilters
      .filter((filter) => this.users.filter[filter.key] !== undefined)
      .map((filter) => ({
        key: filter.key,
        label: filter.label,
        value: filter.valueFormatter
          ? filter.valueFormatter(this.users.filter[filter.key]!)
          : (this.users.filter[filter.key] as ReactNode),
      }));
  }

  get appliedTransactionsFilters() {
    return adminTransactionsFilters
      .filter((filter) => this.transactions.filter[filter.key] !== undefined)
      .map((filter) => ({
        key: filter.key,
        label: filter.label,
        value: filter.valueFormatter
          ? filter.valueFormatter(this.transactions.filter[filter.key]!)
          : (this.transactions.filter[filter.key] as ReactNode),
      }));
  }

  get appliedUserNotificationsFilters() {
    return adminUserNotificationsFilters
      .filter((filter) => this.userNotifications.filter[filter.key] !== undefined)
      .map((filter) => ({
        key: filter.key,
        label: filter.label,
        value: filter.valueFormatter
          ? filter.valueFormatter(this.userNotifications.filter[filter.key]!)
          : (this.userNotifications.filter[filter.key] as ReactNode),
      }));
  }

  get appliedReviewsFilters() {
    return adminReviewsFilters
      .filter((filter) => this.reviews.filter[filter.key] !== undefined)
      .map((filter) => ({
        key: filter.key,
        label: filter.label,
        value: filter.valueFormatter
          ? filter.valueFormatter(this.reviews.filter[filter.key]!)
          : (this.reviews.filter[filter.key] as ReactNode),
      }));
  }

  get isUserNotFound() {
    return this.errors.getUser?.body?.message === 'Not Found';
  }

  get isTransactionNotFound() {
    return this.errors.getTransaction?.body?.message === 'Not Found';
  }

  get userFullName() {
    return `${this.users.item?.firstName || ''} ${this.users.item?.lastName || ''}`;
  }

  resetUser() {
    this.users.item = null;
    this.errors.getUser = null;
  }

  updateUser(newUser: UserType) {
    this.users.item = newUser as UserType & AdminUserItem;
  }

  *blockUser(userId: string) {
    try {
      this.loading.blockUser = true;
      yield AdminService.blockUser(userId);
      this.users.item!.isActive = false;
      this.rootStore.layoutStore.openFeedbackModal('Пользователь заблокирован');
    } catch (error) {
      console.log('[AdminStore] blockUser error', error);
      this.errors.blockUser = error as ApiError;
    } finally {
      this.loading.blockUser = false;
    }
  }

  *unblockUser(userId: string) {
    try {
      this.loading.unblockUser = true;
      yield AdminService.unblockUser(userId);
      this.users.item!.isActive = true;
      this.rootStore.layoutStore.openFeedbackModal('Пользователь разблокирован');
    } catch (error) {
      console.log('[AdminStore] unblockUser error', error);
      this.errors.unblockUser = error as ApiError;
    } finally {
      this.loading.unblockUser = false;
    }
  }

  *getUser(userId: string) {
    try {
      this.loading.getUser = true;
      const { data }: { data: AdminUserItem } = yield AdminService.getOneUser(userId);
      this.users.item = {
        ...data,
        firstName: data.firstName!,
        lastName: data.lastName!,
        isSmm: false,
        isAdmin: false,
        isSupport: false,
        roles: [],
        avatar: {
          hasAvatar: !!data.avatarUrl,
          sm: data.avatarUrl,
          md: data.avatarUrl,
          lg: data.avatarUrl,
          xl: data.avatarUrl,
          xxl: data.avatarUrl,
        },
        registrationDate: data.createdAt,
        inviter: null,
        telegramBotBindLink: '',
        social: { vk: data.vkLogin, instagram: data.instagramLogin, telegram: data.telegramLogin },
      };
    } catch (error) {
      console.log('[AdminStore] getUser error', error);
      this.errors.getUser = error as ApiError;
    } finally {
      this.loading.getUser = false;
    }
  }

  *getUsers() {
    try {
      this.loading.getUsers = true;
      const { data } = yield AdminService.getUserList(
        this.users.page * this.users.perPage,
        this.users.perPage,
        this.users.filter.username,
        this.users.filter.fullName,
        this.users.filter.email,
        this.users.filter.phone,
        this.users.filter.id?.map((user) => user.value as string),
        this.users.filter.inviterId?.map((user) => user.value as string),
        this.users.filter.isAdmin?.value as 0 | 1,
        this.users.filter.isActive?.value as 0 | 1,
        this.users.filter.telegram,
        this.users.filter.vk,
        this.users.filter.instagram,
        this.users.filter.createdAt?.startDate
          ? moment(this.users.filter.createdAt?.startDate).format('DD.MM.YYYY')
          : undefined,
        this.users.filter.createdAt?.endDate
          ? moment(this.users.filter.createdAt?.endDate).format('DD.MM.YYYY')
          : undefined,
        this.users.filter.balanceGte as number,
        this.users.filter.balanceLte as number,
        this.users.filter.internalBalanceGte as number,
        this.users.filter.internalBalanceLte as number,
        this.users.filter.sumBalancesGte as number,
        this.users.filter.sumBalancesLte as number,
        this.users.filter.sort?.value as 'username' | 'created' | 'balance' | 'internal_balance',
        this.users.filter.order?.value as 'ASC' | 'DESC'
      );
      this.users = { ...this.users, ...data };
    } catch (error) {
      console.log('[AdminStore] getUsers error', error);
    } finally {
      this.loading.getUsers = false;
    }
  }

  updateUsersFilter(newFilter: AdminUsersFilterType) {
    this.users.filter = newFilter;
    this.getUsers();
  }

  updateUsersPage(newPage: number) {
    this.users.page = newPage;
    this.getUsers();
  }

  openAdminFilterModal(config: AdminFilterModalConfigType) {
    this.adminFilterModalConfig = config;
  }

  closeAdminFilterModal() {
    this.adminFilterModalConfig = null;
  }

  *getTransactions() {
    try {
      this.loading.getTransactions = true;
      const { data } = yield AdminService.getDetailUserTransactionList(
        this.transactions.page * this.transactions.perPage,
        this.transactions.perPage,
        this.transactions.filter.transactionUsername?.label as string,
        this.transactions.filter.status?.map((status) => status.value) as TransactionStatus[],
        this.transactions.filter.type?.map((txType) => txType.value) as TransactionType[],
        this.transactions.filter.direction?.value as 1 | -1,
        this.users.filter.createdAt?.startDate
          ? moment(this.users.filter.createdAt?.startDate).format('DD.MM.YYYY')
          : undefined,
        this.users.filter.createdAt?.endDate
          ? moment(this.users.filter.createdAt?.endDate).format('DD.MM.YYYY')
          : undefined,
        this.users.filter.sort?.value as 'username' | 'created',
        this.users.filter.order?.value as 'ASC' | 'DESC'
      );
      this.transactions = { ...this.transactions, ...data };
    } catch (error) {
      this.errors.getTransaction = error as ApiError;
      console.log('[AdminStore] getTransactions error', error);
    } finally {
      this.loading.getTransactions = false;
    }
  }

  *getTransaction(transactionId: string) {
    try {
      this.loading.getTransaction = true;
      const { data } = yield AdminService.getOneDetailTransaction(transactionId);
      this.transactions.item = data;
    } catch (error) {
      console.log('[AdminStore] getTransaction error', error);
    } finally {
      this.loading.getTransaction = false;
    }
  }

  *updateTransaction(transactionId: string, payload: UpdateTransactionRequest) {
    try {
      this.loading.updateTransaction = true;
      yield AdminService.updateTransaction(transactionId, payload);
      yield this.getTransaction(transactionId);
      yield this.getTransactions();
      this.closeAdminTransactionUpdateModal();
    } catch (error) {
      console.log('[AdminStore] updateTransaction error', error);
    } finally {
      this.loading.updateTransaction = false;
    }
  }

  *approveTransaction(transactionId: string) {
    try {
      this.loading.approveTransaction = true;
      yield AdminService.adminWithdrawTransactionApprove(transactionId);
      yield this.getTransaction(transactionId);
      yield this.getTransactions();
    } catch (error) {
      console.log('[AdminStore] approveTransaction error', error);
    } finally {
      this.loading.approveTransaction = false;
    }
  }

  *rejectTransaction(transactionId: string) {
    try {
      this.loading.rejectTransaction = true;
      yield AdminService.adminWithdrawTransactionReject(transactionId);
      yield this.getTransaction(transactionId);
      yield this.getTransactions();
    } catch (error) {
      console.log('[AdminStore] rejectTransaction error', error);
    } finally {
      this.loading.rejectTransaction = false;
    }
  }

  resetTransaction() {
    this.transactions.item = null;
  }

  updateTransactionsFilter(newFilter: AdminTransactionsFilterType) {
    this.transactions.filter = newFilter;
    this.getTransactions();
  }

  updateTransactionsPage(newPage: number) {
    this.transactions.page = newPage;
    this.getTransactions();
  }

  openAdminTransactionUpdateModal() {
    this.isAdminTransactionUpdateModalOpen = true;
  }

  closeAdminTransactionUpdateModal() {
    this.isAdminTransactionUpdateModalOpen = false;
  }

  *getSystemNotifications() {
    try {
      this.loading.getSystemNotifications = true;
      const { data } = yield AdminService.getAdminSystemNotificationList(
        this.systemNotifications.perPage * this.systemNotifications.page,
        this.systemNotifications.perPage
      );
      this.systemNotifications = {
        ...this.systemNotifications,
        ...data,
      };
    } catch (error) {
      console.log('[AdminStore] getSystemNotifications error', error);
    } finally {
      this.loading.getSystemNotifications = false;
    }
  }

  *createSystemNotification(payload: SystemNotificationMessageRequest) {
    try {
      this.loading.createOrUpdateSystemNotification = true;
      yield AdminService.createSystemNotification(payload);
      yield this.getSystemNotifications();
      this.closeAdminNotificationFormModal();
    } catch (error) {
      console.log('[AdminStore] createSystemNotification error', error);
    } finally {
      this.loading.createOrUpdateSystemNotification = false;
    }
  }

  *updateSystemNotification(id: string, payload: SystemNotificationMessageRequest) {
    try {
      this.loading.createOrUpdateSystemNotification = true;
      yield AdminService.updateSystemNotification(id, payload);
      yield this.getSystemNotifications();
      this.closeAdminNotificationFormModal();
    } catch (error) {
      console.log('[AdminStore] createSystemNotification error', error);
    } finally {
      this.loading.createOrUpdateSystemNotification = false;
    }
  }

  *deleteSystemNotification(id: string) {
    try {
      this.loading.deleteNotification = true;
      yield AdminService.deleteSystemNotification(id);
      yield this.getSystemNotifications();
      this.closeAdminDeleteNotificationModal();
    } catch (error) {
      console.log('[AdminStore] deleteSystemNotification error', error);
    } finally {
      this.loading.deleteNotification = false;
    }
  }

  updateSystemNotificationsPage(newPage: number) {
    this.systemNotifications.page = newPage;
    this.getSystemNotifications();
  }

  *getUserNotifications() {
    try {
      this.loading.getUserNotifications = true;
      const { data } = yield AdminService.getAdminPersonalNotificationList(
        this.userNotifications.perPage * this.userNotifications.page,
        this.userNotifications.perPage,
        this.userNotifications.filter.fromUser?.value as string,
        this.userNotifications.filter.toUser?.value as string
      );
      this.userNotifications = {
        ...this.userNotifications,
        ...data,
      };
    } catch (error) {
      console.log('[AdminStore] getUserNotifications error', error);
    } finally {
      this.loading.getUserNotifications = false;
    }
  }

  updateUserNotificationsPage(newPage: number) {
    this.userNotifications.page = newPage;
    this.getUserNotifications();
  }

  updateUserNotificationsFilter(newFilter: AdminUserNotificationsFilterType) {
    this.userNotifications.filter = newFilter;
    this.getUserNotifications();
  }

  *deleteUserNotification(id: string) {
    try {
      this.loading.deleteNotification = true;
      yield AdminService.deletePersonalUserNotification(id);
      yield this.getSystemNotifications();
      this.closeAdminDeleteNotificationModal();
    } catch (error) {
      console.log('[AdminStore] deleteUserNotification error', error);
    } finally {
      this.loading.deleteNotification = false;
    }
  }

  openAdminNotificationFormModal(config: AdminNotificationFormModalConfigType) {
    this.adminNotificationFormModalConfig = config;
  }

  closeAdminNotificationFormModal() {
    this.adminNotificationFormModalConfig = null;
  }

  openAdminDeleteNotificationModal(config: AdminDeleteNotificationModalConfig) {
    this.adminDeleteNotificationModalConfig = config;
  }

  closeAdminDeleteNotificationModal() {
    this.adminDeleteNotificationModalConfig = null;
  }

  *getReviews() {
    try {
      this.loading.getReviews = true;

      const { data } = yield AdminService.getAdminReviewList(
        this.reviews.perPage * this.reviews.page,
        this.reviews.perPage,
        this.reviews.filter.reviewStatus?.value as 0 | 1 | 2,
        this.reviews.filter.isPublishedOnMain?.value as boolean
      );

      this.reviews = {
        ...this.reviews,
        ...data,
      };
    } catch (error) {
      console.log('[AdminStore] getReviews error', error);
    } finally {
      this.loading.getReviews = false;
    }
  }

  updateReviewsPage(newPage: number) {
    this.reviews.page = newPage;
    this.getReviews();
  }

  updateReviewsFilter(newFilter: AdminReviewsFilterType) {
    this.reviews.filter = newFilter;
    this.getReviews();
  }

  openAdminEditReviewModal(config: AdminEditReviewModalConfig) {
    this.adminEditReviewModalConfig = config;
  }

  closeAdminEditReviewModal() {
    this.adminEditReviewModalConfig = null;
  }

  *deleteReview(id: string) {
    try {
      this.loading.deleteReview = true;
      yield AdminService.deleteReview(id);
      yield this.getReviews();
      this.closeAdminDeleteReviewModal();
    } catch (error) {
      console.log('[AdminStore] deleteReview error', error);
    } finally {
      this.loading.deleteReview = false;
    }
  }

  openAdminDeleteReviewModal(config: AdminDeleteReviewModalConfig) {
    this.adminDeleteReviewModalConfig = config;
  }

  closeAdminDeleteReviewModal() {
    this.adminDeleteReviewModalConfig = null;
  }
}
